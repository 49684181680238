.site-guide{
    .box{
        width: 88%;
        margin: 0 auto;
        @media screen and (max-width:$tab){
            width: auto;   
        }
    }
    .responsive-flow{
        @media screen and (max-width:$responsive){
            width: 100%;
            overflow: auto;
            &:before{
                content: '▼ 横にスクロールしてご覧ください';
                display: block;
                margin: 0 0 8px;
            }
        }
        table.table-responsive{
            width: 100%;
            border-collapse: collapse;
            @media screen and (max-width:$responsive){
                width: 150%;   
            }
            thead,
            tbody{
                tr{
                    th,td{
                        font-size: 17px;
                        border: $border 2px solid;
                        width: 20%;
                        padding: 15px;
                        text-align: left;
                        vertical-align: middle;
                        @media screen and (max-width:$responsive){
                            font-size: 14px;
                            width: auto;
                        }
                    }
                    &:nth-child(2n+2){
                        background: lighten($glay, 2%);
                    }
                    &:hover{
                        
                    }
                }
            }
            thead{
                tr{
                    background: $red;
                    th,td{
                        color: #FFF;
                        font-weight: 700;
                        border: none;
                        border-right: darken($red, 6%) 2px solid;
                        &:last-of-type{
                            border: none;
                        }
                    }
                }
            }
            tbody{
                tr{
                    &:nth-child(1){
                        th,td{
                            border-top: none;
                        }
                    }
                    &:hover{
                        color: $red;
                        font-weight: 700;
                        th,td{
                            border-bottom-color: $red;
                        }
                    }
                }
            }
        }
    }
    p.alert{
        margin: 24px 0 0;
        font-size: 13px;
    }
}