.site-math{
    $blue: #003597;
    $sky: #e5f6ff;
    $yel: #fffbbc;
    
    margin: $pc-head 0 0;
    @media screen and (max-width:$tab){
        margin: $sp-head 0 0;
    }
    .fv{
        background: url("../../../images/math/bg.jpg") no-repeat center;
        background-size: cover;
        > figure{
            width: 290px;
            margin: 0 auto;
            @media screen and (max-width:$tab){
                width: 220px;   
            }
            @media screen and (max-width:$responsive){
                width: 200px;   
            }
            img{
                width: 100%;
            }
        }
    }
    .context{
        background: $blue;
        position: relative;
        .display{
            z-index: 2;
        }
        &__message{
            text-align: center;
            color: #FFF;
            font-size: 32px;
            font-weight: 700;
            @media screen and (max-width:$tab){
                font-size: 24px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 17px;   
            }
        }
        canvas{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
    .figs{
        position: relative;
        &:before{
            content: '';
            width: 100%;
            height: 40%;
            background: $sky;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            @media screen and (max-width:$tab){
                height: 32%;   
            }
        }
        .display{
            z-index: 2;
        }
        &__flex{
            @include flex();
            align-items: center;
            @media screen and (max-width:$responsive){
                display: block;   
            }
            &__fig{
                width: 440px;
                @media screen and (max-width:$tab){
                    width: 360px;   
                }
                @media screen and (max-width:$responsive){
                    width: 88%;
                    margin: 0 auto 24px;
                }
                img{
                    width: 100%;
                }
            }
            &__dl{
                width: calc(100% - 440px);
                padding: 0 0 0 56px;
                @media screen and (max-width:$tab){
                    width: calc(100% - 360px);
                    padding: 0 0 0 32px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 0;
                }
                dt{
                    font-size: 38px;
                    font-weight: 700;
                    color: $blue;
                    line-height: 1.56;
                    margin: 0 0 18px;
                    padding: 0 0 16px;
                    border-bottom: $border 1px solid;
                    @media screen and (max-width:$tab){
                        font-size: 24px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 20px;   
                    }
                }
                dd{
                    font-size: 15px;
                    text-align: justify;
                    @media screen and (max-width:$tab){
                        font-size: 14px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 13.5px;   
                    }
                    strong{
                        color: $blue;
                        border-bottom: $blue 2px solid;
                    }
                }
            }
        }
        &__description{
            margin: 48px 0 0;
            background: #FFF;
            padding: 32px 12%;
            padding-top: 40px;
            border: $sky 12px solid;
            box-shadow: 0 8px 32px rgba($blue, .04);
            @media screen and (max-width:$tab){
                padding: 32px 8%;   
            }
            @media screen and (max-width:$responsive){
                padding: 20px;
                margin: 20px 0 0;
                border-width: 8px;
            }
            dt{
                text-align: center;
                font-size: 32px;
                color: $blue;
                font-weight: 700;
                margin: 0 0 8px;
                @media screen and (max-width:$tab){
                    font-size: 24px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 18px;
                }
                strong{
                    display: inline-block;
                    span{
                        display: inline-block;
                        position: relative;
                        &:before{
                            content: '';
                            width: 9px;
                            height: 9px;
                            background: $yel;
                            border-radius: 50%;
                            position: absolute;
                            top: -12px;
                            left: 50%;
                            transform: translateX(-50%);
                            @media screen and (max-width:$responsive){
                                width: 7px;
                                height: 7px;
                                top: -8px;
                            }
                        }
                    }
                }
            }
            dd{
                font-size: 15px;
                text-align: justify;
                @media screen and (max-width:$responsive){
                    font-size: 13.5px;   
                }
                strong{
                    color: $blue;
                    border-bottom: $blue 2px solid;
                }
                &.l{
                    text-align: center;
                    font-size: 24px;
                    color: $red;
                    font-weight: 700;
                    margin: 12px 0 0;
                    @media screen and (max-width:$responsive){
                        font-size: 20px;   
                    }
                }
            }
        }
    }
    .clums{
        margin: 80px 0;
        position: relative;
        @media screen and (max-width:$tab){
            margin: 32px 0;   
        }
        @media screen and (max-width:$responsive){
            margin: 0;   
        }
        .bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background: url("../../../images/math/bg2.jpg") no-repeat center;
            background-size: cover;
            @media screen and (max-width:$responsive){
                position: relative;
                width: auto;
                height: 180px;
            }
        }
        &__data{
            margin: 0 0 0 50%;
            padding: 0 0 0 56px;
            @media screen and (max-width:$tab){
                padding: 0 0 0 32px;   
            }
            @media screen and (max-width:$responsive){
                margin: 24px 0 0;
                padding: 0;
            }
            &__description{
                dt{
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 1.48;
                    color: $blue;
                    @media screen and (max-width:$tab){
                        font-size: 28px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 20px;
                        text-align: center;
                    }
                }
                dd{
                    font-size: 15px;
                    text-align: justify;
                    margin: 16px 0 0;
                    @media screen and (max-width:$tab){
                        font-size: 14px;   
                    }
                }
            }
            &__teacher{
                margin: 24px 0 0;
                background: $sky;
                padding: 15px;
                border-radius: 4px;
                @media screen and (max-width:$responsive){
                    margin: 20px 0 0;   
                }
                dt{
                    font-size: 18px;
                    font-weight: 700;
                    @media screen and (max-width:$responsive){
                        font-size: 15px;   
                    }
                }
            }
            &__mini{
                background: $yel;
                padding: 15px;
                font-size: 13px;
                margin: 24px 0 0;
                border-radius: 4px;
                @media screen and (max-width:$responsive){
                    margin: 20px 0 0;   
                }
            }
        }
    }
    
    .voice{
        background: $sky;
        h2{
            font-size: 32px;
            font-weight: 700;
            text-align: center;
            margin: 0 0 32px;
            @media screen and (max-width:$tab){
                font-size: 26px;
                margin: 0 0 24px;
            }
            @media screen and (max-width:$responsive){
                font-size: 20px;
                margin: 0 0 20px;
            }
        }
        &__list{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            > li{
                width: calc(50% - 8px);
                background: #FFF;
                padding: 15px;
                font-size: 17px;
                border-radius: 999px;
                margin: 16px 0 0;
                box-shadow: 0 0 16px rgba($blue,.04);
                @media screen and (max-width:$tab){
                    font-size: 15px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 14px;
                    width: auto;
                    margin: 0 0 12px;
                    &:last-child{
                        margin: 0;
                    }
                }
                &:nth-child(-n+2){
                    margin-top: 0;
                }
                i{
                    color: $blue;
                    transform: rotateY(180deg);
                    display: inline-block;
                    margin: 0 12px 0 0;
                    font-size: 20px;
                    @media screen and (max-width:$responsive){
                        font-size: 16px;
                        margin: 0 8px 0 0;
                    }
                }
            }
        }
    }
    
    .point{
        h2{
            font-size: 30px;
            line-height: 1.56;
            font-weight: 700;
            text-align: center;
            margin: 0 0 32px;
            @media screen and (max-width:$tab){
                font-size: 24px;
                margin: 0 0 24px;
            }
            @media screen and (max-width:$responsive){
                font-size: 18px;
                margin: 0 0 20px;
            }
        }
        &__list{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            > li{
                width: calc(50% - 12px);
                margin: 24px 0 0;
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 12px;
                    &:last-child{
                        margin: 0;
                    }
                }
                &:nth-child(-n+2){
                    margin-top: 0;
                }
                dl{
                    dt{
                        background: $blue;
                        text-align: center;
                        color: #FFF;
                        font-weight: 700;
                        font-size: 18px;
                        padding: 10px 15px;
                        @media screen and (max-width:$tab){
                            font-size: 15px;   
                        }
                    }
                    dd{
                        background: $sky;
                        padding: 15px;
                        text-align: center;
                        font-size: 20px;
                        @media screen and (max-width:$tab){
                            font-size: 15px;   
                        }
                        strong{
                            color: $blue;
                            border-bottom: $blue 3px solid;
                        }
                    }
                }
            }
        }
        &__str{
            margin: 56px 0 0;
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            color: $blue;
            line-height: 2;
            @media screen and (max-width:$tab){
                margin: 24px 0 0;
                font-size: 24px;
            }
            @media screen and (max-width:$responsive){
                font-size: 16px;
                text-align: left;
            }
            strong{
                border-bottom: $blue 6px solid;
            }
        }
        &__badge{
            @include flex();
            padding: 0 12%;
            margin: 32px 0 0;
            @media screen and (max-width:$tab){
                padding: 0 8%;   
            }
            @media screen and (max-width:$responsive){
                display: block;
                padding: 0;
            }
            > li{
                width: 50%;
                @media screen and (max-width:$responsive){
                    width: 88%;
                    margin: 0 auto 8px;
                    &:last-child{
                        margin: 0;
                    }
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    
    .bnrs{
        background: $blue;
        &__area{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            li{
                width: 48.5%;
                margin: 0 0 40px;
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 15px;
                    &:last-child{
                        margin: 0;
                    }
                }
                &.large{
                    width: 100%;
                    margin: 0;
                }
                img{
                    width: 100%;
                }
            }
        }
        .btnarea{
            a{
                background: #FFF;
                border: #FFF 1px solid;
                color: $blue;
                &:hover{
                    color: #FFF;
                    background: none;
                }
            }
        }
    }
}