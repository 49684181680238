.site-index{
    .firstview{
        position: relative;
        top: 0;
        left: 0;
        height: 100vh;
        border-top: none;
        background: #000;
        @include flex();
        align-items: center;
        justify-content: center;
        overflow: hidden;
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            opacity: .12;
            z-index: 2;
        }
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            box-shadow: inset 0 0 255px rgba(0,0,0,.88);
        }
        /* もしも画面映えしない場合
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            background: url("../../../images/index/fv_q.png") no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 0;
            left: 0;
            opacity: .32;
            z-index: 2;
        }
        */
        #video{
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100vw;
            height: 100vh;
            z-index: 1;
        }
        .catch{
            position: relative;
            z-index: 3;
            text-align: center;
            font-weight: 700;
            color: #FFF;
            line-height: 1;
            opacity: 0;
            pointer-events: none;
            transform: translateY(-8px);
            dt{
                @include fontsize(20px, 8);
                letter-spacing: .06em;
                margin: 24px 0 0;
                opacity: 0;
                transform: translateY(8px);
                @media screen and (max-width:$responsive){
                    @include fontsize(16px, 6);
                    margin: 16px 0 0;
                }
            }
            dd{
                @include fontsize(80px, 34);
                @include osw();
                letter-spacing: 0em;
                @media screen and (max-width:$tab){
                    @include fontsize(72px, 32);
                }
                @media screen and (max-width:$responsive){
                    @include fontsize(40px, 30);
                }
            }
            dd{
                span{
                    display: inline-block;
                    opacity: 0;
                    transform: scale(3) translateX(-20px);
                    @for $i from 1 through 50{
                        &:nth-of-type(#{$i}){
                            transition: transform 200ms $i*80ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms $i*80ms cubic-bezier(0.65, 0, 0.35, 1);
                        }
                    }
                }
            }
            &.active{
                opacity: 1;
                dt{
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity 1000ms 1600ms, transform 1000ms 1600ms;
                }
                dd{
                    span{
                        opacity: 1;
                        transform: scale(1) translateX(0);
                    }
                }
            }
        }
        .rightside{
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
            z-index: 3;
            color: #FFF;
            text-align: center;
            @media screen and (max-width:$tab){
                right: 20px;   
            }
            @media screen and (max-width:$responsive){
                display: none;
            }
            a{
                color: #FFF;
                font-size: 16px;
                display: block;
            }
            li{
                margin: 12px 0;
                &.tate{
                    @include osw();
                    font-size: 18px;
                    color: rgba(#FFF,.72);
                    -webkit-writing-mode: vertical-rl;
                    -moz-writing-mode: vertical-rl;
                    -ms-writing-mode: tb-rl;
                    writing-mode: vertical-rl;
                    padding: 0 0 24px;
                    margin: 0 0 24px;
                    line-height: 1;
                    letter-spacing: .12em;
                    border-bottom: rgba(#FFF,.4) 1px solid;
                }
            }
        }
        .topics{
            color: #FFF;
            min-width: 300px;
            max-width: 500px;
            position: absolute;
            left: 32px;
            bottom: 24px;
            z-index: 3;
            @media screen and (max-width:$tab){
                left: 20px;
                bottom: 20px;
            }
            @media screen and (max-width:$responsive){
                left: 15px;
                bottom: 15px;
                padding: 0 20px 0 0;
                max-width: 100%;
                min-width: 1px;
                width: calc(100% - 30px);
            }
						li{
							margin: 0 0 16px;
							&:last-child{
								margin-bottom: 0
							}
							a{
								color: #FFF;
								@include flex();
								display: inline-flex;
								align-items: center;
								position: relative;
								padding: 0 32px 0 0;
							}
						}
            i{
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
            .time{
                background: $red;
                width: 110px;
                font-size: 12px;
                padding: 4px 0;
                font-weight: 700;
                text-align: center;
                border-radius: 999px;
                @media screen and (max-width:$responsive){
                    font-size: 11px;
                    width: 90px;
                    padding: 3px 0;
                }
            }
            &__mes{
                width: calc(100% - 110px);
                padding: 0 0 0 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 15px;
                font-weight: 700;
                text-decoration: underline;
                @media screen and (max-width:$responsive){
                    width: calc(100% - 90px);
                    font-size: 14px;
                    padding: 0 0 0 15px;
                }
            }
        }
        .scroll{
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 3;

            &:before{
                content: '';
                width: 180px;
                height: 180px;
                background: $red;
                position: absolute;
                right: -90px;
                bottom: -90px;
                transform: rotate(45deg);
                @media screen and (max-width:$responsive){
                    width: 140px;
                    height: 140px;
                    right: -70px;
                    bottom: -70px;
                }
            }
            &__line{
                position: absolute;
                right: 24px;
                bottom: 24px;
                z-index: 3;
                height: 140px;
                @media screen and (max-width:$responsive){
                    display: none;
                }
                &:before{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    content: '';
                    width: 1px;
                    height: 100%;
                    background: #FFF;
                    animation: sc 2s infinite;
                    @keyframes sc{
                        0%{
                            height: 0%;
                            bottom: 100%;
                        }
                       25%{
                            height: 100%;
                            bottom: 0;
                        }
                        51%{
                            height: 100%;
                            bottom: 0;
                        }
                        75%{
                            height: 0%;
                            bottom: 0;
                        }
                        100%{
                            height: 0%;
                            bottom: 0;
                        }
                    }
                }
                &:after{
                    font-size: 11px;
                    -webkit-writing-mode: vertical-rl;
                    -moz-writing-mode: vertical-rl;
                    -ms-writing-mode: tb-rl;
                    writing-mode: vertical-rl;
                    white-space: nowrap;
                    letter-spacing: .12em;
                    content: 'SCROLL';
                    position: absolute;
                    top: 0;
                    right: 8px;
                    color: #FFF;
                }
            }
        }
        .copy{
            -webkit-writing-mode: vertical-rl;
            -moz-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            white-space: nowrap;
            letter-spacing: .08em;
            position: absolute;
            top: 0;
            left: 40px;
            height: 100%;
            color: rgba(255,255,255,.88);
            font-size: 9px;
            text-align: center;
            letter-spacing: .16em;
            z-index: 3;
            color: #FFF;
            text-transform: uppercase;
            @media screen and (max-width:$tab){
                left: 20px;   
            }
            @media screen and (max-width:$responsive){
                display: none;
            }
        }
    }
}