@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');


/* BASE COLOR
------------------------------------------------------------*/
$normal:#151515;
$link:#D02;
$border:#e8e8e8;

$blue: #023894;
$red: #db1501;
$glay: #f4f4f4;


/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$tab: $width - 1px;
$responsive: 768px;

$pc-head:76px;
$sp-head:68px;


@import "module/reset";
@import "module/module";

::selection {
    background: $normal;
    color: #FFF;
}

:root{
	font-size: 14px;
}


*{
    user-select: none;
}
img {
  pointer-events: none;
}
a{
    img{
        pointer-events: auto;
    }
}

body{
    &.windows{
        text-shadow: 0 0 rgba(#000,.56);
    }
}

#app{
    opacity: 0;
    &.active{
        opacity: 1;
    }
}


@mixin fontsize($w: 32px, $m: 22){
    font-size: calc(#{$w} + #{$m} * (100vw - 450px)/ 900);
}

@media screen and (min-width:$width){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$tab){
	@include default(
		auto, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$responsive){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/firstview";
@import "page/teacher";
@import "page/advertise";
@import "page/contact";
@import "page/achievement";
@import "page/news";
@import "page/math";
@import "page/recruit";
@import "page/guide";
@import "page/kamatori";
@import "page/e-tore";
@import "page/myquest";
@import "page/leader";
@import "page/privacy";

#site-wrapper,#site-footer{
    position: relative;
    @media screen and (max-width:768px){

    }
}

.site-clum{
    @include flex();
    width: 88%;
    margin: 0 auto;
    @media screen and (max-width:$tab){
        width: auto;
        display: block;
    }
    &__sidebar{
        width: 280px;
        @media screen and (max-width:$tab){
            width: auto;
        }
        @media screen and (max-width:$responsive){
            margin: 32px 0 0;   
        }
    }
    &__container{
        width: calc(100% - 280px);
        padding: 0 48px 0 0;
        @media screen and (max-width:$tab){
            width: auto;
            padding: 0;
        }
        .pager{
            margin: 24px 0 0;
            text-align: center;
            a,span{
                $size: 28px;
                width: $size;
                height: $size;
                line-height: $size - 2px;
                text-align: center;
                display: inline-block;
                margin: 0 3px;
                border: $border 1px solid;
                background: $border;
                color: $normal;
                font-size: 13px;
            }
            span{
                background: $red;
                border-color: $red;
                color: #FFF;
            }
            a{
                &:hover{
                    color: $red;
                    background: #FFF;
                    border-color: $red;
                }
            }
        }
    }
}



.site-module{
    &.wrap{
        padding: 88px 40px;
        overflow: hidden;
        @media screen and (max-width:$tab){
            padding: 40px 20px;   
        }
        @media screen and (max-width:$responsive){
            padding: 32px 15px;   
        }
        &.no-top{
            padding-top: 0;
        }
        &.no-bottom{
            padding-bottom: 0;
        }
        &.glay{
            background: $glay;
        }
        &.borders{
            border-bottom: $glay 15px solid;
            @media screen and (max-width:$responsive){
                border-bottom: $glay 8px solid;
            }
        }
    }
    
    h2.large{
        font-weight: 700;
        font-size: 20px;
        line-height: 1;
        margin: 0 0 40px;
        letter-spacing: .08em;
        @media screen and (max-width:$tab){
            font-size: 18px;
            margin: 0 0 32px;
        }
        @media screen and (max-width:$responsive){
            font-size: 15px;
            margin: 0 0 24px;   
        }
        &:before{
            content: attr(data-eng);
            @include osw();
            letter-spacing: .02em;
            font-size: 48px;
            margin: 0 32px 0 0;
            text-indent: -2px;
            text-shadow: none;
            @media screen and (max-width:$tab){
                font-size: 40px;
                margin: 0 24px 0 0;
            }
            @media screen and (max-width:$responsive){
                font-size: 30px;
                margin: 0 16px 0 0;
            }
        }
    }
    h2.center{
        font-weight: 700;
        font-size: 34px;
        line-height: 1;
        margin: 0 0 40px;
        letter-spacing: .08em;
        text-align: center;
        position: relative;
        @media screen and (max-width:$tab){
            font-size: 28px;
            margin: 0 0 32px;
        }
        @media screen and (max-width:$responsive){
            font-size: 24px;
            margin: 0 0 20px;
        }
        &:after{
            content: attr(data-eng);
            letter-spacing: .048em;
            font-size: 14px;
            display: block;
            margin: 20px 0 0;
            color: $red;
            @media screen and (max-width:$tab){
                font-size: 13px;
                margin: 16px 0 0;
            }
            @media screen and (max-width:$responsive){
                margin: 10px 0 0;   
            }
        }
    }
    h2.borders,
    h3.borders,
    h4.borders{
        border-bottom: $border 1px solid;
		position: relative;
        padding: 12px;
		padding-left: 20px;
        font-size: 22px;
		line-height: 1;
        font-weight: 700;
		margin: 0 0 16px;
        @media screen and (max-width:$tab){
            font-size: 20px;   
        }
		@media screen and (max-width:$responsive){
			font-size: 18px;
			margin: 0 0 20px;
			padding-left: 18px;
		}
		small{
			display: inline-block;
			margin: 0 0 0 20px;
			font-size: 13px;
			@media screen and (max-width:$responsive){
				margin: 0 0 0 15px;
				font-size: 11px;
			}
		}
		&:after{
			content: '';
			width: 5px;
			height: 70%;
			background: $red;
			border-radius: 4px;
			display: block;
			position: absolute;
			top: 15%;
			left: 0;
		}
    }

    .btnarea{
        margin: 32px 0 0;
        @media screen and (max-width:$responsive){
            margin: 24px 0 0;   
        }
        &.center{
            @media screen and (min-width:$responsive + 1px){
                text-align: center;
                a{
                    margin: 0 8px;
                }
            }
        }
        &.s{
            margin: 24px 0 0;
            a{
                padding: 10px 0;
                min-width: auto;
                display: block;
            }
        }
        a{
            background: $red;
            color: #FFF;
            padding: 12px 72px;
            min-width: 300px;
            text-align: center;
            font-size: 17px;
            font-weight: 700;
            position: relative;
            display: inline-block;
            z-index: 1;
            @media screen and (max-width:$responsive){
                display: block;
                padding: 11px 0;
                margin: 0 0 10px;
                &:last-of-type{
                    margin: 0;
                }
            }
            &.arrow{
                &:after{
                    content: "\f061";
                    font-size: 11px;
                    font-family: "Font Awesome 5 Free";
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-50%);
                }
            }
            &:hover{
                background: $normal;
                color: #FFF;
            }
            &.glay{
                background: darken($glay, 4%);
                color: $normal;
                &:hover{
                    background: $red;
                    color: #FFF;
                }
            }
            &.black{
                background: $normal;
                color: #FFF;
                &:hover{
                    background: lighten($normal, 8%);
                }
            }
        }
    }
    .sendarea{
        text-align: center;
        margin: 40px 0 0;
        @media screen and (max-width:$responsive){
            margin: 24px 0 0;   
        }
        input{
            @include font();
            font-size: 17px;
            font-weight: 700;
            background: $red;
            width: 280px;
            padding: 12px 0;
            color: #FFF;
            border: none;
            display: inline-block;
            cursor: pointer;
            @media screen and (max-width:$responsive){
                width: 100%;
                display: block;
            }
            &:hover{
                background: lighten($red, 4%);
            }
        }
    }
	.privacystyle{
		&__box{
			width: 280px;
			margin: 0 auto;
			position: relative;
			$h: 64px;
			@media screen and (max-width:$responsive){
				width: 100%;	
			}
			&__clone{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: $h;
				@include flex();
				align-items: center;
				justify-content: center;
				background: $red;
				color: #FFF;
				text-align: center;
				pointer-events: none;
				&__box{
					line-height: 1;
				}
				&__min{
					font-size: 11px;
					display: block;
				}
				&__str{
					font-size: 16px;
					font-weight: 700;
					display: block;
					margin: 6px 0 0;
				}
			}
			input{
				width: 100%;
				height: $h;
				&:hover{
					& + .privacystyle__box__clone{
						background: lighten($red, 4%);
					}
				}
			}
		}
	}
    .tablestyle{
        width: 100%;
        border: $border 1px solid;
        border-collapse: collapse;
        @media screen and (max-width:$responsive){
            display: block;   
        }
        tbody,
        thead{
            @media screen and (max-width:$responsive){
                display: block;   
            }
            tr{
                border-bottom: $border 1px solid;
                @media screen and (max-width:$responsive){
                    display: block;   
                }
                th,
                td{
                    padding: 24px 20px;
                    text-align: left;
                    vertical-align: middle;
                    font-size: 15px;
                    @media screen and (max-width:$tab){
                        padding: 20px;   
                    }
                    @media screen and (max-width:$responsive){
                        display: block;
                        padding: 15px;
                    }
                }
                th{
                    width: 240px;
                    border-right: $border 1px solid;
                    background: $glay;
                    @media screen and (max-width:$responsive){
                        width: auto;
                        border: none;
                    }
                }
            }
        }
        &.formstyle{
            border: none;
            tbody,
            thead{
                tr{
                    border-bottom: $border 1px solid;
                    th,
                    td{
                        padding: 48px 0;
                        @media screen and (max-width:$tab){
                            padding: 32px 0;   
                        }
                        @media screen and (max-width:$responsive){
                            padding: 16px 0;   
                        }
                        .mt{
                            margin: 8px 0 0;
                            font-size: 13px;
                        }
                        input[type=text],
                        input[type=email],
                        input[type=tel],
                        textarea,
                        select{
                            border: $border 2px solid;
                            background: #FFF;
                            display: block;
                            padding: 8px;
                            @include font();
                            font-size: 15px;
                            width: 100%;
                            border-radius: 4px;
                            @include transition(200ms);
                            pointer-events: auto !important;
                            user-select: auto !important;
                            @media screen and (max-width:$responsive){
                                -webkit-appearance: none;
                            }
                            &:focus{
                                transform: scale(1.02);
                                border-color: #FFF;
                                box-shadow: 0 4px 32px rgba(#000,.16);
                            }
                        }
                    }
                    td{
                        padding-left: 48px;
                        @media screen and (max-width:$responsive){
                            padding-left: 0;
                            padding-top: 0;
                        }
                    }
                    th{
                        width: 300px;
                        background: none;
                        border: none;
                        @media screen and (max-width:$responsive){
                            width: auto;   
                        }
                        &:after{
                            content: '任意';
                            float: right;
                            background: $border;
                            color: $normal;
                            border-radius: 4px;
                            padding: 2px 8px;
                            font-size: 13px;
                        }
                        &.hiss{
                            &:after{
                                content: '必須';
                                background: $red;
                                color: #FFF;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sendmessage{
    text-align: center;
    font-size: 16px;
    @media screen and (max-width:$responsive){
        font-size: 14px;   
    }
}

// USE ANIMATE
.scroll-anime{
	opacity: 0;
	transform: translateY(48px);
	@include transition(800ms);
	&._active{
		opacity: 1;
		transform: translateY(0);
	}
}

.scroll-img{
	position: relative;
	$ease-transition: cubic-bezier(0.86, 0, 0.07, 1);
	$speed: 600ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
        z-index: 2;
	}
	&:before{
		background: $red;
		@include transition($speed , $delay:$speed , $ease:$ease-transition);
	}
	&:after{
		background: #FFF;
		@include transition($speed , $ease: $ease-transition);
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}    
}

.scale-enter-active, .scale-leave-active {
	transition: 400ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
}


#intro{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    visibility: hidden;
    &.visible{
        visibility: visible;
    }
    &.active{
        &:before,
        &:after{
            height: 0;
        }
        #bar{
            opacity: 0;
        }
        #num{
            opacity: 0;
        }
        .svg{
            opacity: 0;
        }
    }
    &:before,
    &:after{
        content: '';
        width: 200%;
        height: 51%;
        position: absolute;
        background: $red;
        z-index: 1;
        @include transition(400ms);
    }
    &:before{
        top: 0;
        left: -50%;
    }
    &:after{
        bottom: 0;
        right: -50%;
    }
    #bar{
        height: 2px;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 3;
        transform: translateY(-1px) translateX(-50%);
        background: #FFF;
        opacity: 1;
        transition: opacity 400ms, transform 400ms;
    }
    #num{
        font-family: Arial, Helvetica, "sans-serif";
        font-size: 20px;
        line-height: 1;
        text-align: center;
        position: absolute;
        top: calc(50% + 30px);
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        position: fixed;
        pointer-events: none;
        color: #FFF;
        opacity: 1;
        z-index: 4;
        transition: opacity 400ms, transform 400ms;
    }
    .svg{
        width: 60vh;
        position: absolute;
        top: 50%;
        left: calc(50% + 5px);
        transform: translateY(-50%) translateX(-50%);
        z-index: 2;
        opacity: .16;
        transition: opacity 400ms, transform 400ms;
        @media screen and (max-width:$responsive){
            width: 60vw;
        }
        svg{
            width: 100%;
        }
        .st0{
            fill: $normal;
        }
    }
}

// CONTACTFORM7
.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output{
    border-color: $red;
}
