.site-contact{
    h2.huge{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        @media screen and (max-width:$tab){
            font-size: 20px;   
        }
        @media screen and (max-width:$responsive){
            font-size: 15px;   
        }
    }
    .kugiri{
        margin: 24px 0 36px;
        .telarea{
            margin-bottom: 0;
        }
    }
    .context{
        margin: 0 0 16px;
        font-size: 17px;
        text-align: center;
        @media screen and (max-width:$responsive){
            font-size: 14px;   
        }
    }
    .box{
        width: 88%;
        margin: 0 auto;
        @media screen and (max-width:$tab){
            width: auto;   
        }
    }
	.privacy{
		margin: 40px 0 0;
		padding: 32px;
		background: $glay;
		@media screen and (max-width:$responsive){
			margin: 24px 0 0;
			padding: 16px;	
		}
		dt{
			font-size: 17px;
			font-weight: 700;
			margin: 0 0 4px;
			@media screen and (max-width:$responsive){
				font-size: 15px;	
			}
		}
		dd{
			line-height: 1.66;
		}
	}
}