#site-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $pc-head;
    z-index: 2000;
    padding: 0 40px;
    @include flex();
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #FFF;
    @include transition(400ms);
    z-index: 2000;
    @media screen and (max-width:$tab){
        height: $sp-head;
        color: $normal;
        padding: 0 15px;
        position: absolute;
        a{
            color: $normal;
        }
    }
    @media screen and (min-width:$tab + 1px){
        &.active,
        &.second,
        &:hover{
            background: #FFF;
            color: $normal;
            .logo{
                &:after{
                    opacity: 1;
                }
                img{
                    opacity: 0;
                }
            }
            .gnavi{
                > ul{
                    li{
                        > a{
                            color: $normal;
                        }
                    }
                }
            }
            .subnavi{
                background: #eee;
                ul{
                    li{
                        a{
                            color: $normal;
                            i{
                                color: $red;
                            }
                            &.contact{
                                color: #FFF;
                                i{
                                    color: #FFF;
                                }
                            }
                        }
                        &:first-child{
                            &:after{
                                border-color: $normal;
                                opacity: .32;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:$tab){
        &.second{
            background: #FFF;
            color: $normal;
            .logo{
                &:after{
                    opacity: 1 !important;
                }
                img{
                    opacity: 0 !important;
                }
            } 
        } 
    }
    .logo{
        margin: 2px 0 0;
        width: 90px;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("../../../images/common/logo_2x.png") no-repeat;
            background-size: cover;
            opacity: 0;
            pointer-events: none;
            @include transition(400ms);
            @media screen and (max-width:$tab){
                opacity: 0 !important;   
            }
        }
        img{
            width: 100%;
            opacity: 1;
            @include transition(400ms);
            @media screen and (max-width:$tab){
                opacity: 1 !important;   
            }
        }
    }
    .trigger,
    .close{
        display: none;
        @media screen and (max-width:$tab){
            display: block;
            $size: 44px;
            width: $size;
            height: $size;
            background: $normal;
            border-radius: 50%;
            position: fixed;
            top: 13px;
            right: 15px;
            cursor: pointer;
            z-index: 2000;
            box-shadow: 0 0 15px rgba(#000,.12);
            @include transition(200ms);
            &:before,
            &:after{
                content: '';
                width: 16px;
                height: 1px;
                background: #FFF;
                position: absolute;
                left: 50%;
                top: 42%;
                transform: translateX(-50%);
            }
            &:after{
                top: auto;
                bottom: 40%;
            }
        }
    }
    .close{
        @media screen and (max-width:$tab){
            background: #666;
            z-index: 9999;
            opacity: 0;
            &.active{
                animation: role 400ms forwards;
                @keyframes role{
                    0%{
                        opacity: 0;
                        transform: scale(.8) rotate(90deg);
                    }
                    100%{
                        opacity: 1;
                        transform: scale(1) rotate(0deg);
                    }
                }
            }
            &:before,
            &:after{
                background: #FFF;
                bottom: auto;
                top: 50%;
                left: 31%;
                transform: rotate(45deg);
            }
            &:after{
                transform: rotate(-45deg);
            }
        }
    }
    
    .navigation{
        @media screen and (min-width:$tab + 1px){
            display: block !important;   
        }
    }
    .gnavi{
        @media screen and (min-width:$tab + 1px){
            margin: -1px 0 0 72px;
            > ul{
                @include flex();
                align-items: flex-start;
                > li{
                    list-style: none;
                    font-weight: 700;
                    margin: 0 40px 0 0;
                    &.none{
                        display: none;
                    }
                    @media screen and (max-width:1350px){
                        &:first-child{
                            display: none;
                        }   
                    }
                    i{
                        display: inline-block;
                        margin: 0 0 0 8px;
                        font-size: .8rem;
                        transform: translateY(-1px);
                    }
                    > a{
                        color: #FFF;
                        @include transition(400ms);
                        position: relative;
                        &:after{
                            content: '';
                            width: 0;
                            height: 3px;
                            background: $red;
                            position: absolute;
                            left: 50%;
                            bottom: -6px;
                            transform: translateX(-50%);
                            @include transition(120ms);
                        }
                        &:hover{
                            &:after{
                                width: 64px;
                            }
                        }
                    }
                    &:last-child{
                        margin: 0;
                    }
                    dl{
                        &:hover{
                            dt{
                                i{
                                    color: $red;
                                    transform: rotate(-180deg);
                                }
                            }
                            dd{
                                display: block !important;
                                animation: fade 400ms forwards;
                                @keyframes fade{
                                    0%{
                                        opacity: 0;
                                        transform: translateY(-10px);
                                    }
                                    100%{
                                        opacity: 1;
                                        transform: translateY(0);
                                    }
                                }
                            }
                        }
                        dt{
                            cursor: pointer;
                            position: relative;
                            i{
                                display: inline-block;
                                @include transition(120ms);
                            }
                            &:before{
                                content: '';
                                width: 100%;
                                height: $pc-head;
                                position: absolute;
                                top: -24px;
                                left: 0;
                            }
                        }
                        dd{
                            width: 100%;
                            position: absolute;
                            top: $pc-head;
                            left: 0;
                            background: $glay;
                            opacity: 0;
                            display: none;
                            ul{
                                padding: 32px 0;
                                @include flex();
                                align-items: center;
                                justify-content: flex-start;
                                li{
                                    width: 20%;
                                    text-align: center;
                                    border-right: rgba(#000,.12) 1px solid;
                                    &:last-child{
                                        border: none;
                                    }
                                    a{
                                        font-size: 14px;
                                        display: block;
                                        &:hover{
                                            color: $red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width:$tab){
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background: $normal;
            z-index: 9000;
            color: #FFF;
            font-size: 15px;
            a{
                color: #FFF;
            }
            > ul{
                position: relative;
                z-index: 2;
                padding: 80px 15px 15px;
                > li{
                    border-bottom: rgba(#FFF,.12) 1px solid;
                    &:last-child{
                        border: none;
                    }
                    a{
                        display: block;
                        padding: 15px 0;
                        position: relative;
                        &:after{
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            content: "\f054";
                            position: absolute;
                            top: 50%;
                            right: 15px;
                            transform: translateY(-50%);
                            font-size: 12px;
                            opacity: .48;
                        }
                    }
                    &.other{
                        @include flex();
                        padding: 15px 0;
                        a{
                            width: calc(50% - 7.5px);
                            padding: 10px 15px;
                            display: block;
                            background: rgba(#FFF,.1);
                            border-radius: 4px;
                            &:hover{
                                background: rgba(#FFF,.08);
                            }
                            i{
                                font-size: 12px;
                                position: absolute;
                                top: 50%;
                                right: 15px;
                                transform: translateY(-50%);
                                opacity: .48;
                            }
                            &:after{
                                display: none;
                            }
                        }
                    }
                }
                dl{
                    dt{
                        padding: 15px 0;
                        position: relative;
                        cursor: pointer;
                        &.active{
                            i{
                                transform: rotate(180deg) translateY(50%);
                                opacity: 1;
                            }
                        }
                        > i{
                            position: absolute;
                            top: 50%;
                            right: 15px;
                            transform: translateY(-50%);
                            font-size: 11px;
                            opacity: .48;
                            @include transition(200ms);
                        }
                    }
                    dd{
                        background: rgba(#FFF,.08);
                        border-radius: 4px;
                        margin: 0 0 15px;
                        a{
                            padding: 15px;
                        }
                        ul{
                            &.active{
                                li{
                                    @for $i from 1 through 10{
                                        &:nth-child(#{$i}){
                                            animation: shows 300ms 80ms*$i forwards;
                                            @keyframes shows{
                                                0%{
                                                    opacity: 0;
                                                    transform: translateY(20px);
                                                }
                                                100%{
                                                    opacity: 1;
                                                    transform: translateY(0);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            li{
                                border-bottom: rgba(#FFF,.12) 1px solid;
                                opacity: 0;
                                &:last-child{
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .subnavi{
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 0 0 40px;
        @include transition(400ms);
        @media screen and (max-width:$tab){
            display: none;
        }
        ul{
            @include flex();
            align-items: center;
            li{
                &:first-child{
                    margin: 0 28px 0 0;
                    padding: 0 28px 0 0;
                    position: relative;
                    &:after{
                        content: '';
                        width: 2px;
                        height: 70%;
                        border-left: #FFF 2px dotted;
                        transform: rotate(20deg) translateX(100%);
                        position: absolute;
                        top: 15%;
                        right: 0;
                    }
                }
                &:last-child{
                    margin: 0 0 0 40px;
                }
                a{
                    font-weight: 700;
                    color: #FFF;
                    &:hover{
                        text-decoration: underline;
                        @include transition(200ms);
                    }
                    @include transition(400ms);
                    &.contact{
                        position: relative;
                        padding: 12px 24px;
                        display: block;
                        height: $pc-head;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        @include transition(400ms);
                        &:before,
                        &:after{
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: $red;
                            z-index: 1;
                        }
                        &:after{
                            width: 0;
                            background: $normal;
                            @include transition(200ms);
                        }
                        &:hover{
                            text-decoration: none;
                            opacity: 1;
                            &:after{
                                width: 100%;
                            }
                        }
                        i,span{
                            position: relative;
                            z-index: 2;
                        }
                        i{
                            width: 100%;
                            margin: 0;
                            font-size: 20px;
                            display: block;
                            text-align: center;
                        }
                        span{
                            display: block;
                            font-size: 13px;
                            text-align: center;
                            letter-spacing: .12em;
                        }
                    }
                    i{
                        display: inline-block;
                        margin: 0 0 0 8px;
                        font-size: .6rem;
                        transform: translateY(-1px);
                        @include transition(400ms);
                    }
                }
            }
        }
    }
    
    /*
    .menu-trigger{
        cursor: pointer;
        &__item{
            $side: 30px;
            width: $side;
            height: $side;
            margin: 0 auto;
            position: relative;
            &__border,
            &:before,
            &:after{
                content: '';
                width: 26px;
                height: 3px;
                background: $blue;
                position: absolute;
                border-radius: 999px;
                top: 7px;
                left: 50%;
                transform: translateX(-50%);
            }
            &:after{
                top: auto;
                bottom: 7px;
            }
            &__border{
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }
        &__text{
            @include osw();
            white-space: nowrap;
            display: block;
            font-size: 12px;
            line-height: 1;
            text-align: center;
            margin: 2px 0 0;
            letter-spacing: .08em;
        }
    }
    */
    
    
}