.site-index{
    .firstsec{
        &.wrap{
            padding-top: 64px;
            padding-bottom: 64px;
            @media screen and (max-width:$tab){
                padding-top: 20px;
                padding-bottom: 20px;
            }
            @media screen and (max-width:$responsive){
                padding-top: 15px;   
                padding-bottom: 15px;   
            }
        }
        &__bnr{
            img{
                width: 100%;
                pointer-events: auto;
            }
        }
        #bnr-swipe{
            .swiper-wrapper{
                transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
            }
            .swiper-button-next,
            .swiper-button-prev{
                width: 56px;
                height: 56px;
                background: #FFF;
                border-radius: 50%;
                box-shadow: 0 0 16px rgba(#000,.08);
                overflow: hidden;
                margin-top: 0;
                transform: translateY(-50%) scale(.64);
                opacity: 0;
                @include flex();
                align-items: center;
                justify-content: center;
                font-size: 15px;
                color: $red;
                @include transition(200ms);
                @media screen and (max-width:$responsive){
                    width: 40px;
                    height: 40px;
                }
            }
            .swiper-button-next{
                right: 20px;
                @media screen and (max-width:$responsive){
                    right: 15px;   
                }
                i{
                    transform: translateX(2px);
                }
            }
            .swiper-button-prev{
                left: 20px;
                @media screen and (max-width:$responsive){
                    left: 15px;   
                }
                i{
                    transform: translateX(-2px);
                }
            }
            &:hover{
                .swiper-button-next,
                .swiper-button-prev{
                    transform: translateY(-50%) scale(1);
                    opacity: 1;
                }
            }
        }
    }
    
    .resulted{
        &.wrap{
            background: $glay;
            @media screen and (max-width:$responsive){
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
            }
        }
        .score{
            font-weight: 700;
            @include flex();
            justify-content: center;
            align-items: flex-end;
            position: relative;
            @media screen and (max-width:$responsive){
                display: block;
                margin: 0 0 15px;
            }
            h2{
                position: absolute;
                right: 0;
                top: 12px;
                line-height: 1;
                font-weight: 700;
                z-index: 3;
                color: $red;
                font-size: 60px;
                border-bottom: $red 8px solid;
                padding: 0 0 12px;
                text-shadow: none;
                @media screen and (max-width:1350px){
                    font-size: 44px;
                    padding: 0 0 8px;
                }
                @media screen and (max-width:1150px){
                    font-size: 36px;
                }
                @media screen and (max-width:$tab){
                    top: 0;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 28px;
                    border-bottom-width: 5px;
                    top: 18px;
                    right: 15px;
                    padding: 0 0 4px;
                }
            }
            &__badge{
                position: absolute;
                top: 0;
                left: 0;
                width: 260px;
                z-index: 10;
                @media screen and (max-width:$tab){
                    width: 150px;   
                }
                @media screen and (max-width:$responsive){
                    top: auto;
                    bottom: 64px;
                    left: 8px;
                    width: 100px;
                }
                img{
                    width: 100%;
                }
            }
            &__catch{
                width: calc(100% - 400px);
                transform: translateX(2%);
                position: relative;
                z-index: 2;
                @media screen and (max-width:$tab){
                    width: 64%;
                    transform: translateX(0);
                }
                @media screen and (max-width:$responsive){
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 240px;
                    width: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    background: #FFF;
                    &:before{
                        content: '';
                        background: #FFF;
                        position: absolute;
                        top: 0;
                        right: -15%;
                        width: 60%;
                        height: 100%;
                        z-index: 2;
                        transform: skew(-20deg);
                    }
                }
                img{
                    width: 100%;
                    @media screen and (max-width:$responsive){
                        transform: translateX(-20%);
                    }
                    @media screen and (max-width:360px){
                        width: 110%;
                    }
                    @media screen and (max-width:325px){
                        width: 120%;
                    }
                }
                &:after{
                    content: '';
                    width: 102%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: -1%;
                    z-index: 2;
                    box-shadow: inset 0 0 40px 48px $glay;
                    @media screen and (max-width:$responsive){
                         box-shadow: none;
                    }
                }
            }
            &__number{
                width: 400px;
                text-align: right;
                margin: 0 0 40px;
                position: relative;
                z-index: 3;
                @media screen and (max-width:$tab){
                    width: 36%;
                    margin: 0 0 20px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 56px 0 0;
                    margin: 0;
                }
                dd{
                    line-height: 1;
                    font-size: 100px;
                    white-space: nowrap;
                    color: $red;
                    text-shadow: none;
                    @media screen and (max-width:$responsive){
                        font-size: 52px;
                        transform: translateX(4px);
                        margin: 0 15px;
                    }
                    strong{
                        display: inline-block;
                        @include osw;
                        font-size: 280px;
                        transform: translateY(4%);
                        @media screen and (max-width:1350px){
                            font-size: 240px;   
                        }
                        @media screen and (max-width:1250px){
                            font-size: 200px;   
                        }
                        @media screen and (max-width:1150px){
                            font-size: 148px;
                        }
                        @media screen and (max-width:$tab){
                            @include fontsize(90px,180);
                        }
                        @media screen and (max-width:$responsive){
                            font-size: 140px;   
                        }
                    }
                }
                dt{
                    width: calc(100% + 100px);
                    transform: translateX(-100px);
                    margin: 28px 0 0;
                    background: $red;
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
                    font-size: 36px;
                    position: relative;
                    @media screen and (max-width:$tab){
                        font-size: 24px;
                        margin: 12px 0 0;
                    }
                    @media screen and (max-width:$responsive){
                        width: 100%;
                        transform: translateX(0);
                        text-align: center;
                        padding: 2px 15px;
                        margin: 16px 0 0;
                        font-size: 26px;
                    }
                    &:after{
                        content: '';
                        width: 60px;
                        height: 100%;
                        background: $red;
                        transform: skewX(-30deg);
                        position: absolute;
                        top: 0;
                        left: -24px;
                        @media screen and (max-width:$responsive){
                            display: none;
                        }
                    }
                }  
            }
        }
        .other{
            border: $normal 6px solid;
            background: #FFF;
            @include flex();
            @media screen and (max-width:$responsive){
                margin: 0 15px;   
            }
            li{
                width: 33.3%;
                padding: 16px;
                border-right: $normal 6px solid;
                @include flex();
                align-items: center;
                justify-content: center;
                @media screen and (max-width:$responsive){
                    width: 50%;
                    &:nth-child(2){
                        border-right: none;
                    }
                }
                &:last-child{
                    border: none;
                    @media screen and (max-width:$responsive){
                        width: 100%;
                        border-top: $normal 6px solid;
                    }
                }
                dl{
                    font-weight: 700;
                    text-align: center;
                    dd{
                        line-height: 1;
                        font-size: 48px;
                        color: $red;
                        text-shadow: none;
                        @media screen and (max-width:$tab){
                            font-size: 32px;   
                        }
                        @media screen and (max-width:$responsive){
                            font-size: 28px;   
                        }
                        strong{
                            @include osw();
                            font-size: 120px;
                            display: inline-block;
                            @media screen and (max-width:$tab){
                                font-size: 96px;   
                            }
                            @media screen and (max-width:$responsive){
                                font-size: 72px;   
                            }
                        }
                    }
                    dt{
                        margin: 8px 0 0;
                        font-size: 24px;
												line-height: 1.56;
                        @media screen and (max-width:$tab){
                            font-size: 18px;   
                        }
                        @media screen and (max-width:$responsive){
                            line-height: 1.5;   
                        }
                    }
                }
            }
            &__block{
                @media screen and (max-width:$tab){
                    width: 100%;   
                }
                &__txt{
                    text-align: center;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 1.5;
                    @media screen and (max-width:$tab){
                        font-size: 18px;   
                    }
                }
                .btnarea{
                    margin: 12px 0 0;
                    @media screen and (max-width:$tab){
                        a{
                            display: block;
                            min-width: 1px;
                            max-width: 100%;
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        .score_param{
            &.active{
                animation: scales 300ms forwards;
                @keyframes scales{
                    0%{
                        transform: scale(1);
                    }
                    20%{
                        transform: scale(.8);
                    }
                    60%{
                        transform: scale(1.4);
                    }
                    100%{
                        transform: scale(1);
                    }
                }
            }
        }
    }
    
    .secondsec{
        position: relative;
        background: url("../../../images/index/sec_q.png") no-repeat right -80px bottom 40px;
        background-size: auto 80%;
        @media screen and (max-width:$tab){
            background: none;   
        }
        &.site-module{
            h2.large{
                margin-left: 8%;
                position: relative;
                z-index: 10;
                color: $red;
                @media screen and (max-width:$tab){
                    margin-left: 20px;   
                }
                @media screen and (max-width:$responsive){
                    margin-left: 0;
                    position: absolute;
                    top: 28px;
                    left: 15px;
                }
                &:before{
                    font-size: 96px;
                    @media screen and (max-width:$tab){
                        font-size: 64px;
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 36px;   
                    }
                }
                strong{
                    display: inline-block;
                    transform: translateY(-40px);
                    font-size: 22px;
                    @media screen and (max-width:$tab){
                        font-size: 20px;
                        transform: translateY(-32px);
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 16px;
                        transform: translateY(-15px);
                    }
                }
            }
        }
        &.wrap{
            padding-left: 0;
            padding-right: 0;
        }
        .display{
            z-index: 10;
        }
        &__wrap{
            position: relative;
            padding: 0 40px 64px;
            @media screen and (max-width:$tab){
                padding: 0 20px 40px;   
            }
            @media screen and (max-width:$responsive){
                padding: 0 15px;   
            }
        }
        &__bg{
            width: 50%;
            height: calc(100% + 64px);
            background-size: cover;
            position: absolute;
            top: -64px;
            left: 0;
            @media screen and (max-width:$responsive){
                width: calc(100% + 30px);
                height: 260px;
                position: relative;
                top: 0;
                left: -15px;
                margin: 24px 0 0;
            }
        }
        &__box{
            margin: 0 0 0 50%;
            padding: 0 0 0 56px;
            @media screen and (max-width:$tab){
                padding: 0 0 0 32px;   
            }
            @media screen and (max-width:$responsive){
                padding: 32px 15px 0;
                margin: 0;
            }
            h2{
                font-weight: 700;
                @include fontsize(22px, 7);
                line-height: 1.56;
                @media screen and (max-width:$responsive){
                    font-size: 17px;
                    text-align: center;
                }
                &:before{
                    content: attr(data-eng);
                    font-size: 16px;
                    color: $red;
                    display: block;
                    text-indent: .1em;
                    margin: 0 0 12px;
                    @media screen and (max-width:$responsive){
                        text-indent: 0em;
                        margin: 0 0 8px;
                    }
                }
            }
            &__txt{
                @include fontsize(12.5px, 2.4);
                margin: 20px 0 0;
                text-align: justify;
                @media screen and (max-width:$tab){
                    font-size: 14px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 13.5px;   
                }
            }
        }
    }
    
    .thirdsec{
        position: relative;
        &__items{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            li{
                list-style: none;
                width: 31%;
                background: #FFF;
                @media screen and (max-width:$tab){
                    width: 32%;   
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 15px;
                    box-shadow: 0 0 15px rgba(#000,.06);
                    &:last-child{
                        margin: 0;
                    }
                }
                figure{
                    img{
                        width: 100%;
                    }
                }
                dl{
                    padding: 24px;
                    @media screen and (max-width:$tab){
                        padding: 20px;   
                    }
                    dt{
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 1.56;
                        text-align: center;
                        padding: 12px 0 0;
                        &:before{
                            content: attr(data-text);
                            font-size: 12px;
                            color: $red;
                            letter-spacing: .04em;
                            text-transform: uppercase;
                            display: block;
                            margin: 0 0 8px;
                        }
                    }
                    dd{
                        font-size: 14px;
                        text-align: justify;
                        position: relative;
                        margin: 12px 0 0;
                        padding: 12px 0 0;
                        border-top: $border 1px solid;
                        @media screen and (max-width:$tab){
                            font-size: 13px;   
                        }
                    }
                }
                @for $i from 1 through 5{
                    &:nth-child(#{$i}){
                        dl{
                            position: relative;
                            &:after{
                                @include osw();
                                content: '0#{$i}';
                                font-size: 15px;
                                color: #FFF;
                                position: absolute;
                                left: 50%;
                                top: 0;
                                transform: translateX(-50%) translateY(-50%);
                            }
                            &:before{
                                content: '';
                                position: absolute;
                                left: calc(50% - 20px);
                                top: -20px;
                                width: 40px;
                                height: 40px;
                                transform: rotate(45deg);
                                background: $red;
                            }
                        }
                    }
                }
            }
        }
        .btnarea{
            margin: 48px 0 0;
            @media screen and (max-width:$responsive){
                margin: 24px 0 0;   
            }
        }
    }
    .forsec{
        position: relative;
        &.wrap{
            padding-left: 0;
            padding-right: 0;
        }
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url("../../../images/index/photos.jpg") no-repeat center;
            background-size: cover;
            opacity: .12;
        }
        #index-swipe{
            position: relative;
            z-index: 2;
            padding: 0 40px;
            .swiper-wrapper{
                transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
            }
            .swiper-slide{
                width: 300px;
            }
            .bg{
                width: 300px;
                height: 300px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    .fifthsec{
        .bnrlist{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            li{
                width: 31.5%;
                @media screen and (max-width:$responsive){
                    width: 100%;
                    margin: 0 0 15px;
                    &:last-child{
                        margin: 0;
                    }
                }
            }
            img{
                width: 100%;
            }
        }
    }
	.news{
        position: relative;
        z-index: 2;
		background: $red;
		color: #FFF;
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width:$responsive){
					display: block;	
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width:$responsive){
				width: 100%;
				padding: 0;
				display: block;	
			}
		}
        .leftside{
            width: 280px;
			@media screen and (max-width:$responsive){
				width: 100%;
			}
			h3{
				line-height: 1;
				font-size: 14px;
				margin: 0 0 16px;
				font-weight: 700;
				@media screen and (max-width:$responsive){
					font-size: 12px;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 36px;
					display: inline-block;
					margin: 0 24px 0 0;
					@media screen and (max-width:$responsive){
						font-size: 20px;	
					}
				}
			}
			a{
				display: inline-block;
				font-weight: 700;
				color: #FFF;
				font-size: 15px;
				@media screen and (max-width:$responsive){
					position: absolute;
					top: 0;
					right: 0;
					font-size: 13px;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: 13px;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 130px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width:$responsive){
				width: 100%;
				height: 164px;
				padding: 15px;
				border: rgba(#FFF,.2) 1px solid;
				border-radius: 4px;
			}
            li{
                border-bottom: rgba(#FFF,.16) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width:$responsive){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: #FFF;
					@media screen and (max-width:$tab){
						display: block;
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                span.time{
                    width: 14%;
                    font-size: 12px;
                    background: $normal;
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width:$tab){
						width: 115px;
                        display: inline-block;
						font-size: 12px;
						padding: 1px 12px;
						margin: 2px 0 0;
					}
                }
                h4{
                    width: 86%;
                    font-size: 16px;
                    padding: 0 0 0 32px;
					@media screen and (max-width:$tab){
						width: auto;
						font-size: 14px;
						padding: 8px 0 0;
						clear: both;
					}
                }
            }
        }
    }
    .sns{
        background: #f2f2f2;
        &.wrap{
            padding-top: 48px;
            padding-bottom: 48px;  
            @media screen and (max-width:$responsive){
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
        .snslist{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            &__items{
                width: 48.5%;
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 20px;
                    &:last-of-type{
                        margin: 0;
                    }
                }
                iframe{
                    width: 100% !important;
                    vertical-align: bottom;
                }
            } 
        }

    }
}