.site-leader{
	$yellow: #ffe013;
    span,strong{
		&.marker{
	        background: -webkit-linear-gradient(transparent 70%, $yellow 70%);
			background: -o-linear-gradient(transparent 70%, $yellow 70%);
			background: linear-gradient(transparent 70%, $yellow 70%);
		}
    }
	.site-module{
		&.wrap.none{
			padding: 0;
			overflow: visible;
		}
	}

	.leader-h{
		text-align: center;
		font-size: 32px;
		font-weight: 700;
		letter-spacing: 0em;
		@media screen and (max-width:$responsive){
			font-size: 20px;
			margin: 0 15px 6px;
		}
	}
	.fv{
		height: 450px;
		background: url("../../../images/leader/main.jpg") no-repeat center top;
		background-size: cover;
		margin: $pc-head 0 0;
		position: relative;
		@media screen and (max-width:$tab){
			height: 340px;	
		}
		@media screen and (max-width:$responsive){
			height: 200px;
		}
		figure{
			width: 40%;
			position: absolute;
			top: 40px;
			left: 50%;
			transform: translateX(-50%);
			@media screen and (max-width:$responsive){
				width: 64%;	
				top: 24px;
			}
			img{
				width: 100%;
			}
		}
		.txt{
			position: absolute;
			bottom: 40px;
			left: 50%;
			font-size: 18px;
			font-weight: 700;
			transform: translateX(-50%);
			background: $yellow;
			padding: 10px 40px;
			white-space: nowrap;
			border-radius: 999px;
			box-shadow: 0 12px 24px rgba(#000,.12);
			@media screen and (max-width:$tab){
				font-size: 17px;
				bottom: 32px;
			}
			@media screen and (max-width:$responsive){
				bottom: 24px;
				white-space: normal;
				font-size: 13px;
				width: calc(100% - 30px);
				text-align: center;
				bottom: 0;
				transform: translateX(-50%) translateY(50%);
				box-shadow: none;
			}
		}
	}
	.context-area{
		margin: 64px 0;
		@media screen and (max-width:$responsive){
			margin: 56px 0 32px;	
		}
		.context{
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			line-height: 1.6;
			margin: 8px 0 0;
			@media screen and (max-width:$responsive){
				font-size: 14px;
				margin: 0 15px;
			}
		}
	}
	.clums{
		position: relative;
		background: $glay;
		&:before{
			content: '';
			width: 200px;
			height: 120px;
			position: absolute;
			right: 6%;
			top: -40px;
			background: url("../../../images/leader/icon.png") no-repeat;
			background-size: cover;
			z-index: 2;
			@media screen and (max-width:$tab){
				width: 120px;
				height: 72px;
			}
			@media screen and (max-width:$responsive){
				display: none;
			}
		}
		&__bg{
			position: absolute;
			top: 0;
			left: -40px;
			width: calc(50% + 40px);
			height: 100%;
			background: url("../../../images/leader/photo.jpg") no-repeat center;
			background-size: cover;
			@media screen and (max-width:$responsive){
				position: relative;
				top: 0;
				left: 0;
				width: 100%;
				height: 200px;
			}
		}
		&__txt{
			padding: 80px 40px;
			padding-left: 56px;
			width: 50%;
			margin: 0 0 0 50%;
			@media screen and (max-width:$tab){
				padding: 64px 32px;
			}
			@media screen and (max-width:$responsive){
				position: relative;
				width: auto;
				padding: 24px 15px;
				margin: 0;
			}
			dt{
				font-size: 28px;
				font-weight: 700;
				line-height: 1.6;
				margin: 0 0 20px;
				padding: 0 0 18px;
				letter-spacing: 0em;
				position: relative;
				@media screen and (max-width:$tab){
					font-size: 18px;	
				}
				&:before,
				&:after{
					content: '';
					width: 100%;
					height: 4px;
					position: absolute;
					left: 0;
					bottom: 0;
					background: $normal;
				}
				&:after{
					background: $yellow;
					width: 100px;
				}
			}
			dd{
				font-size: 16px;
				text-align: justify;
				line-height: 1.8;
				@media screen and (max-width:$tab){
					font-size: 14px;	
				}
				@media screen and (max-width:$responsive){
					font-size: 13.5px;	
				}
			}
		}
	}
	.listable{
		@include flex();
		@media screen and (max-width:$tab){
			display: block;	
		}
		li{
			width: calc(50% - 16px);
			border: $glay 8px solid;
			@media screen and (max-width:$tab){
				width: auto;
				margin: 0 0 48px;
				&:last-child{
					margin: 0;
				}
			}
			@media screen and (max-width:$responsive){
				margin: 0 0 32px;	
			}
			dl{
				dt{
					background: $yellow;
					font-size: 22px;
					font-weight: 700;
					text-align: center;
					margin: 0 20px;
					padding: 6px 15px;
					position: relative;
					transform: translateY(-50%);
					border-radius: 4px;
					@media screen and (max-width:$tab){
						font-size: 18px;	
					}
					@media screen and (max-width:$responsive){
						font-size: 16px;
					}
					&:before{
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 16px 20px 0 20px;
						border-color: $yellow transparent transparent transparent;
						position: absolute;
						left: 50%;
						bottom: -16px;
						transform: translateX(-50%);
						@media screen and (max-width:$tab){
							bottom: -14px;	
						}
					}
				}
				dd{
					text-align: justify;
					padding: 0 20px 20px;
					font-size: 15px;
					line-height: 1.7;
					border-top: 0;
					@media screen and (max-width:$tab){
						font-size: 14px;	
					}
					@media screen and (max-width:$responsive){
						padding: 0 15px 15px;	
					}
					&.block{
						text-align: center;
						font-weight: 700;
						display: block;
						background: $glay;
						padding: 12px 0;
						font-size: 16px;
						margin: 0 20px 16px;
						border-radius: 4px;
						@media screen and (max-width:$responsive){
							margin: 0 15px 15px;
							padding: 8px 15px;
							font-size: 15px;
						}
					}
				}
			}
		}
	}
	p.large{
		font-size: 22px;
		text-align: center;
		font-weight: 700;
		margin: 40px 0 0;
		padding: 24px 20px;
		background: $glay;
		letter-spacing: 0em;
		@media screen and (max-width:$tab){
			border-radius: 4px;
			font-size: 18px;
			margin: 24px 0 0;
		}
		@media screen and (max-width:$responsive){
			font-size: 16px;
			margin: 16px 0 0;
			padding: 15px;
			text-align: left;
		}
	}
	
	.archive-box{
		width: 88%;
		margin: 0 auto;
		@media screen and (max-width:$tab){
			width: auto;	
		}
		.borders{
			border-bottom: rgba($normal,.08) 1px solid;
			&:after{
				background: $yellow;
			}
		}
		.list{
			> li{
				background: #FFF;
				padding: 24px;
				margin: 0 0 32px;
				box-shadow: 0 0 24px rgba($normal, .06);
				@media screen and (max-width:$responsive){
					padding: 15px;
					margin: 0 0 15px;
				}
				&:last-child{
					margin: 0;
				}
			}
			&__title{
				@include flex();
				align-items: center;
				justify-content: flex-start;
				margin: 0 0 20px;
				@media screen and (max-width:$responsive){
					margin: 0 0 15px;
					display: block;
				}
				time{
					background: $yellow;
					font-weight: 700;
					font-size: 13px;
					display: block;
					padding: 2px 0;
					width: 120px;
					text-align: center;
					margin: 0 20px 0 0;
				}
				h4{
					font-size: 18px;
					font-weight: 700;
					@media screen and (max-width:$responsive){
						font-size: 17px;
						margin: 12px 0 0;
						line-height: 1.56;
					}
				}
			}
			&__pics{
				margin: 0 0 20px -24px;
				@include flex();
				justify-content: flex-start;
				@media screen and (max-width:$responsive){
					display: block;
					margin: 0 0 20px;
				}
				> li{
					width: 33.3%;
					padding: 0 0 0 24px;
					margin: 24px 0 0;
					&:nth-child(-n+3){
						margin-top: 0;
					}
					@media screen and (max-width:$responsive){
						width: auto;
						padding: 0;
						margin: 0 0 15px;
						&:last-child{
							margin: 0;
						}
					}
					figure{
						img{
							width: 100%;
							height: auto;
						}
					}
				}
			}
			&__desc{
				text-align: left;
				background: $glay;
				font-size: 15.5px;
				padding: 16px 20px;
				@media screen and (max-width:$responsive){
					padding: 15px;
					font-size: 13.5px;
				}
			}
		}
	}
}