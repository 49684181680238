.site-e-tore{
    $green: #009225;
    .e-fv{
        background: #fcfcfc url("../../../images/e-tore/fv_bg.jpg") center no-repeat;
        margin: $pc-head 0 0;
        border-bottom: rgba(#000,.04) 1px solid;
        @media screen and (max-width:$tab){
            margin: $sp-head 0 0;   
        }
        @media screen and (max-width:$responsive){
            background-size: cover;
        }
        .display{
            height: 310px;
            @include flex();
            align-items: center;
            justify-content: flex-start;
            @media screen and (max-width:$tab){
                height: 280px;    
            }
            @media screen and (max-width:$responsive){
                height: 200px;   
            }
        }
        &__logo{
            margin-left: 4%;
            @media screen and (max-width:$tab){
                margin-left: 6%;   
            }
            @media screen and (max-width:$responsive){
                margin-left: 15px;
                img{
                    width: 180px;
                }
            }
            figcaption{
                font-size: 28px;
                font-weight: 700;
                line-height: 1;
                margin: 16px 0 0;
                @media screen and (max-width:$tab){
                    font-size: 20px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 17px;   
                }
            }
        }
    }
    .first{
        position: relative;
        &.wrap{
            padding-bottom: 56px;
            @media screen and (max-width:$tab){
                padding-bottom: 32px;   
            }
        }
        &:before{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            background: $glay url("../../../images/e-tore/black_dotto.png") repeat;
            background-size: 16px;
            width: 100%;
            height: 136px;
            @media screen and (max-width:$responsive){
                height: 32%;   
            }
        }
        dl.context{
            font-weight: 700;
            text-align: center;
            line-height: 1.56;
            dt{
                font-size: 15px;
                margin: 0 0 16px;
                @media screen and (max-width:$responsive){
                    font-size: 14px;   
                }
            }
            dd{
                font-size: 24px;
                @media screen and (max-width:$responsive){
                    font-size: 18px;
                    br{
                        display: none;
                    }
                }
                strong{
                    color: $green;
                }
            }
        }
        h1.catch{
            text-align: center;
            color: #FFF;
            font-size: 32px;
            font-weight: 700;
            background: $green url("../../../images/e-tore/white_dotto.png") repeat;
            background-size: 16px;
            border-radius: 8px;
            margin: 24px 0 0;
            padding: 24px;
            line-height: 1.56;
            position: relative;
            @media screen and (max-width:$tab){
                padding: 20px;
                font-size: 26px;
            }
            @media screen and (max-width:$responsive){
                padding: 15px;
                font-size: 17px;
                margin: 15px 0 0;
            }
            &:before,
            &:after{
                content: '';
                width: 125px;
                height: 126px;
                background: url("../../../images/e-tore/arrow_yel.png") no-repeat;
                background-size: 125px;
                position: absolute;
                top: -80px;
                left: 10%;
                @media screen and (max-width:$tab){
                    width: 62px;
                    height: 63px;
                    background-size: 62px;
                    top: -32px;
                    left: 5%;
                }
                @media screen and (max-width:$responsive){
                    width: 32px;
                    height: 33px;
                    background-size: 32px;
                    top: -15px;
                    left: 15px;
                }
            }
            &:after{
                left: auto;
                right: 10%;
                transform: rotateY(-180deg);
                @media screen and (max-width:$tab){
                    right: 5%;   
                }
                @media screen and (max-width:$responsive){
                    right: 15px;   
                }
            }
        }
    }
    .second{
        background: $glay url("../../../images/e-tore/black_dotto.png") repeat;
        background-size: 16px;
        .whitebox{
            background: #FFF;
            padding: 40px;
            border-radius: 8px;
            @include flex();
            align-items: center;
            @media screen and (max-width:$tab){
                padding: 20px;
                display: block;
            }
            @media screen and (max-width:$responsive){
                padding: 15px;   
            }
            figure{
                width: 440px;
                @media screen and (max-width:$tab){
                    width: auto;
                    margin: 0 0 24px;
                }
                img{
                    width: 100%;
                }
            }
            dl{
                width: calc(100% - 440px);
                padding: 0 0 0 48px;
                @media screen and (max-width:$tab){
                    width: auto;
                    padding: 0;
                }
                dt{
                    font-size: 30px;
                    font-weight: 700;
                    letter-spacing: 0em;
                    margin: 0 0 16px;
                    padding: 0 0 12px;
                    border-bottom: $border 2px solid;
                    @include flex();
                    justify-content: flex-start;
                    align-items: center;
                    @media screen and (max-width:$tab){
                        font-size: 24px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 17px;   
                    }
                    &:before{
                        content: 'POINT';
                        color: #FFF;
                        background: $green;
                        font-size: 16px;
                        padding: 4px 16px;
                        margin: 0 16px 0 0;
                        border-radius: 3px;
                        display: inline-block;
                        @media screen and (max-width:$tab){
                            padding: 3px 15px;   
                        }
                        @media screen and (max-width:$responsive){
                            font-size: 13px;   
                            padding: 3px 8px;
                            margin: 0 12px 0 0;
                        }
                    }
                }
                dd{
                    font-size: 15px;
                    text-align: justify;
                    margin: 16px 0 0;
                    @media screen and (max-width:$tab){
                        font-size: 14px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 13.5px;   
                    }
                }
            }
        }
        .btnarea{
            margin-top: 48px;
            @media screen and (max-width:$tab){
                margin-top: 32px;   
            }
            a{
                background: $green;
                border: $green 2px solid;
                border-radius: 4px;
                font-size: 18px;
                &:hover{
                    color: $green;
                    background: #FFF;
                }
            }
        }
    }
}