.site-advertise{
    h2.title{
        text-align: center;
        font-size: 28px;
        line-height: 1.6;
        font-weight: 700;
        margin: 0 0 24px;
        @media screen and (max-width:$tab){
            font-size: 22px;   
        }
        @media screen and (max-width:$responsive){
            font-size: 20px;
            text-align: left;
            border-bottom: $red 6px solid;
            line-height: 1.5;
            padding: 0 0 6px;
        }
        strong{
            display: inline-block;
            border-bottom: $red 6px solid;
            @media screen and (max-width:$responsive){
                display: block;
                border: none;
            }
        }
    }
    .localnav{
        background: $glay;
        border-radius: 4px;
        box-shadow: inset 0 2px 4px rgba(#000,.12);
        padding: 24px;
        @media screen and (max-width:$tab){
            padding: 20px;   
        }
        @media screen and (max-width:$responsive){
            padding: 15px;   
        }
        &__list{
            @include flex();
            align-items: center;
            justify-content: center;
            @media screen and (max-width:$responsive){
                display: block;   
            }
            li{
                list-style: none;
                margin: 0 16px 0 0;
                @media screen and (max-width:$responsive){
                    margin: 0 0 8px;   
                }
                &:last-child{
                    margin: 0;
                }
                a{
                    background: $red;
                    border: $red 1px solid;
                    color: #FFF;
                    border-radius: 3px;
                    font-size: 18px;
                    font-weight: 700;
                    padding: 8px 32px;
                    display: block;
                    min-width: 240px;
                    text-align: center;
                    @media screen and (max-width:$responsive){
                        display: block;
                        padding: 8px 15px;
                    }
                    &:hover{
                        background: #FFF;
                        color: $red;
                    }
                }
            }
        }
    }
    .data{
        @include flex();
        align-items: center;
        background: #FFF;
        border: $glay 24px solid;
        position: relative;
        margin: 0 0 56px;
        @media screen and (max-width:$responsive){
            border: $border 8px solid;
            margin: 0 0 32px;
            display: block;
        }
        &__pic{
            width: 640px;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            @media screen and (max-width:$tab){
                width: 320px;   
            }
            @media screen and (max-width:$responsive){
                width: auto;
                pointer-events: none;
            }
            &.sizeM{
                background: $glay;
                text-align: center;
                img{
                    width: 50%;
                    @media screen and (max-width:$tab){
                        width: 100%;   
                    }
                    @media screen and (max-width:$responsive){
                        pointer-events: none;
                    }
                }
            }
            &:hover{
                img{
                    opacity: .88;
                }
                figcaption{
                    background: lighten($red, 8%);
                }
            } 
            img{
                width: 100%;
                @include transition(120ms);
            }
            figcaption{
                position: absolute;
                right: 0;
                top: 0;
                background: $red;
                padding: 8px 16px;
                color: #FFF;
                font-weight: 700;
                font-size: 15px;
                @include transition(120ms);
                @media screen and (max-width:$tab){
                    font-size: 13px;   
                }
                @media screen and (max-width:$responsive){
                    display: none;
                }
                i{
                    display: inline-block;
                    margin: 0 8px 0 0;
                }
            }
        }
        &__dl{
            width: calc(100% - 640px);
            padding: 40px;
            position: relative;
            z-index: 2;
            @media screen and (max-width:$tab){
                width: calc(100% - 320px);
                padding: 32px;
            }
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 15px;
            }
            dt{
                font-size: 32px;
                font-weight: 700;
                line-height: 1.6;
                padding-left: 36px;
                position: relative;
                @media screen and (max-width:$tab){
                    font-size: 24px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 18px;
                    padding-left: 24px;
                }
                &:before {
                    content: '';
                    border: $red 6px solid;
                    position: absolute;
                    top: calc(50% + 2px);
                    left: 0;
                    transform: translateY(-50%);
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    @media screen and (max-width:$responsive){
                        width: 16px;
                        height: 16px;
                        border: $red 4px solid;
                    }
                }
            }
            dd{
                margin: 14px 0 0;
                padding: 14px 0 0;
                border-top: $border 1px solid;
                font-size: 15px;
                text-align: justify;
                @media screen and (max-width:$tab){
                    font-size: 13.5px;   
                }
                &.btnarea{
                    margin: 32px 0 0;
                    padding: 0;
                    border: none;
                }
            }
        }
    }
    .telarea{
        background: $glay;
        padding: 24px;
        margin: 0 0 56px;
        @include flex();
        @media screen and (max-width:$tab){
            padding: 20px;
            margin: 0 0 32px;   
        }
        @media screen and (max-width:$responsive){
            padding: 15px;
            margin: 0 0 24px;
            display: block;
        }
        li{
            width: 23.5%;
            background: #FFF;
            padding: 15px;
            text-align: center;
            box-shadow: 0 0 15px rgba(#000,.02);
			@media screen and (min-width:$tab + 1px){
				margin: 15px 0 0;
				&:nth-child(-n+4){
					margin-top: 0;
				}
			}
            @media screen and (max-width:$tab){
                width: 48.5%;
                margin: 16px 0 0;
                &:nth-child(-n+2){
                    margin-top: 0;
                }
            }
            @media screen and (max-width:$responsive){
                width: 100%;
                margin: 0 0 8px;
                &:last-child{
                    margin: 0;
                }
            }
            dl{
                font-weight: 700;
                dt{
                    font-size: 16px;
                    margin: 0 0 8px;
                }
                dd{
                    a{
                        display: block;
                        background: $red;
                        color: #FFF;
                        font-size: 18px;
                        padding: 2px 15px;
                        border-radius: 4px;
                        box-shadow: 0 4px 0 darken($red, 8%);
                        i{
                            display: inline-block;
                            transform: rotateY(180deg) translateY(-1px);
                            margin: 0 8px 0 0;
                        }
                    }
                }
            }
        }
    }
    .overlay{
        position: fixed;
        top: 0;
        left: 0;
        background: rgba($normal, .88);
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        pointer-events: none;
        overflow: hidden;
        &.active{
            pointer-events: auto;
            opacity: 1;
            z-index: 9000;
            transition: opacity 800ms;
            img{
                max-width: 84%;
                max-height: 84%;
                @include transition(400ms);
            }
        }
        &__close{
            $size: 48px;
            width: $size;
            height: $size;
            @include flex();
            align-items: center;
            justify-content: center;
            background: #FFF;
            border-radius: 50%;
            z-index: 2;
            position: absolute;
            top: 24px;
            right: 24px;
            font-size: 18px;
            cursor: pointer;
            @include transition(120ms);
            &:hover{
                transform: scale(1.2);
            }
        }
        > img{
            max-width: 20%;
            max-height: 20%;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            z-index: 1;
        }
    }
	
	// add 20230427
	.step{
		&__title{
			font-size: 2.88rem;
			font-weight: 700;
			line-height: 1.44;
			text-align: center;
			color: $blue;
			margin: 0 0 32px;
			@media screen and (max-width:$tab){
				font-size: 2rem;
				margin: 0 0 20px;
			}
		}
		
		&__list{
			position: relative;
			margin: 0 0 80px;
			padding: 0 0 60px;
			border-bottom: darken($border, 6%) 1px solid;
			@media screen and (max-width:$responsive){
				margin: 0 0 40px;
				padding: 0 0 24px;
			}
			&.none{
				margin: 0;
				padding: 0;
				border: none;
				&:after{
					display: none;
				}
			}
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 40px 40px 0 40px;
				border-color: darken($border, 6%) transparent transparent transparent;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 100%);
				@media screen and (max-width:$responsive){
					border-width: 24px 24px 0 24px;
				}
			}
			&__mid{
				font-size: 1.88rem;
				font-weight: 700;
				line-height: 1.56;
				text-align: center;
				@media screen and (max-width:$responsive){
					font-size: 1.32rem;
					margin: 0 16px;
				}
				i{
					width: 64px;
					height: 64px;
					margin: 0 auto 24px;
					font-size: 1.99rem;
					display: block;
					background: #FFF;
					color: $blue;
					border-radius: 50%;
					@include flex();
					align-items: center;
					justify-content: center;
					box-shadow: 2px 2px 16px rgba($blue,.08);
					@media screen and (max-width:$responsive){
						width: 56px;
						height: 56px;
						font-size: 1.64rem;
					}
				}
			}
			&__three{
				@include flex();
				margin: 48px 0 0;
				@media screen and (max-width:$responsive){
					margin: 24px 8px 0;
					display: block;
				}
				li{
					width: calc(33.3% - 12px);
					background: #FFF;
					height: auto;
					padding: 24px;
					padding-top: 40px;
					position: relative;
					border-radius: 0 0 3px 3px;
					border-top: $blue 2px solid;
					@media screen and (max-width:$responsive){
						width: auto;
						margin: 0 0 32px;
						padding: 16px;
						padding-top: 24px;
						&:last-child{
							margin-bottom: 0;
						}
					}
					@for $i from 1 through 10{
						&:nth-child(#{$i}){
							&:before{
								content: '#{$i}';
							}
						}
					}
					&:before{
						content: '1';
						position: absolute;
						top: 0;
						left: 50%;
						transform: translate(-50%, -50%);
						background: #FFF;
						border: $blue 2px solid;
						color: $blue;
						font-size: 1.32rem;
						line-height: 1;
						font-weight: 700;
						width: 40px;
						height: 40px;
						@include flex();
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						@media screen and (max-width:$responsive){
							width: 32px;
							height: 32px;
							font-size: 1.12rem;
						}
					}
					dl{
						dt{
							font-size: 1.44rem;
							font-weight: 700;
							color: $blue;
							text-align: center;
							@media screen and (max-width:$responsive){
								font-size: 1.24rem;
							}
						}
						dd{
							margin: 8px 0 0;
							font-size: 1.08rem;
							line-height: 1.66;
							@media screen and (max-width:$responsive){
								font-size: 1rem;
							}
						}
					}
				}
			}
			&__context{
				text-align: center;
				font-size: 1.08rem;
				margin: 12px 0 24px;
				&.l{
					font-size: 1.16rem;
					margin-bottom: 0;
					@media screen and (max-width:$responsive){
						padding: 0 16px;
						font-size: 1rem;
					}
				}
			}
			&__table{
				background: #FFF;
				border-radius: 3px;
				padding: 40px;
				@media screen and (max-width:$responsive){
					padding: 16px;
				}
				dl{
					@include flex();
					align-items: center;
					font-size: 1.12rem;
					margin: 0 0 32px;
					padding: 0 0 32px;
					border-bottom: $border 1px dashed;
					@media screen and (max-width:$responsive){
						display: block;
						font-size: 1rem;
						margin: 0 0 16px;
						padding: 0 0 16px;
					}
					dt{
						width: 200px;
						font-size: 1.24rem;
						font-weight: 700;
						color: $blue;
						@media screen and (max-width:$responsive){
							width: auto;
						}
					}
					dd{
						width: calc(100% - 200px);
						@media screen and (max-width:$responsive){
							width: auto;
						}
					}
				}
				&__under{
					text-align: center;
					font-size: 1.12rem;
					padding: 24px;
					background: $glay;
					@media screen and (max-width:$responsive){
						padding: 16px;
						text-align: left;
						font-size: 1rem;
					}
					h4{
						font-weight: 700;
						font-size: 1.32rem;
					}
				}
			}
			&__test{
				margin: 32px 0 0;
				background: #FFF;
				border-radius: 3px;
				padding: 40px;
				@include flex();
				align-items: center;
				@media screen and (max-width:$responsive){
					padding: 16px;
					margin: 24px 0 0;
				}
				&__pic{
					width: 400px;
					height: calc(400px / 16 * 9);
					@media screen and (max-width:$responsive){
						width: auto;
						height: auto;
					}
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				&__data{
					font-size: 1.12rem;
					width: calc(100% - 400px);
					padding: 0 0 0 56px;
					@media screen and (max-width:$responsive){
						width: auto;
						padding: 24px 0 0;
						font-size: 1rem;
					}
					dt{
						font-weight: 700;
						font-size: 1.64rem;
						line-height: 1.56;
						margin: 0 0 16px;
						padding: 0 0 16px;
						border-bottom: $border 1px solid;
						@media screen and (max-width:$responsive){
							font-size: 1.24rem;
						}
					}
				}
			}
		}
	}
}