.site-recruit{
    h2{
        @include min();
        text-align: center;
        font-size: 32px;
        line-height: 1;
        font-weight: 700;
        margin: 0 0 24px;
        @media screen and (max-width:$tab){
            font-size: 28px;   
        }
        @media screen and (max-width:$responsive){
            font-size: 22px;
            margin: 0 0 16px;
        }
    }
    .context{
        dt{
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            margin: 0 0 20px;
            @media screen and (max-width:$responsive){
                font-size: 17px;
                margin: 0 0 16px;
            }
        }
        dd{
            width: 840px;
            margin: 0 auto 12px;
            font-size: 15px;
            @media screen and (max-width:$tab){
                width: auto;
                padding: 0 20px;
            }
            @media screen and (max-width:$responsive){
                padding: 0;
                font-size: 13.5px;
                text-align: justify;
            }
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
    .listable{
        background: $glay;
        &__list{
            &__item{
                @include flex();
                align-items: center;
                position: relative;
                background: #FFF;
                padding: 20px;
                margin: 0 0 32px;
                box-shadow: 0 0 24px rgba(#000,.04);
                @media screen and (max-width:$tab){
                    align-items: flex-start;   
                }
                @media screen and (max-width:$responsive){
                    display: block;
                    padding: 15px;
                    margin: 0 0 15px;
                }
                &:last-child{
                    margin: 0;
                }
                &--rvs{
                    > figure{
                        order: 2;
                    }
                    > dl{
                        order: 1;
                        padding: 40px;
                        padding-right: 60px;
                        @media screen and (max-width:$tab){
                            padding: 0;
                            padding-right: 32px;
                        }
                        @media screen and (max-width:$responsive){
                            padding-right: 0;   
                        }
                    }
                }
                &__pic{
                    width: 40%;
                    order: 1;
                    @media screen and (max-width:$responsive){
                        width: auto;   
                    }
                    img{
                        width: 100%;
                    }
                }
                &__data{
                    order: 2;
                    width: 60%;
                    padding: 40px;
                    padding-left: 60px;
                    @media screen and (max-width:$tab){
                        padding: 0;
                        padding-left: 32px;
                    }
                    @media screen and (max-width:$responsive){
                        width: auto;
                        padding: 0;
                        margin: 20px 0 0;
                    }
                    dt{
                        font-size: 26px;
                        font-weight: 700;
                        padding: 0 0 0 16px;
                        position: relative;
                        line-height: 1.2;
                        @media screen and (max-width:$tab){
                            font-size: 20px;
                            line-height: 1.5;
                        }
                        @media screen and (max-width:$responsive){
                            font-size: 17px;   
                        }
                        &:before{
                            content: '';
                            width: 4px;
                            height: 88%;
                            border-radius: 4px;
                            position: absolute;
                            top: 6%;
                            left: 0;
                            background: $red;
                        }
                    }
                    dd{
                        margin: 10px 0 0;
                        padding: 18px 0 0;
                        border-top: $normal 2px solid;
                        font-size: 15px;
                        text-align: justify;
                        @media screen and (max-width:$tab){
                            font-size: 14px;
                            padding: 15px 0 0;
                        }
                        @media screen and (max-width:$responsive){
                            font-size: 13.5px;   
                        }
                    }
                }
            }
        }
        .btnarea{
            margin: 48px 0 0;
            @media screen and (max-width:$tab){
                margin: 40px 0 0;
            }
            @media screen and (max-width:$responsive){
                margin: 20px 0 0;   
            }
        }
    }
    .message{
        position: relative;
        &.wrap{
            padding-left: 0;
            padding-right: 0;
            overflow: visible;
        }
        &:before{
            content: '';
            width: 1px;
            height: 96px;
            background: $normal;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateY(-50%);
            @media screen and (max-width:$tab){
                height: 48px;   
            }
            @media screen and (max-width:$responsive){
                display: none;
            }
        }
        &__p{
            width: 840px;
            font-size: 15px;
            margin: 0 auto 24px;
            text-align: justify; 
            @media screen and (max-width:$tab){
                width: auto;
                padding: 0 20px;
            }
            @media screen and (max-width:$responsive){
                font-size: 13.5px;
                padding: 0 15px;
            }
        }
    }
    #recruit-swipe{
        padding: 32px 0 0;
        @media screen and (max-width:$tab){
            padding: 20px 0 0;   
        }
        @media screen and (max-width:$responsive){
            padding: 0 15px;   
        }
        .swiper-slide{
            width: 600px;
            height: 380px;
            position: relative;
            @media screen and (max-width:$tab){
                width: 500px;
                height: 300px;
            }
            @media screen and (max-width:$responsive){
                width: 280px;
                height: 240px;
            }
            .bg{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
            }
        }
    }
}