.site-achievement{
    .box{
        width: 88%;
        margin: 0 auto 64px;
        @media screen and (max-width:$tab){
            width: auto;
            margin: 0 auto 48px;
        }
        @media screen and (max-width:$responsive){
            margin: 0 auto 32px;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    .achievement{
        @include flex();
        @media screen and (max-width:$responsive){
            display: block;   
        }
        &--mini{
            justify-content: flex-start;
            margin: 0 0 0 -24px;
            @media screen and (max-width:$tab){
                margin: 0 0 0 -20px;   
            }
            @media screen and (max-width:$responsive){
                margin: 0;   
            }
            &__minitem{
                width: 25%;
                padding: 0 0 0 24px;
                margin: 24px 0 0;
                @media screen and (max-width:$tab){
                    padding: 0 0 0 20px;
                    margin: 20px 0 0;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 15px;
                    padding: 0;
                    &:last-child{
                        margin: 0;
                    }
                }
                @media screen and (min-width:$responsive + 1px){
                    &:nth-child(-n+4){
                        margin: 0;
                    }
                }
                a{
                    background: darken($glay,1%);
                    border-radius: 5px;
                    text-align: center;
                    display: block;
                    padding: 16px 0;
                    font-size: 16px;
                    font-weight: 700;
                    color: $normal;
                    @media screen and (max-width:$tab){
                        padding: 14px 20px;
                        font-size: 15px;   
                    }
                    &:hover{
                        background: $red;
                        color: #FFF;
                    }
                }
            }
        }
        &__item{
            list-style: none;
            width: calc(50% - 12px);
            margin: 24px 0 0;
            background: darken($glay, 1%);
            position: relative;
            padding: 40px 0 28px;
            border-radius: 5px;
            @media screen and (max-width:$tab){
                width: calc(50% - 10px);
                padding: 20px;
                padding-top: 28px;
            }
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 0 0 15px;
                &:last-child{
                    margin: 0;
                }
            }
            &:nth-child(-n+2){
                margin-top: 0;
            }
            &__badge{
                position: absolute;
                top: 0;
                left: 0;
                background: $red;
                color: #FFF;
                font-weight: 700;
                padding: 2px 20px;
                font-size: 13px;
                border-radius: 5px 0 0 0;
                animation: flash 1200ms infinite alternate;
                @media screen and (max-width:$tab){
                    padding: 0 20px;   
                }
                @media screen and (max-width:$responsive){
                    padding: 0 15px;   
                }
                @keyframes flash{
                    0%{
                        opacity: 0;
                    }
                    70%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 1;
                    }
                }
            }
            dl{
                dt{
                    font-weight: 700;
                    text-align: center;
                    font-size: 26px;
                    line-height: 1;
                    @media screen and (max-width:$tab){
                        font-size: 24px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 22px;   
                    }
                }
                dd{
                    &.btnarea{
                        margin: 20px 0 0;
                        @media screen and (max-width:$responsive){
                            margin: 15px 0 0;   
                        }
                        a{
                            padding: 6px 0;
                            min-width: 220px;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }
}


.site-achievement-single{
    &.site-module{
        h2.center{
            font-size: 32px;
            letter-spacing: 0em;
            margin: 0 0 24px;
            @media screen and (max-width:$responsive){
                font-size: 22px;
                margin: 0 0 16px;
            }
        }
    }
    .box{
        width: 92%;
        margin: 0 auto;
        @media screen and (max-width:$tab){
            width: auto;   
        }
    }
    .mb{
        margin-top: 64px;
        @media screen and (max-width:$tab){
            margin-top: 48px;   
        }
    }
    .context{
        text-align: center;
        line-height: 1.56;
        letter-spacing: 0em;
        font-weight: 700;
        margin: 0 0 32px;
        font-size: 32px;
        color: $normal;
        @media screen and (max-width:$responsive){
            font-size: 22px;
            border-bottom: $red 6px solid;
            margin: 0 0 16px;
        }
        strong{
            color: $red;
            display: inline-block;
            border-bottom: $red 6px solid;
            @media screen and (max-width:$responsive){
                border: none;
            }
        }
    }
    .result{
        border: $normal 6px solid;
        border-bottom: 0;
        border-right: 0;
        @include flex();
        justify-content: flex-start;
        &__badge{
            position: absolute;
            top: -24px;
            left: -24px;
            background: $red;
            padding: 6px 24px;
            color: #FFF;
            font-size: 16px;
            font-weight: 700;
            border-radius: 3px;
            @media screen and (max-width:$tab){
                font-size: 15px;
                padding: 4px 20px;
                top: -16px;
                left: -16px;
            }
            @media screen and (max-width:$responsive){
                font-size: 13.5px;
                padding: 3px 15px;
                top: -14px;
                left: -14px;
            }
            &:after{
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                bottom: -24px;
                left: 50%;
                transform: translateX(-25%) skewX(34deg);
                border-style: solid;
                border-width: 24px 12px 0 12px;
                border-color: darken($red,8%) transparent transparent transparent;
                @media screen and (max-width:$tab){
                    display: none;
                }
            }
        }
        li{
            width: 33.3%;
            padding: 16px;
            border-right: $normal 6px solid;
            border-bottom: $normal 6px solid;
            @include flex();
            align-items: center;
            justify-content: center;
            position: relative;
            @media screen and (max-width:$responsive){
                width: 50%;
                padding: 15px;
            }
            dl{
                font-weight: 700;
                text-align: center;
                dd{
                    line-height: 1;
                    font-size: 48px;
                    color: $red;
                    text-shadow: none;
                    @media screen and (max-width:$tab){
                        font-size: 32px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 24px;   
                    }
                    strong{
                        @include osw();
                        font-size: 120px;
                        display: inline-block;
                        @media screen and (max-width:$tab){
                            font-size: 96px;   
                        }
                        @media screen and (max-width:$responsive){
                            font-size: 60px;   
                        }
                    }
                }
                dt{
                    margin: 8px 0 0;
                    font-size: 32px;
										line-height: 1.56;
                    @media screen and (max-width:$tab){
                        font-size: 20px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 17px;
                        margin: 6px 0 0;
                    }
                }
            }
        }
    }
    .mini{
        @include flex();
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0 0 -20px;
        @media screen and (max-width:$responsive){
            margin: 0 0 0 -15px;   
        }
        li{
            @media screen and (min-width:$tab + 1px){
                width: 25%;
                padding: 0 0 0 20px;
                margin: 20px 0 0;
                &:nth-child(-n+4){
                    margin: 0;
                }
            }
            @media screen and (max-width:$tab){
                width: 33.3%;
                padding: 0 0 0 20px;
                margin: 20px 0 0;
                @media screen and (min-width:$responsive + 1px){
                    &:nth-child(-n+3){
                        margin-top: 0;
                    }
                }
            }
            @media screen and (max-width:$responsive){
                width: 50%;
                padding: 0 0 0 15px;
                margin: 15px 0 0;
                &:nth-child(-n+2){
                    margin: 0;
                }
            }
            dl{
                border: darken($glay,1%) 6px solid;
                text-align: center;
                padding: 22px 16px 24px;
                font-weight: 700;
                line-height: 1;
                position: relative;
                @media screen and (max-width:$responsive){
                    padding: 15px;   
                }
                dt{
                    font-size: 22px;
                    margin: 16px 0 2px;
                    @media screen and (max-width:$tab){
                        font-size: 20px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 17px;   
                    }
                }
                dd{
                    font-size: 28px;
                    color: $red;
                    text-shadow: none;
                    @media screen and (max-width:$responsive){
                        font-size: 24px;   
                    }
                    strong{
                        @include osw();
                        font-size: 64px;
                        @media screen and (max-width:$responsive){
                            font-size: 56px;   
                        }
                    }
                    &.small{
                        color: #151515;
                        font-weight: 500;
                        font-size: 13px;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translateX(-50%) translateY(50%);
                        background: darken($glay,1%);
                        padding: 6px 24px;
                        font-weight: 700;
                        white-space: nowrap;
                        @media screen and (max-width:$responsive){
                            padding: 5px 15px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
