#site-footer{
    background: $normal;
	.box{
		padding: 0 0 64px;
		@media screen and (max-width:$width){
			padding: 0 0 40px;
		}
        .pagetop{
            background: darken($red,4%);
            color: rgba(#FFF,.88);
            text-align: center;
            display: block;
            padding: 16px 0;
            margin: 0 0 64px;
			width: 100%;
			@include transition(200ms);
			@media screen and (max-width:$width){
				margin: 0 0 40px;
			}
			&:hover{
				background: darken($red,10%);
			}
        }
		.flexer{
			@include flex();
			@media screen and (max-width:$width){
				display: block;
				margin: 0 15px;
			}
		}
		.company{
			order: 2;
			width: 50%;
			color: #FFF;
            @include flex();
            align-items: center;
            padding: 0 0 0 40px;
			@media screen and (max-width:$width){
				width: auto;
			}
            @media screen and (max-width:$tab){
                padding: 0;   
            }
            a{
                color: #FFF;
				&:hover{
					text-decoration: underline;
				}
            }
			dl{
                width: 48%;
                margin: 0 0 32px;
                @media screen and (max-width:$responsive){
                    width: 48.5%;   
                }
                &:last-child(-n+2){
                    margin: 0;
                }
				dt{
					font-size: 15px;
					font-weight: 700;
					margin: 0 0 8px;
					padding: 0 0 8px;
					border-bottom: rgba(#FFF,.08) 1px solid;
                    @media screen and (max-width:$responsive){
                        font-size: 14px;
                        border-bottom: rgba(#FFF,.12) 1px solid;
                        &:before{
                            content: '■';
                            color: $red;
                            display: inline-block;
                            margin: 0 4px 0 0;
                        }
                    }
				}
                dd{
                    font-size: 13px;
                    @media screen and (max-width:$responsive){
                        font-size: 12px;
                        line-height: 1.56;
                    }
                }
			}
		}
		nav{
			width: 50%;
			@media screen and (max-width:$width){
				width: auto;
			}
			ul.fnav{
				@include flex();
				@media screen and (max-width:$width){
					display: block;	
				}
				li{
					list-style: none;
					color: #FFF;
					width: 25%;
					@media screen and (max-width:$width){
						width: 100%;
						margin: 0 0 16px;
						border: rgba(#FFF,.16) 1px solid;
						border-radius: 6px;
						&:last-child{
							margin: 0;
						}
					}
					a{
						color: rgba(#FFF,.8);
						&:hover{
							color: #FFF;
							text-decoration: underline;
						}
					}
					dl{
						@media screen and (max-width:$width){
							&.active{
								dt{
									&:after{
										transform: translateY(-50%) rotate(180deg);
									}
								}
								dd{
									display: block;
								}
							}
						}
						dt{
							font-weight: 700;
							margin: 0 0 10px;
							font-size: 16px;
							position: relative;
							@media screen and (max-width:$width){
								font-size: 14px;
								padding: 12px 20px;
								background: rgba(#FFF,.08);
								margin: 0;
                                &:after{
                                    content: "\f078";
                                    font-family: "Font Awesome 5 Free";
                                    font-weight: 900;
                                    font-size: 11px;
                                    position: absolute;
                                    top: 50%;
                                    right: 20px;
                                    transform: translateY(-50%);
                                    opacity: .48;
									@include transition(200ms);
                                }
							}
						}
						dd{
							font-size: 12px;
							margin: 4px 0 0;
							display: block;
                            @media screen and (max-width:$responsive){
                                font-size: 15px;   
                            }
							@media screen and (max-width:$width){
								border-bottom: rgba(#FFF,.08) 1px solid;
								a{
									padding: 12px 20px;
									display: block;
									position: relative;
									&:after{
										content: "\f0a9";
										font-family: "Font Awesome 5 Free";
										font-weight: 900;
										font-size: 11px;
										position: absolute;
										top: 50%;
										right: 20px;
										transform: translateY(-50%);
										opacity: .24;
									}
									&:hover{
										text-decoration: none;
									}
								}
								&:last-of-type{
									border: none;
								}
							}
						}
					}
				}
			}
		}
	}
	.copy{
		text-align: center;
		font-size: 13px;
		color: rgba(#FFF,.8);
		display: block;
		border-top: rgba(#FFF,.12) 1px solid;
		padding: 24px 0;
		&__link{
			margin: 0 0 12px;
			a{
				font-size: .8rem;
				text-decoration: underline;
				color: #aaa;
				&:hover{
					color: #FFF;
				}
			}
		}
  }
}

#pagetop{
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 1000;
	width: 72px;
	height: 72px;
	border-radius: 50%;
	overflow: hidden;
	transform: scale(0);
	transition: transform 0.2s linear;
	@media screen and (max-width:$width){
		width: 40px;
		height: 40px;
		right: 15px;
		bottom: 15px;
	}
	&.active{
		transform: scale(1);
		transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	}
	img{
		width: 100%;
	}
}