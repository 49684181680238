#breadcrumb{
    background: #FFF;
    border-top: $border 1px solid;
    margin: $pc-head 0 0;
    @media screen and (max-width:$tab){
        margin: $sp-head 0 0;
    }
    @media screen and (max-width:$responsive){
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    a{
        color: $normal;
    }
    ol{
        padding: 12px 0;
        font-size: 13px;
        @include flex();
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width:$tab){
            padding: 12px 20px;   
        }
        @media screen and (max-width:$responsive){
            padding: 10px 15px;
            font-size: 12px;
            width: 200%;
        }
        li{
            &:first-child{
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
                        font-size: 11px;
                        transform: translateY(-1px);
                        opacity: .4;
                    }
                }
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
            &:after{
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-size: 8px;
                font-weight: 900;
                opacity: .4;
                display: inline-block;
                margin: 0 16px;
                -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
                @media screen and (max-width:$responsive){
                    margin: 0 12px;   
                }
            }
        }
    }
}

#page-title{
    position: relative;
    background-position: center;
    background-size: cover;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #000;
        opacity: .32;
        @media screen and (max-width:$responsive){
            opacity: .64;   
        }
    }
    .display{
        z-index: 2;
        height: 280px;
        @include flex();
        align-items: center;
        justify-content: center;
        @media screen and (max-width:$tab){
            height: 220px;   
        }
        @media screen and (max-width:$responsive){
            height: 150px;   
        }
    }
    h1{
        color: #FFF;
        font-size: 40px;
        font-weight: 600;
        line-height: 1;
        text-shadow: 0 0 32px rgba(#000,.24);
        text-align: center;
        letter-spacing: .06em;
        transform: translateY(4px);
        @media screen and (max-width:$responsive){
            font-size: 24px;   
        }
        &:after{
            content: attr(data-eng);
            font-size: 16px;
            display: block;
            margin: 16px 0 0;
            text-indent: -3px;
            letter-spacing: .04em;
            text-transform: uppercase;
            @media screen and (max-width:$responsive){
                font-size: 13px;
                margin: 12px 0 0;
            }
        }
    }
}

