@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");
/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

::selection {
  background: #151515;
  color: #FFF; }

:root {
  font-size: 14px; }

* {
  user-select: none; }

img {
  pointer-events: none; }

a img {
  pointer-events: auto; }

body.windows {
  text-shadow: 0 0 rgba(0, 0, 0, 0.56); }

#app {
  opacity: 0; }
  #app.active {
    opacity: 1; }

@media screen and (min-width: 1120px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #151515;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    min-width: 1120px;
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 1119px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #151515;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    min-width: auto;
    max-width: auto160px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #151515;
    background: #FFF;
    font-size: 13px;
    line-height: 1.8;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    min-width: auto;
    max-width: auto160px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 76px;
  z-index: 2000;
  padding: 0 40px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #FFF;
  -webkit-transition: all 400ms 0ms ease-in-out;
  -moz-transition: all 400ms 0ms ease-in-out;
  -ms-transition: all 400ms 0ms ease-in-out;
  -o-transition: all 400ms 0ms ease-in-out;
  transition: all 400ms 0ms ease-in-out;
  z-index: 2000;
  /*
    .menu-trigger{
        cursor: pointer;
        &__item{
            $side: 30px;
            width: $side;
            height: $side;
            margin: 0 auto;
            position: relative;
            &__border,
            &:before,
            &:after{
                content: '';
                width: 26px;
                height: 3px;
                background: $blue;
                position: absolute;
                border-radius: 999px;
                top: 7px;
                left: 50%;
                transform: translateX(-50%);
            }
            &:after{
                top: auto;
                bottom: 7px;
            }
            &__border{
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }
        &__text{
            @include osw();
            white-space: nowrap;
            display: block;
            font-size: 12px;
            line-height: 1;
            text-align: center;
            margin: 2px 0 0;
            letter-spacing: .08em;
        }
    }
    */ }
  @media screen and (max-width: 1119px) {
    #site-header {
      height: 68px;
      color: #151515;
      padding: 0 15px;
      position: absolute; }
      #site-header a {
        color: #151515; } }
  @media screen and (min-width: 1120px) {
    #site-header.active, #site-header.second, #site-header:hover {
      background: #FFF;
      color: #151515; }
      #site-header.active .logo:after, #site-header.second .logo:after, #site-header:hover .logo:after {
        opacity: 1; }
      #site-header.active .logo img, #site-header.second .logo img, #site-header:hover .logo img {
        opacity: 0; }
      #site-header.active .gnavi > ul li > a, #site-header.second .gnavi > ul li > a, #site-header:hover .gnavi > ul li > a {
        color: #151515; }
      #site-header.active .subnavi, #site-header.second .subnavi, #site-header:hover .subnavi {
        background: #eee; }
        #site-header.active .subnavi ul li a, #site-header.second .subnavi ul li a, #site-header:hover .subnavi ul li a {
          color: #151515; }
          #site-header.active .subnavi ul li a i, #site-header.second .subnavi ul li a i, #site-header:hover .subnavi ul li a i {
            color: #db1501; }
          #site-header.active .subnavi ul li a.contact, #site-header.second .subnavi ul li a.contact, #site-header:hover .subnavi ul li a.contact {
            color: #FFF; }
            #site-header.active .subnavi ul li a.contact i, #site-header.second .subnavi ul li a.contact i, #site-header:hover .subnavi ul li a.contact i {
              color: #FFF; }
        #site-header.active .subnavi ul li:first-child:after, #site-header.second .subnavi ul li:first-child:after, #site-header:hover .subnavi ul li:first-child:after {
          border-color: #151515;
          opacity: .32; } }
  @media screen and (max-width: 1119px) {
    #site-header.second {
      background: #FFF;
      color: #151515; }
      #site-header.second .logo:after {
        opacity: 1 !important; }
      #site-header.second .logo img {
        opacity: 0 !important; } }
  #site-header .logo {
    margin: 2px 0 0;
    width: 90px;
    position: relative; }
    #site-header .logo:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../../images/common/logo_2x.png") no-repeat;
      background-size: cover;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: all 400ms 0ms ease-in-out;
      -moz-transition: all 400ms 0ms ease-in-out;
      -ms-transition: all 400ms 0ms ease-in-out;
      -o-transition: all 400ms 0ms ease-in-out;
      transition: all 400ms 0ms ease-in-out; }
      @media screen and (max-width: 1119px) {
        #site-header .logo:after {
          opacity: 0 !important; } }
    #site-header .logo img {
      width: 100%;
      opacity: 1;
      -webkit-transition: all 400ms 0ms ease-in-out;
      -moz-transition: all 400ms 0ms ease-in-out;
      -ms-transition: all 400ms 0ms ease-in-out;
      -o-transition: all 400ms 0ms ease-in-out;
      transition: all 400ms 0ms ease-in-out; }
      @media screen and (max-width: 1119px) {
        #site-header .logo img {
          opacity: 1 !important; } }
  #site-header .trigger,
  #site-header .close {
    display: none; }
    @media screen and (max-width: 1119px) {
      #site-header .trigger,
      #site-header .close {
        display: block;
        width: 44px;
        height: 44px;
        background: #151515;
        border-radius: 50%;
        position: fixed;
        top: 13px;
        right: 15px;
        cursor: pointer;
        z-index: 2000;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
        -webkit-transition: all 200ms 0ms ease-in-out;
        -moz-transition: all 200ms 0ms ease-in-out;
        -ms-transition: all 200ms 0ms ease-in-out;
        -o-transition: all 200ms 0ms ease-in-out;
        transition: all 200ms 0ms ease-in-out; }
        #site-header .trigger:before, #site-header .trigger:after,
        #site-header .close:before,
        #site-header .close:after {
          content: '';
          width: 16px;
          height: 1px;
          background: #FFF;
          position: absolute;
          left: 50%;
          top: 42%;
          transform: translateX(-50%); }
        #site-header .trigger:after,
        #site-header .close:after {
          top: auto;
          bottom: 40%; } }
  @media screen and (max-width: 1119px) {
    #site-header .close {
      background: #666;
      z-index: 9999;
      opacity: 0; }
      #site-header .close.active {
        animation: role 400ms forwards; }
    @keyframes role {
      0% {
        opacity: 0;
        transform: scale(0.8) rotate(90deg); }
      100% {
        opacity: 1;
        transform: scale(1) rotate(0deg); } }
      #site-header .close:before, #site-header .close:after {
        background: #FFF;
        bottom: auto;
        top: 50%;
        left: 31%;
        transform: rotate(45deg); }
      #site-header .close:after {
        transform: rotate(-45deg); } }
  @media screen and (min-width: 1120px) {
    #site-header .navigation {
      display: block !important; } }
  @media screen and (min-width: 1120px) {
    #site-header .gnavi {
      margin: -1px 0 0 72px; }
      #site-header .gnavi > ul {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: flex-start; }
        #site-header .gnavi > ul > li {
          list-style: none;
          font-weight: 700;
          margin: 0 40px 0 0; }
          #site-header .gnavi > ul > li.none {
            display: none; } }
      @media screen and (min-width: 1120px) and (max-width: 1350px) {
        #site-header .gnavi > ul > li:first-child {
          display: none; } }
  @media screen and (min-width: 1120px) {
          #site-header .gnavi > ul > li i {
            display: inline-block;
            margin: 0 0 0 8px;
            font-size: .8rem;
            transform: translateY(-1px); }
          #site-header .gnavi > ul > li > a {
            color: #FFF;
            -webkit-transition: all 400ms 0ms ease-in-out;
            -moz-transition: all 400ms 0ms ease-in-out;
            -ms-transition: all 400ms 0ms ease-in-out;
            -o-transition: all 400ms 0ms ease-in-out;
            transition: all 400ms 0ms ease-in-out;
            position: relative; }
            #site-header .gnavi > ul > li > a:after {
              content: '';
              width: 0;
              height: 3px;
              background: #db1501;
              position: absolute;
              left: 50%;
              bottom: -6px;
              transform: translateX(-50%);
              -webkit-transition: all 120ms 0ms ease-in-out;
              -moz-transition: all 120ms 0ms ease-in-out;
              -ms-transition: all 120ms 0ms ease-in-out;
              -o-transition: all 120ms 0ms ease-in-out;
              transition: all 120ms 0ms ease-in-out; }
            #site-header .gnavi > ul > li > a:hover:after {
              width: 64px; }
          #site-header .gnavi > ul > li:last-child {
            margin: 0; }
          #site-header .gnavi > ul > li dl:hover dt i {
            color: #db1501;
            transform: rotate(-180deg); }
          #site-header .gnavi > ul > li dl:hover dd {
            display: block !important;
            animation: fade 400ms forwards; }
    @keyframes fade {
      0% {
        opacity: 0;
        transform: translateY(-10px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul > li dl dt {
            cursor: pointer;
            position: relative; }
            #site-header .gnavi > ul > li dl dt i {
              display: inline-block;
              -webkit-transition: all 120ms 0ms ease-in-out;
              -moz-transition: all 120ms 0ms ease-in-out;
              -ms-transition: all 120ms 0ms ease-in-out;
              -o-transition: all 120ms 0ms ease-in-out;
              transition: all 120ms 0ms ease-in-out; }
            #site-header .gnavi > ul > li dl dt:before {
              content: '';
              width: 100%;
              height: 76px;
              position: absolute;
              top: -24px;
              left: 0; }
          #site-header .gnavi > ul > li dl dd {
            width: 100%;
            position: absolute;
            top: 76px;
            left: 0;
            background: #f4f4f4;
            opacity: 0;
            display: none; }
            #site-header .gnavi > ul > li dl dd ul {
              padding: 32px 0;
              display: -webkit-flex;
              display: -moz-flex;
              display: -o-flex;
              display: -ms-flex;
              display: flex;
              -moz-flex-flow: row wrap;
              -webkit-flex-flow: row wrap;
              -o-flex-flow: row wrap;
              -ms-flex-flow: row wrap;
              flex-flow: row wrap;
              -webkit-justify-content: space-between;
              -o-justify-content: space-between;
              -ms-justify-content: space-between;
              -moz-justify-content: space-between;
              justify-content: space-between;
              align-items: center;
              justify-content: flex-start; }
              #site-header .gnavi > ul > li dl dd ul li {
                width: 20%;
                text-align: center;
                border-right: rgba(0, 0, 0, 0.12) 1px solid; }
                #site-header .gnavi > ul > li dl dd ul li:last-child {
                  border: none; }
                #site-header .gnavi > ul > li dl dd ul li a {
                  font-size: 14px;
                  display: block; }
                  #site-header .gnavi > ul > li dl dd ul li a:hover {
                    color: #db1501; } }
  @media screen and (max-width: 1119px) {
    #site-header .gnavi {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background: #151515;
      z-index: 9000;
      color: #FFF;
      font-size: 15px; }
      #site-header .gnavi a {
        color: #FFF; }
      #site-header .gnavi > ul {
        position: relative;
        z-index: 2;
        padding: 80px 15px 15px; }
        #site-header .gnavi > ul > li {
          border-bottom: rgba(255, 255, 255, 0.12) 1px solid; }
          #site-header .gnavi > ul > li:last-child {
            border: none; }
          #site-header .gnavi > ul > li a {
            display: block;
            padding: 15px 0;
            position: relative; }
            #site-header .gnavi > ul > li a:after {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f054";
              position: absolute;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
              font-size: 12px;
              opacity: .48; }
          #site-header .gnavi > ul > li.other {
            display: -webkit-flex;
            display: -moz-flex;
            display: -o-flex;
            display: -ms-flex;
            display: flex;
            -moz-flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            -o-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-justify-content: space-between;
            -o-justify-content: space-between;
            -ms-justify-content: space-between;
            -moz-justify-content: space-between;
            justify-content: space-between;
            padding: 15px 0; }
            #site-header .gnavi > ul > li.other a {
              width: calc(50% - 7.5px);
              padding: 10px 15px;
              display: block;
              background: rgba(255, 255, 255, 0.1);
              border-radius: 4px; }
              #site-header .gnavi > ul > li.other a:hover {
                background: rgba(255, 255, 255, 0.08); }
              #site-header .gnavi > ul > li.other a i {
                font-size: 12px;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                opacity: .48; }
              #site-header .gnavi > ul > li.other a:after {
                display: none; }
        #site-header .gnavi > ul dl dt {
          padding: 15px 0;
          position: relative;
          cursor: pointer; }
          #site-header .gnavi > ul dl dt.active i {
            transform: rotate(180deg) translateY(50%);
            opacity: 1; }
          #site-header .gnavi > ul dl dt > i {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            font-size: 11px;
            opacity: .48;
            -webkit-transition: all 200ms 0ms ease-in-out;
            -moz-transition: all 200ms 0ms ease-in-out;
            -ms-transition: all 200ms 0ms ease-in-out;
            -o-transition: all 200ms 0ms ease-in-out;
            transition: all 200ms 0ms ease-in-out; }
        #site-header .gnavi > ul dl dd {
          background: rgba(255, 255, 255, 0.08);
          border-radius: 4px;
          margin: 0 0 15px; }
          #site-header .gnavi > ul dl dd a {
            padding: 15px; }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(1) {
            animation: shows 300ms 80ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(2) {
            animation: shows 300ms 160ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(3) {
            animation: shows 300ms 240ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(4) {
            animation: shows 300ms 320ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(5) {
            animation: shows 300ms 400ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(6) {
            animation: shows 300ms 480ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(7) {
            animation: shows 300ms 560ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(8) {
            animation: shows 300ms 640ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(9) {
            animation: shows 300ms 720ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul.active li:nth-child(10) {
            animation: shows 300ms 800ms forwards; }
    @keyframes shows {
      0% {
        opacity: 0;
        transform: translateY(20px); }
      100% {
        opacity: 1;
        transform: translateY(0); } }
          #site-header .gnavi > ul dl dd ul li {
            border-bottom: rgba(255, 255, 255, 0.12) 1px solid;
            opacity: 0; }
            #site-header .gnavi > ul dl dd ul li:last-child {
              border: none; } }
  #site-header .subnavi {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0 0 40px;
    -webkit-transition: all 400ms 0ms ease-in-out;
    -moz-transition: all 400ms 0ms ease-in-out;
    -ms-transition: all 400ms 0ms ease-in-out;
    -o-transition: all 400ms 0ms ease-in-out;
    transition: all 400ms 0ms ease-in-out; }
    @media screen and (max-width: 1119px) {
      #site-header .subnavi {
        display: none; } }
    #site-header .subnavi ul {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center; }
      #site-header .subnavi ul li:first-child {
        margin: 0 28px 0 0;
        padding: 0 28px 0 0;
        position: relative; }
        #site-header .subnavi ul li:first-child:after {
          content: '';
          width: 2px;
          height: 70%;
          border-left: #FFF 2px dotted;
          transform: rotate(20deg) translateX(100%);
          position: absolute;
          top: 15%;
          right: 0; }
      #site-header .subnavi ul li:last-child {
        margin: 0 0 0 40px; }
      #site-header .subnavi ul li a {
        font-weight: 700;
        color: #FFF;
        -webkit-transition: all 400ms 0ms ease-in-out;
        -moz-transition: all 400ms 0ms ease-in-out;
        -ms-transition: all 400ms 0ms ease-in-out;
        -o-transition: all 400ms 0ms ease-in-out;
        transition: all 400ms 0ms ease-in-out; }
        #site-header .subnavi ul li a:hover {
          text-decoration: underline;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
        #site-header .subnavi ul li a.contact {
          position: relative;
          padding: 12px 24px;
          display: block;
          height: 76px;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          -webkit-transition: all 400ms 0ms ease-in-out;
          -moz-transition: all 400ms 0ms ease-in-out;
          -ms-transition: all 400ms 0ms ease-in-out;
          -o-transition: all 400ms 0ms ease-in-out;
          transition: all 400ms 0ms ease-in-out; }
          #site-header .subnavi ul li a.contact:before, #site-header .subnavi ul li a.contact:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #db1501;
            z-index: 1; }
          #site-header .subnavi ul li a.contact:after {
            width: 0;
            background: #151515;
            -webkit-transition: all 200ms 0ms ease-in-out;
            -moz-transition: all 200ms 0ms ease-in-out;
            -ms-transition: all 200ms 0ms ease-in-out;
            -o-transition: all 200ms 0ms ease-in-out;
            transition: all 200ms 0ms ease-in-out; }
          #site-header .subnavi ul li a.contact:hover {
            text-decoration: none;
            opacity: 1; }
            #site-header .subnavi ul li a.contact:hover:after {
              width: 100%; }
          #site-header .subnavi ul li a.contact i, #site-header .subnavi ul li a.contact span {
            position: relative;
            z-index: 2; }
          #site-header .subnavi ul li a.contact i {
            width: 100%;
            margin: 0;
            font-size: 20px;
            display: block;
            text-align: center; }
          #site-header .subnavi ul li a.contact span {
            display: block;
            font-size: 13px;
            text-align: center;
            letter-spacing: .12em; }
        #site-header .subnavi ul li a i {
          display: inline-block;
          margin: 0 0 0 8px;
          font-size: .6rem;
          transform: translateY(-1px);
          -webkit-transition: all 400ms 0ms ease-in-out;
          -moz-transition: all 400ms 0ms ease-in-out;
          -ms-transition: all 400ms 0ms ease-in-out;
          -o-transition: all 400ms 0ms ease-in-out;
          transition: all 400ms 0ms ease-in-out; }

#site-footer {
  background: #151515; }
  #site-footer .box {
    padding: 0 0 64px; }
    @media screen and (max-width: 1120px) {
      #site-footer .box {
        padding: 0 0 40px; } }
    #site-footer .box .pagetop {
      background: #c71301;
      color: rgba(255, 255, 255, 0.88);
      text-align: center;
      display: block;
      padding: 16px 0;
      margin: 0 0 64px;
      width: 100%;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
      @media screen and (max-width: 1120px) {
        #site-footer .box .pagetop {
          margin: 0 0 40px; } }
      #site-footer .box .pagetop:hover {
        background: #a81001; }
    #site-footer .box .flexer {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      @media screen and (max-width: 1120px) {
        #site-footer .box .flexer {
          display: block;
          margin: 0 15px; } }
    #site-footer .box .company {
      order: 2;
      width: 50%;
      color: #FFF;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 40px; }
      @media screen and (max-width: 1120px) {
        #site-footer .box .company {
          width: auto; } }
      @media screen and (max-width: 1119px) {
        #site-footer .box .company {
          padding: 0; } }
      #site-footer .box .company a {
        color: #FFF; }
        #site-footer .box .company a:hover {
          text-decoration: underline; }
      #site-footer .box .company dl {
        width: 48%;
        margin: 0 0 32px; }
        @media screen and (max-width: 768px) {
          #site-footer .box .company dl {
            width: 48.5%; } }
        #site-footer .box .company dl:last-child(-n+2) {
          margin: 0; }
        #site-footer .box .company dl dt {
          font-size: 15px;
          font-weight: 700;
          margin: 0 0 8px;
          padding: 0 0 8px;
          border-bottom: rgba(255, 255, 255, 0.08) 1px solid; }
          @media screen and (max-width: 768px) {
            #site-footer .box .company dl dt {
              font-size: 14px;
              border-bottom: rgba(255, 255, 255, 0.12) 1px solid; }
              #site-footer .box .company dl dt:before {
                content: '■';
                color: #db1501;
                display: inline-block;
                margin: 0 4px 0 0; } }
        #site-footer .box .company dl dd {
          font-size: 13px; }
          @media screen and (max-width: 768px) {
            #site-footer .box .company dl dd {
              font-size: 12px;
              line-height: 1.56; } }
    #site-footer .box nav {
      width: 50%; }
      @media screen and (max-width: 1120px) {
        #site-footer .box nav {
          width: auto; } }
      #site-footer .box nav ul.fnav {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between; }
        @media screen and (max-width: 1120px) {
          #site-footer .box nav ul.fnav {
            display: block; } }
        #site-footer .box nav ul.fnav li {
          list-style: none;
          color: #FFF;
          width: 25%; }
          @media screen and (max-width: 1120px) {
            #site-footer .box nav ul.fnav li {
              width: 100%;
              margin: 0 0 16px;
              border: rgba(255, 255, 255, 0.16) 1px solid;
              border-radius: 6px; }
              #site-footer .box nav ul.fnav li:last-child {
                margin: 0; } }
          #site-footer .box nav ul.fnav li a {
            color: rgba(255, 255, 255, 0.8); }
            #site-footer .box nav ul.fnav li a:hover {
              color: #FFF;
              text-decoration: underline; }
          @media screen and (max-width: 1120px) {
            #site-footer .box nav ul.fnav li dl.active dt:after {
              transform: translateY(-50%) rotate(180deg); }
            #site-footer .box nav ul.fnav li dl.active dd {
              display: block; } }
          #site-footer .box nav ul.fnav li dl dt {
            font-weight: 700;
            margin: 0 0 10px;
            font-size: 16px;
            position: relative; }
            @media screen and (max-width: 1120px) {
              #site-footer .box nav ul.fnav li dl dt {
                font-size: 14px;
                padding: 12px 20px;
                background: rgba(255, 255, 255, 0.08);
                margin: 0; }
                #site-footer .box nav ul.fnav li dl dt:after {
                  content: "\f078";
                  font-family: "Font Awesome 5 Free";
                  font-weight: 900;
                  font-size: 11px;
                  position: absolute;
                  top: 50%;
                  right: 20px;
                  transform: translateY(-50%);
                  opacity: .48;
                  -webkit-transition: all 200ms 0ms ease-in-out;
                  -moz-transition: all 200ms 0ms ease-in-out;
                  -ms-transition: all 200ms 0ms ease-in-out;
                  -o-transition: all 200ms 0ms ease-in-out;
                  transition: all 200ms 0ms ease-in-out; } }
          #site-footer .box nav ul.fnav li dl dd {
            font-size: 12px;
            margin: 4px 0 0;
            display: block; }
            @media screen and (max-width: 768px) {
              #site-footer .box nav ul.fnav li dl dd {
                font-size: 15px; } }
            @media screen and (max-width: 1120px) {
              #site-footer .box nav ul.fnav li dl dd {
                border-bottom: rgba(255, 255, 255, 0.08) 1px solid; }
                #site-footer .box nav ul.fnav li dl dd a {
                  padding: 12px 20px;
                  display: block;
                  position: relative; }
                  #site-footer .box nav ul.fnav li dl dd a:after {
                    content: "\f0a9";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    font-size: 11px;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                    opacity: .24; }
                  #site-footer .box nav ul.fnav li dl dd a:hover {
                    text-decoration: none; }
                #site-footer .box nav ul.fnav li dl dd:last-of-type {
                  border: none; } }
  #site-footer .copy {
    text-align: center;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    border-top: rgba(255, 255, 255, 0.12) 1px solid;
    padding: 24px 0; }
    #site-footer .copy__link {
      margin: 0 0 12px; }
      #site-footer .copy__link a {
        font-size: .8rem;
        text-decoration: underline;
        color: #aaa; }
        #site-footer .copy__link a:hover {
          color: #FFF; }

#pagetop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  overflow: hidden;
  transform: scale(0);
  transition: transform 0.2s linear; }
  @media screen and (max-width: 1120px) {
    #pagetop {
      width: 40px;
      height: 40px;
      right: 15px;
      bottom: 15px; } }
  #pagetop.active {
    transform: scale(1);
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  #pagetop img {
    width: 100%; }

#breadcrumb {
  background: #FFF;
  border-top: #e8e8e8 1px solid;
  margin: 76px 0 0; }
  @media screen and (max-width: 1119px) {
    #breadcrumb {
      margin: 68px 0 0; } }
  @media screen and (max-width: 768px) {
    #breadcrumb {
      width: 100%;
      overflow: hidden;
      position: relative; } }
  #breadcrumb a {
    color: #151515; }
  #breadcrumb ol {
    padding: 12px 0;
    font-size: 13px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start; }
    @media screen and (max-width: 1119px) {
      #breadcrumb ol {
        padding: 12px 20px; } }
    @media screen and (max-width: 768px) {
      #breadcrumb ol {
        padding: 10px 15px;
        font-size: 12px;
        width: 200%; } }
    #breadcrumb ol li:first-child a:hover {
      text-decoration: underline; }
    #breadcrumb ol li:first-child a i {
      display: inline-block;
      margin: 0 8px 0 0;
      font-size: 11px;
      transform: translateY(-1px);
      opacity: .4; }
    #breadcrumb ol li:last-child:after {
      display: none; }
    #breadcrumb ol li:after {
      content: "\f054";
      font-family: "Font Awesome 5 Free";
      font-size: 8px;
      font-weight: 900;
      opacity: .4;
      display: inline-block;
      margin: 0 16px;
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px); }
      @media screen and (max-width: 768px) {
        #breadcrumb ol li:after {
          margin: 0 12px; } }

#page-title {
  position: relative;
  background-position: center;
  background-size: cover; }
  #page-title:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: .32; }
    @media screen and (max-width: 768px) {
      #page-title:before {
        opacity: .64; } }
  #page-title .display {
    z-index: 2;
    height: 280px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 1119px) {
      #page-title .display {
        height: 220px; } }
    @media screen and (max-width: 768px) {
      #page-title .display {
        height: 150px; } }
  #page-title h1 {
    color: #FFF;
    font-size: 40px;
    font-weight: 600;
    line-height: 1;
    text-shadow: 0 0 32px rgba(0, 0, 0, 0.24);
    text-align: center;
    letter-spacing: .06em;
    transform: translateY(4px); }
    @media screen and (max-width: 768px) {
      #page-title h1 {
        font-size: 24px; } }
    #page-title h1:after {
      content: attr(data-eng);
      font-size: 16px;
      display: block;
      margin: 16px 0 0;
      text-indent: -3px;
      letter-spacing: .04em;
      text-transform: uppercase; }
      @media screen and (max-width: 768px) {
        #page-title h1:after {
          font-size: 13px;
          margin: 12px 0 0; } }

.site-index .firstsec.wrap {
  padding-top: 64px;
  padding-bottom: 64px; }
  @media screen and (max-width: 1119px) {
    .site-index .firstsec.wrap {
      padding-top: 20px;
      padding-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    .site-index .firstsec.wrap {
      padding-top: 15px;
      padding-bottom: 15px; } }

.site-index .firstsec__bnr img {
  width: 100%;
  pointer-events: auto; }

.site-index .firstsec #bnr-swipe .swiper-wrapper {
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1); }

.site-index .firstsec #bnr-swipe .swiper-button-next,
.site-index .firstsec #bnr-swipe .swiper-button-prev {
  width: 56px;
  height: 56px;
  background: #FFF;
  border-radius: 50%;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin-top: 0;
  transform: translateY(-50%) scale(0.64);
  opacity: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #db1501;
  -webkit-transition: all 200ms 0ms ease-in-out;
  -moz-transition: all 200ms 0ms ease-in-out;
  -ms-transition: all 200ms 0ms ease-in-out;
  -o-transition: all 200ms 0ms ease-in-out;
  transition: all 200ms 0ms ease-in-out; }
  @media screen and (max-width: 768px) {
    .site-index .firstsec #bnr-swipe .swiper-button-next,
    .site-index .firstsec #bnr-swipe .swiper-button-prev {
      width: 40px;
      height: 40px; } }

.site-index .firstsec #bnr-swipe .swiper-button-next {
  right: 20px; }
  @media screen and (max-width: 768px) {
    .site-index .firstsec #bnr-swipe .swiper-button-next {
      right: 15px; } }
  .site-index .firstsec #bnr-swipe .swiper-button-next i {
    transform: translateX(2px); }

.site-index .firstsec #bnr-swipe .swiper-button-prev {
  left: 20px; }
  @media screen and (max-width: 768px) {
    .site-index .firstsec #bnr-swipe .swiper-button-prev {
      left: 15px; } }
  .site-index .firstsec #bnr-swipe .swiper-button-prev i {
    transform: translateX(-2px); }

.site-index .firstsec #bnr-swipe:hover .swiper-button-next,
.site-index .firstsec #bnr-swipe:hover .swiper-button-prev {
  transform: translateY(-50%) scale(1);
  opacity: 1; }

.site-index .resulted.wrap {
  background: #f4f4f4; }
  @media screen and (max-width: 768px) {
    .site-index .resulted.wrap {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0; } }

.site-index .resulted .score {
  font-weight: 700;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  justify-content: center;
  align-items: flex-end;
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-index .resulted .score {
      display: block;
      margin: 0 0 15px; } }
  .site-index .resulted .score h2 {
    position: absolute;
    right: 0;
    top: 12px;
    line-height: 1;
    font-weight: 700;
    z-index: 3;
    color: #db1501;
    font-size: 60px;
    border-bottom: #db1501 8px solid;
    padding: 0 0 12px;
    text-shadow: none; }
    @media screen and (max-width: 1350px) {
      .site-index .resulted .score h2 {
        font-size: 44px;
        padding: 0 0 8px; } }
    @media screen and (max-width: 1150px) {
      .site-index .resulted .score h2 {
        font-size: 36px; } }
    @media screen and (max-width: 1119px) {
      .site-index .resulted .score h2 {
        top: 0; } }
    @media screen and (max-width: 768px) {
      .site-index .resulted .score h2 {
        font-size: 28px;
        border-bottom-width: 5px;
        top: 18px;
        right: 15px;
        padding: 0 0 4px; } }
  .site-index .resulted .score__badge {
    position: absolute;
    top: 0;
    left: 0;
    width: 260px;
    z-index: 10; }
    @media screen and (max-width: 1119px) {
      .site-index .resulted .score__badge {
        width: 150px; } }
    @media screen and (max-width: 768px) {
      .site-index .resulted .score__badge {
        top: auto;
        bottom: 64px;
        left: 8px;
        width: 100px; } }
    .site-index .resulted .score__badge img {
      width: 100%; }
  .site-index .resulted .score__catch {
    width: calc(100% - 400px);
    transform: translateX(2%);
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 1119px) {
      .site-index .resulted .score__catch {
        width: 64%;
        transform: translateX(0); } }
    @media screen and (max-width: 768px) {
      .site-index .resulted .score__catch {
        position: absolute;
        top: 0;
        left: 0;
        height: 240px;
        width: 100%;
        object-fit: cover;
        overflow: hidden;
        background: #FFF; }
        .site-index .resulted .score__catch:before {
          content: '';
          background: #FFF;
          position: absolute;
          top: 0;
          right: -15%;
          width: 60%;
          height: 100%;
          z-index: 2;
          transform: skew(-20deg); } }
    .site-index .resulted .score__catch img {
      width: 100%; }
      @media screen and (max-width: 768px) {
        .site-index .resulted .score__catch img {
          transform: translateX(-20%); } }
      @media screen and (max-width: 360px) {
        .site-index .resulted .score__catch img {
          width: 110%; } }
      @media screen and (max-width: 325px) {
        .site-index .resulted .score__catch img {
          width: 120%; } }
    .site-index .resulted .score__catch:after {
      content: '';
      width: 102%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -1%;
      z-index: 2;
      box-shadow: inset 0 0 40px 48px #f4f4f4; }
      @media screen and (max-width: 768px) {
        .site-index .resulted .score__catch:after {
          box-shadow: none; } }
  .site-index .resulted .score__number {
    width: 400px;
    text-align: right;
    margin: 0 0 40px;
    position: relative;
    z-index: 3; }
    @media screen and (max-width: 1119px) {
      .site-index .resulted .score__number {
        width: 36%;
        margin: 0 0 20px; } }
    @media screen and (max-width: 768px) {
      .site-index .resulted .score__number {
        width: auto;
        padding: 56px 0 0;
        margin: 0; } }
    .site-index .resulted .score__number dd {
      line-height: 1;
      font-size: 100px;
      white-space: nowrap;
      color: #db1501;
      text-shadow: none; }
      @media screen and (max-width: 768px) {
        .site-index .resulted .score__number dd {
          font-size: 52px;
          transform: translateX(4px);
          margin: 0 15px; } }
      .site-index .resulted .score__number dd strong {
        display: inline-block;
        font-family: 'Oswald', sans-serif;
        font-size: 280px;
        transform: translateY(4%); }
        @media screen and (max-width: 1350px) {
          .site-index .resulted .score__number dd strong {
            font-size: 240px; } }
        @media screen and (max-width: 1250px) {
          .site-index .resulted .score__number dd strong {
            font-size: 200px; } }
        @media screen and (max-width: 1150px) {
          .site-index .resulted .score__number dd strong {
            font-size: 148px; } }
        @media screen and (max-width: 1119px) {
          .site-index .resulted .score__number dd strong {
            font-size: calc(90px + 180 * (100vw - 450px)/ 900); } }
        @media screen and (max-width: 768px) {
          .site-index .resulted .score__number dd strong {
            font-size: 140px; } }
    .site-index .resulted .score__number dt {
      width: calc(100% + 100px);
      transform: translateX(-100px);
      margin: 28px 0 0;
      background: #db1501;
      color: #FFF;
      padding: 2px 0;
      text-align: center;
      font-size: 36px;
      position: relative; }
      @media screen and (max-width: 1119px) {
        .site-index .resulted .score__number dt {
          font-size: 24px;
          margin: 12px 0 0; } }
      @media screen and (max-width: 768px) {
        .site-index .resulted .score__number dt {
          width: 100%;
          transform: translateX(0);
          text-align: center;
          padding: 2px 15px;
          margin: 16px 0 0;
          font-size: 26px; } }
      .site-index .resulted .score__number dt:after {
        content: '';
        width: 60px;
        height: 100%;
        background: #db1501;
        transform: skewX(-30deg);
        position: absolute;
        top: 0;
        left: -24px; }
        @media screen and (max-width: 768px) {
          .site-index .resulted .score__number dt:after {
            display: none; } }

.site-index .resulted .other {
  border: #151515 6px solid;
  background: #FFF;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-index .resulted .other {
      margin: 0 15px; } }
  .site-index .resulted .other li {
    width: 33.3%;
    padding: 16px;
    border-right: #151515 6px solid;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .site-index .resulted .other li {
        width: 50%; }
        .site-index .resulted .other li:nth-child(2) {
          border-right: none; } }
    .site-index .resulted .other li:last-child {
      border: none; }
      @media screen and (max-width: 768px) {
        .site-index .resulted .other li:last-child {
          width: 100%;
          border-top: #151515 6px solid; } }
    .site-index .resulted .other li dl {
      font-weight: 700;
      text-align: center; }
      .site-index .resulted .other li dl dd {
        line-height: 1;
        font-size: 48px;
        color: #db1501;
        text-shadow: none; }
        @media screen and (max-width: 1119px) {
          .site-index .resulted .other li dl dd {
            font-size: 32px; } }
        @media screen and (max-width: 768px) {
          .site-index .resulted .other li dl dd {
            font-size: 28px; } }
        .site-index .resulted .other li dl dd strong {
          font-family: 'Oswald', sans-serif;
          font-size: 120px;
          display: inline-block; }
          @media screen and (max-width: 1119px) {
            .site-index .resulted .other li dl dd strong {
              font-size: 96px; } }
          @media screen and (max-width: 768px) {
            .site-index .resulted .other li dl dd strong {
              font-size: 72px; } }
      .site-index .resulted .other li dl dt {
        margin: 8px 0 0;
        font-size: 24px;
        line-height: 1.56; }
        @media screen and (max-width: 1119px) {
          .site-index .resulted .other li dl dt {
            font-size: 18px; } }
        @media screen and (max-width: 768px) {
          .site-index .resulted .other li dl dt {
            line-height: 1.5; } }
  @media screen and (max-width: 1119px) {
    .site-index .resulted .other__block {
      width: 100%; } }
  .site-index .resulted .other__block__txt {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5; }
    @media screen and (max-width: 1119px) {
      .site-index .resulted .other__block__txt {
        font-size: 18px; } }
  .site-index .resulted .other__block .btnarea {
    margin: 12px 0 0; }
    @media screen and (max-width: 1119px) {
      .site-index .resulted .other__block .btnarea a {
        display: block;
        min-width: 1px;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0; } }

.site-index .resulted .score_param.active {
  animation: scales 300ms forwards; }

@keyframes scales {
  0% {
    transform: scale(1); }
  20% {
    transform: scale(0.8); }
  60% {
    transform: scale(1.4); }
  100% {
    transform: scale(1); } }

.site-index .secondsec {
  position: relative;
  background: url("../../../images/index/sec_q.png") no-repeat right -80px bottom 40px;
  background-size: auto 80%; }
  @media screen and (max-width: 1119px) {
    .site-index .secondsec {
      background: none; } }
  .site-index .secondsec.site-module h2.large {
    margin-left: 8%;
    position: relative;
    z-index: 10;
    color: #db1501; }
    @media screen and (max-width: 1119px) {
      .site-index .secondsec.site-module h2.large {
        margin-left: 20px; } }
    @media screen and (max-width: 768px) {
      .site-index .secondsec.site-module h2.large {
        margin-left: 0;
        position: absolute;
        top: 28px;
        left: 15px; } }
    .site-index .secondsec.site-module h2.large:before {
      font-size: 96px; }
      @media screen and (max-width: 1119px) {
        .site-index .secondsec.site-module h2.large:before {
          font-size: 64px; } }
      @media screen and (max-width: 768px) {
        .site-index .secondsec.site-module h2.large:before {
          font-size: 36px; } }
    .site-index .secondsec.site-module h2.large strong {
      display: inline-block;
      transform: translateY(-40px);
      font-size: 22px; }
      @media screen and (max-width: 1119px) {
        .site-index .secondsec.site-module h2.large strong {
          font-size: 20px;
          transform: translateY(-32px); } }
      @media screen and (max-width: 768px) {
        .site-index .secondsec.site-module h2.large strong {
          font-size: 16px;
          transform: translateY(-15px); } }
  .site-index .secondsec.wrap {
    padding-left: 0;
    padding-right: 0; }
  .site-index .secondsec .display {
    z-index: 10; }
  .site-index .secondsec__wrap {
    position: relative;
    padding: 0 40px 64px; }
    @media screen and (max-width: 1119px) {
      .site-index .secondsec__wrap {
        padding: 0 20px 40px; } }
    @media screen and (max-width: 768px) {
      .site-index .secondsec__wrap {
        padding: 0 15px; } }
  .site-index .secondsec__bg {
    width: 50%;
    height: calc(100% + 64px);
    background-size: cover;
    position: absolute;
    top: -64px;
    left: 0; }
    @media screen and (max-width: 768px) {
      .site-index .secondsec__bg {
        width: calc(100% + 30px);
        height: 260px;
        position: relative;
        top: 0;
        left: -15px;
        margin: 24px 0 0; } }
  .site-index .secondsec__box {
    margin: 0 0 0 50%;
    padding: 0 0 0 56px; }
    @media screen and (max-width: 1119px) {
      .site-index .secondsec__box {
        padding: 0 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-index .secondsec__box {
        padding: 32px 15px 0;
        margin: 0; } }
    .site-index .secondsec__box h2 {
      font-weight: 700;
      font-size: calc(22px + 7 * (100vw - 450px)/ 900);
      line-height: 1.56; }
      @media screen and (max-width: 768px) {
        .site-index .secondsec__box h2 {
          font-size: 17px;
          text-align: center; } }
      .site-index .secondsec__box h2:before {
        content: attr(data-eng);
        font-size: 16px;
        color: #db1501;
        display: block;
        text-indent: .1em;
        margin: 0 0 12px; }
        @media screen and (max-width: 768px) {
          .site-index .secondsec__box h2:before {
            text-indent: 0em;
            margin: 0 0 8px; } }
    .site-index .secondsec__box__txt {
      font-size: calc(12.5px + 2.4 * (100vw - 450px)/ 900);
      margin: 20px 0 0;
      text-align: justify; }
      @media screen and (max-width: 1119px) {
        .site-index .secondsec__box__txt {
          font-size: 14px; } }
      @media screen and (max-width: 768px) {
        .site-index .secondsec__box__txt {
          font-size: 13.5px; } }

.site-index .thirdsec {
  position: relative; }
  .site-index .thirdsec__items {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-index .thirdsec__items {
        display: block; } }
    .site-index .thirdsec__items li {
      list-style: none;
      width: 31%;
      background: #FFF; }
      @media screen and (max-width: 1119px) {
        .site-index .thirdsec__items li {
          width: 32%; } }
      @media screen and (max-width: 768px) {
        .site-index .thirdsec__items li {
          width: auto;
          margin: 0 0 15px;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.06); }
          .site-index .thirdsec__items li:last-child {
            margin: 0; } }
      .site-index .thirdsec__items li figure img {
        width: 100%; }
      .site-index .thirdsec__items li dl {
        padding: 24px; }
        @media screen and (max-width: 1119px) {
          .site-index .thirdsec__items li dl {
            padding: 20px; } }
        .site-index .thirdsec__items li dl dt {
          font-weight: 700;
          font-size: 18px;
          line-height: 1.56;
          text-align: center;
          padding: 12px 0 0; }
          .site-index .thirdsec__items li dl dt:before {
            content: attr(data-text);
            font-size: 12px;
            color: #db1501;
            letter-spacing: .04em;
            text-transform: uppercase;
            display: block;
            margin: 0 0 8px; }
        .site-index .thirdsec__items li dl dd {
          font-size: 14px;
          text-align: justify;
          position: relative;
          margin: 12px 0 0;
          padding: 12px 0 0;
          border-top: #e8e8e8 1px solid; }
          @media screen and (max-width: 1119px) {
            .site-index .thirdsec__items li dl dd {
              font-size: 13px; } }
      .site-index .thirdsec__items li:nth-child(1) dl {
        position: relative; }
        .site-index .thirdsec__items li:nth-child(1) dl:after {
          font-family: 'Oswald', sans-serif;
          content: "01";
          font-size: 15px;
          color: #FFF;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%) translateY(-50%); }
        .site-index .thirdsec__items li:nth-child(1) dl:before {
          content: '';
          position: absolute;
          left: calc(50% - 20px);
          top: -20px;
          width: 40px;
          height: 40px;
          transform: rotate(45deg);
          background: #db1501; }
      .site-index .thirdsec__items li:nth-child(2) dl {
        position: relative; }
        .site-index .thirdsec__items li:nth-child(2) dl:after {
          font-family: 'Oswald', sans-serif;
          content: "02";
          font-size: 15px;
          color: #FFF;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%) translateY(-50%); }
        .site-index .thirdsec__items li:nth-child(2) dl:before {
          content: '';
          position: absolute;
          left: calc(50% - 20px);
          top: -20px;
          width: 40px;
          height: 40px;
          transform: rotate(45deg);
          background: #db1501; }
      .site-index .thirdsec__items li:nth-child(3) dl {
        position: relative; }
        .site-index .thirdsec__items li:nth-child(3) dl:after {
          font-family: 'Oswald', sans-serif;
          content: "03";
          font-size: 15px;
          color: #FFF;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%) translateY(-50%); }
        .site-index .thirdsec__items li:nth-child(3) dl:before {
          content: '';
          position: absolute;
          left: calc(50% - 20px);
          top: -20px;
          width: 40px;
          height: 40px;
          transform: rotate(45deg);
          background: #db1501; }
      .site-index .thirdsec__items li:nth-child(4) dl {
        position: relative; }
        .site-index .thirdsec__items li:nth-child(4) dl:after {
          font-family: 'Oswald', sans-serif;
          content: "04";
          font-size: 15px;
          color: #FFF;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%) translateY(-50%); }
        .site-index .thirdsec__items li:nth-child(4) dl:before {
          content: '';
          position: absolute;
          left: calc(50% - 20px);
          top: -20px;
          width: 40px;
          height: 40px;
          transform: rotate(45deg);
          background: #db1501; }
      .site-index .thirdsec__items li:nth-child(5) dl {
        position: relative; }
        .site-index .thirdsec__items li:nth-child(5) dl:after {
          font-family: 'Oswald', sans-serif;
          content: "05";
          font-size: 15px;
          color: #FFF;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%) translateY(-50%); }
        .site-index .thirdsec__items li:nth-child(5) dl:before {
          content: '';
          position: absolute;
          left: calc(50% - 20px);
          top: -20px;
          width: 40px;
          height: 40px;
          transform: rotate(45deg);
          background: #db1501; }
  .site-index .thirdsec .btnarea {
    margin: 48px 0 0; }
    @media screen and (max-width: 768px) {
      .site-index .thirdsec .btnarea {
        margin: 24px 0 0; } }

.site-index .forsec {
  position: relative; }
  .site-index .forsec.wrap {
    padding-left: 0;
    padding-right: 0; }
  .site-index .forsec:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../../images/index/photos.jpg") no-repeat center;
    background-size: cover;
    opacity: .12; }
  .site-index .forsec #index-swipe {
    position: relative;
    z-index: 2;
    padding: 0 40px; }
    .site-index .forsec #index-swipe .swiper-wrapper {
      transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1); }
    .site-index .forsec #index-swipe .swiper-slide {
      width: 300px; }
    .site-index .forsec #index-swipe .bg {
      width: 300px;
      height: 300px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }

.site-index .fifthsec .bnrlist {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-index .fifthsec .bnrlist {
      display: block; } }
  .site-index .fifthsec .bnrlist li {
    width: 31.5%; }
    @media screen and (max-width: 768px) {
      .site-index .fifthsec .bnrlist li {
        width: 100%;
        margin: 0 0 15px; }
        .site-index .fifthsec .bnrlist li:last-child {
          margin: 0; } }
  .site-index .fifthsec .bnrlist img {
    width: 100%; }

.site-index .news {
  position: relative;
  z-index: 2;
  background: #db1501;
  color: #FFF; }
  .site-index .news .display.flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-index .news .display.flex {
        display: block; } }
  .site-index .news .newsbox {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-index .news .newsbox {
        width: 100%;
        padding: 0;
        display: block; } }
  .site-index .news .leftside {
    width: 280px; }
    @media screen and (max-width: 768px) {
      .site-index .news .leftside {
        width: 100%; } }
    .site-index .news .leftside h3 {
      line-height: 1;
      font-size: 14px;
      margin: 0 0 16px;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .site-index .news .leftside h3 {
          font-size: 12px; } }
      .site-index .news .leftside h3:before {
        content: attr(data-eng);
        font-size: 36px;
        display: inline-block;
        margin: 0 24px 0 0; }
        @media screen and (max-width: 768px) {
          .site-index .news .leftside h3:before {
            font-size: 20px; } }
    .site-index .news .leftside a {
      display: inline-block;
      font-weight: 700;
      color: #FFF;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .site-index .news .leftside a {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 13px; } }
      .site-index .news .leftside a:hover {
        text-decoration: underline; }
      .site-index .news .leftside a i {
        display: inline-block;
        font-size: 13px;
        margin: 0 0 0 12px; }
  .site-index .news ol {
    width: calc(100% - 280px);
    height: 130px;
    padding: 0 40px 0 0;
    overflow: auto; }
    @media screen and (max-width: 768px) {
      .site-index .news ol {
        width: 100%;
        height: 164px;
        padding: 15px;
        border: rgba(255, 255, 255, 0.2) 1px solid;
        border-radius: 4px; } }
    .site-index .news ol li {
      border-bottom: rgba(255, 255, 255, 0.16) 1px solid;
      padding: 0 0 24px;
      margin: 0 0 24px; }
      @media screen and (max-width: 768px) {
        .site-index .news ol li {
          margin: 0 0 15px;
          padding: 0 0 15px; } }
      .site-index .news ol li:last-child {
        margin: 0; }
      .site-index .news ol li a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        color: #FFF; }
        @media screen and (max-width: 1119px) {
          .site-index .news ol li a {
            display: block; } }
        .site-index .news ol li a:hover h4 {
          text-decoration: underline; }
      .site-index .news ol li span.time {
        width: 14%;
        font-size: 12px;
        background: #151515;
        color: #FFF;
        padding: 2px 0;
        text-align: center; }
        @media screen and (max-width: 1119px) {
          .site-index .news ol li span.time {
            width: 115px;
            display: inline-block;
            font-size: 12px;
            padding: 1px 12px;
            margin: 2px 0 0; } }
      .site-index .news ol li h4 {
        width: 86%;
        font-size: 16px;
        padding: 0 0 0 32px; }
        @media screen and (max-width: 1119px) {
          .site-index .news ol li h4 {
            width: auto;
            font-size: 14px;
            padding: 8px 0 0;
            clear: both; } }

.site-index .sns {
  background: #f2f2f2; }
  .site-index .sns.wrap {
    padding-top: 48px;
    padding-bottom: 48px; }
    @media screen and (max-width: 768px) {
      .site-index .sns.wrap {
        padding-top: 15px;
        padding-bottom: 15px; } }
  .site-index .sns .snslist {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-index .sns .snslist {
        display: block; } }
    .site-index .sns .snslist__items {
      width: 48.5%; }
      @media screen and (max-width: 768px) {
        .site-index .sns .snslist__items {
          width: auto;
          margin: 0 0 20px; }
          .site-index .sns .snslist__items:last-of-type {
            margin: 0; } }
      .site-index .sns .snslist__items iframe {
        width: 100% !important;
        vertical-align: bottom; }

.site-index .firstview {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  border-top: none;
  background: #000;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* もしも画面映えしない場合
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            background: url("../../../images/index/fv_q.png") no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 0;
            left: 0;
            opacity: .32;
            z-index: 2;
        }
        */ }
  .site-index .firstview:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .12;
    z-index: 2; }
  .site-index .firstview:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: inset 0 0 255px rgba(0, 0, 0, 0.88); }
  .site-index .firstview #video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: 1; }
  .site-index .firstview .catch {
    position: relative;
    z-index: 3;
    text-align: center;
    font-weight: 700;
    color: #FFF;
    line-height: 1;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-8px); }
    .site-index .firstview .catch dt {
      font-size: calc(20px + 8 * (100vw - 450px)/ 900);
      letter-spacing: .06em;
      margin: 24px 0 0;
      opacity: 0;
      transform: translateY(8px); }
      @media screen and (max-width: 768px) {
        .site-index .firstview .catch dt {
          font-size: calc(16px + 6 * (100vw - 450px)/ 900);
          margin: 16px 0 0; } }
    .site-index .firstview .catch dd {
      font-size: calc(80px + 34 * (100vw - 450px)/ 900);
      font-family: 'Oswald', sans-serif;
      letter-spacing: 0em; }
      @media screen and (max-width: 1119px) {
        .site-index .firstview .catch dd {
          font-size: calc(72px + 32 * (100vw - 450px)/ 900); } }
      @media screen and (max-width: 768px) {
        .site-index .firstview .catch dd {
          font-size: calc(40px + 30 * (100vw - 450px)/ 900); } }
    .site-index .firstview .catch dd span {
      display: inline-block;
      opacity: 0;
      transform: scale(3) translateX(-20px); }
      .site-index .firstview .catch dd span:nth-of-type(1) {
        transition: transform 200ms 80ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 80ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(2) {
        transition: transform 200ms 160ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 160ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(3) {
        transition: transform 200ms 240ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 240ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(4) {
        transition: transform 200ms 320ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 320ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(5) {
        transition: transform 200ms 400ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 400ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(6) {
        transition: transform 200ms 480ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 480ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(7) {
        transition: transform 200ms 560ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 560ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(8) {
        transition: transform 200ms 640ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 640ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(9) {
        transition: transform 200ms 720ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 720ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(10) {
        transition: transform 200ms 800ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 800ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(11) {
        transition: transform 200ms 880ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 880ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(12) {
        transition: transform 200ms 960ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 960ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(13) {
        transition: transform 200ms 1040ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1040ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(14) {
        transition: transform 200ms 1120ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1120ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(15) {
        transition: transform 200ms 1200ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1200ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(16) {
        transition: transform 200ms 1280ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1280ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(17) {
        transition: transform 200ms 1360ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1360ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(18) {
        transition: transform 200ms 1440ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1440ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(19) {
        transition: transform 200ms 1520ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1520ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(20) {
        transition: transform 200ms 1600ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1600ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(21) {
        transition: transform 200ms 1680ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1680ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(22) {
        transition: transform 200ms 1760ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1760ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(23) {
        transition: transform 200ms 1840ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1840ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(24) {
        transition: transform 200ms 1920ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 1920ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(25) {
        transition: transform 200ms 2000ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2000ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(26) {
        transition: transform 200ms 2080ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2080ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(27) {
        transition: transform 200ms 2160ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2160ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(28) {
        transition: transform 200ms 2240ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2240ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(29) {
        transition: transform 200ms 2320ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2320ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(30) {
        transition: transform 200ms 2400ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2400ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(31) {
        transition: transform 200ms 2480ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2480ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(32) {
        transition: transform 200ms 2560ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2560ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(33) {
        transition: transform 200ms 2640ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2640ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(34) {
        transition: transform 200ms 2720ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2720ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(35) {
        transition: transform 200ms 2800ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2800ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(36) {
        transition: transform 200ms 2880ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2880ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(37) {
        transition: transform 200ms 2960ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 2960ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(38) {
        transition: transform 200ms 3040ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3040ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(39) {
        transition: transform 200ms 3120ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3120ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(40) {
        transition: transform 200ms 3200ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3200ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(41) {
        transition: transform 200ms 3280ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3280ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(42) {
        transition: transform 200ms 3360ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3360ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(43) {
        transition: transform 200ms 3440ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3440ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(44) {
        transition: transform 200ms 3520ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3520ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(45) {
        transition: transform 200ms 3600ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3600ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(46) {
        transition: transform 200ms 3680ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3680ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(47) {
        transition: transform 200ms 3760ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3760ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(48) {
        transition: transform 200ms 3840ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3840ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(49) {
        transition: transform 200ms 3920ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 3920ms cubic-bezier(0.65, 0, 0.35, 1); }
      .site-index .firstview .catch dd span:nth-of-type(50) {
        transition: transform 200ms 4000ms cubic-bezier(0.65, 0, 0.35, 1), opacity 200ms 4000ms cubic-bezier(0.65, 0, 0.35, 1); }
    .site-index .firstview .catch.active {
      opacity: 1; }
      .site-index .firstview .catch.active dt {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 1000ms 1600ms, transform 1000ms 1600ms; }
      .site-index .firstview .catch.active dd span {
        opacity: 1;
        transform: scale(1) translateX(0); }
  .site-index .firstview .rightside {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    z-index: 3;
    color: #FFF;
    text-align: center; }
    @media screen and (max-width: 1119px) {
      .site-index .firstview .rightside {
        right: 20px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstview .rightside {
        display: none; } }
    .site-index .firstview .rightside a {
      color: #FFF;
      font-size: 16px;
      display: block; }
    .site-index .firstview .rightside li {
      margin: 12px 0; }
      .site-index .firstview .rightside li.tate {
        font-family: 'Oswald', sans-serif;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.72);
        -webkit-writing-mode: vertical-rl;
        -moz-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        padding: 0 0 24px;
        margin: 0 0 24px;
        line-height: 1;
        letter-spacing: .12em;
        border-bottom: rgba(255, 255, 255, 0.4) 1px solid; }
  .site-index .firstview .topics {
    color: #FFF;
    min-width: 300px;
    max-width: 500px;
    position: absolute;
    left: 32px;
    bottom: 24px;
    z-index: 3; }
    @media screen and (max-width: 1119px) {
      .site-index .firstview .topics {
        left: 20px;
        bottom: 20px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstview .topics {
        left: 15px;
        bottom: 15px;
        padding: 0 20px 0 0;
        max-width: 100%;
        min-width: 1px;
        width: calc(100% - 30px); } }
    .site-index .firstview .topics li {
      margin: 0 0 16px; }
      .site-index .firstview .topics li:last-child {
        margin-bottom: 0; }
      .site-index .firstview .topics li a {
        color: #FFF;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        display: inline-flex;
        align-items: center;
        position: relative;
        padding: 0 32px 0 0; }
    .site-index .firstview .topics i {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
    .site-index .firstview .topics .time {
      background: #db1501;
      width: 110px;
      font-size: 12px;
      padding: 4px 0;
      font-weight: 700;
      text-align: center;
      border-radius: 999px; }
      @media screen and (max-width: 768px) {
        .site-index .firstview .topics .time {
          font-size: 11px;
          width: 90px;
          padding: 3px 0; } }
    .site-index .firstview .topics__mes {
      width: calc(100% - 110px);
      padding: 0 0 0 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      font-weight: 700;
      text-decoration: underline; }
      @media screen and (max-width: 768px) {
        .site-index .firstview .topics__mes {
          width: calc(100% - 90px);
          font-size: 14px;
          padding: 0 0 0 15px; } }
  .site-index .firstview .scroll {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3; }
    .site-index .firstview .scroll:before {
      content: '';
      width: 180px;
      height: 180px;
      background: #db1501;
      position: absolute;
      right: -90px;
      bottom: -90px;
      transform: rotate(45deg); }
      @media screen and (max-width: 768px) {
        .site-index .firstview .scroll:before {
          width: 140px;
          height: 140px;
          right: -70px;
          bottom: -70px; } }
    .site-index .firstview .scroll__line {
      position: absolute;
      right: 24px;
      bottom: 24px;
      z-index: 3;
      height: 140px; }
      @media screen and (max-width: 768px) {
        .site-index .firstview .scroll__line {
          display: none; } }
      .site-index .firstview .scroll__line:before {
        position: absolute;
        right: 0;
        bottom: 0;
        content: '';
        width: 1px;
        height: 100%;
        background: #FFF;
        animation: sc 2s infinite; }

@keyframes sc {
  0% {
    height: 0%;
    bottom: 100%; }
  25% {
    height: 100%;
    bottom: 0; }
  51% {
    height: 100%;
    bottom: 0; }
  75% {
    height: 0%;
    bottom: 0; }
  100% {
    height: 0%;
    bottom: 0; } }
      .site-index .firstview .scroll__line:after {
        font-size: 11px;
        -webkit-writing-mode: vertical-rl;
        -moz-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        white-space: nowrap;
        letter-spacing: .12em;
        content: 'SCROLL';
        position: absolute;
        top: 0;
        right: 8px;
        color: #FFF; }
  .site-index .firstview .copy {
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
    letter-spacing: .08em;
    position: absolute;
    top: 0;
    left: 40px;
    height: 100%;
    color: rgba(255, 255, 255, 0.88);
    font-size: 9px;
    text-align: center;
    letter-spacing: .16em;
    z-index: 3;
    color: #FFF;
    text-transform: uppercase; }
    @media screen and (max-width: 1119px) {
      .site-index .firstview .copy {
        left: 20px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstview .copy {
        display: none; } }

.site-teacher .teacherlist {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-teacher .teacherlist {
      display: block; } }
  .site-teacher .teacherlist__items {
    width: calc(50% - 20px);
    height: 180px;
    margin: 40px 0 0;
    position: relative;
    overflow: hidden;
    display: block; }
    @media screen and (max-width: 1119px) {
      .site-teacher .teacherlist__items {
        width: calc(50% - 10px); } }
    @media screen and (max-width: 768px) {
      .site-teacher .teacherlist__items {
        width: auto;
        margin: 0 0 16px; }
        .site-teacher .teacherlist__items:last-child {
          margin: 0; } }
    .site-teacher .teacherlist__items:nth-child(-n+2) {
      margin-top: 0; }
    .site-teacher .teacherlist__items:before, .site-teacher .teacherlist__items:after {
      content: '';
      width: 60%;
      height: 100%;
      position: absolute;
      top: 0;
      right: -5%;
      transform: skewX(15deg);
      background: #f1f1f1;
      z-index: 2; }
      @media screen and (max-width: 1119px) {
        .site-teacher .teacherlist__items:before, .site-teacher .teacherlist__items:after {
          width: 70%;
          right: -10%; } }
    .site-teacher .teacherlist__items:after {
      width: 0;
      background: #db1501;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
    .site-teacher .teacherlist__items:hover:after {
      width: 60%; }
      @media screen and (max-width: 1119px) {
        .site-teacher .teacherlist__items:hover:after {
          width: 70%; } }
    .site-teacher .teacherlist__items:hover .bg {
      transform: scale(1.1); }
    .site-teacher .teacherlist__items:hover dl {
      color: #FFF; }
    .site-teacher .teacherlist__items:hover i {
      border-color: #FFF;
      color: #FFF; }
    .site-teacher .teacherlist__items .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-size: cover;
      background-position: center;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
    .site-teacher .teacherlist__items dl {
      padding: 56px 40px;
      margin: 0 0 0 50%;
      position: relative;
      color: #151515;
      z-index: 3; }
      @media screen and (max-width: 1119px) {
        .site-teacher .teacherlist__items dl {
          padding: 40px 20px;
          padding-top: 56px;
          margin: 0 0 0 44%; } }
      .site-teacher .teacherlist__items dl dt {
        margin: -12px 0 0;
        font-weight: 700;
        font-size: 16px; }
        .site-teacher .teacherlist__items dl dt strong {
          font-size: 28px;
          display: inline-block;
          margin: 0 8px 0 0; }
          @media screen and (max-width: 1119px) {
            .site-teacher .teacherlist__items dl dt strong {
              font-size: 20px; } }
      .site-teacher .teacherlist__items dl dd {
        margin: 8px 0 0; }
        .site-teacher .teacherlist__items dl dd:before {
          content: attr(data-cate);
          display: inline-block;
          margin: 0 12px 0 0;
          padding: 1px 8px;
          background: #151515;
          color: #FFF;
          font-size: 13px; }
    .site-teacher .teacherlist__items__badge {
      width: 52px;
      height: 52px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      color: #FFF;
      background: #c71301;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 6; }
      @media screen and (max-width: 1119px) {
        .site-teacher .teacherlist__items__badge {
          font-size: 12px;
          width: 40px;
          height: 40px; } }
    .site-teacher .teacherlist__items__more {
      position: absolute;
      right: 0;
      bottom: 20px;
      border-bottom: #151515 1px solid;
      color: #151515;
      padding: 0 48px 0 0;
      z-index: 4; }

.site-teacher-single .profile {
  padding: 64px 0;
  position: relative; }
  @media screen and (max-width: 1119px) {
    .site-teacher-single .profile {
      padding: 32px 0; } }
  .site-teacher-single .profile:before {
    content: '';
    width: calc(50% + 40px);
    height: 100%;
    background: #f4f4f4;
    position: absolute;
    top: 0;
    left: -40px; }
  .site-teacher-single .profile__box {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    padding: 24px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.04);
    overflow: hidden;
    position: relative;
    margin: 0 0 48px; }
    @media screen and (max-width: 1119px) {
      .site-teacher-single .profile__box {
        align-items: flex-start;
        padding: 20px;
        margin: 0 0 40px; } }
    @media screen and (max-width: 768px) {
      .site-teacher-single .profile__box {
        padding: 0;
        display: block;
        margin: 0 0 24px; } }
    .site-teacher-single .profile__box:last-of-type {
      margin: 0; }
    .site-teacher-single .profile__box:before {
      content: '';
      width: 80px;
      height: 80px;
      position: absolute;
      right: -40px;
      top: -40px;
      background: #db1501;
      transform: rotate(45deg); }
      @media screen and (max-width: 1119px) {
        .site-teacher-single .profile__box:before {
          width: 40px;
          height: 40px;
          right: -20px;
          top: -20px; } }
      @media screen and (max-width: 768px) {
        .site-teacher-single .profile__box:before {
          display: none; } }
    .site-teacher-single .profile__box figure {
      width: 480px; }
      @media screen and (max-width: 1119px) {
        .site-teacher-single .profile__box figure {
          width: 320px; } }
      @media screen and (max-width: 768px) {
        .site-teacher-single .profile__box figure {
          width: auto; } }
      .site-teacher-single .profile__box figure img {
        width: 100%; }
    .site-teacher-single .profile__box dl {
      width: calc(100% - 480px);
      padding: 0 24px 0 48px; }
      @media screen and (max-width: 1119px) {
        .site-teacher-single .profile__box dl {
          width: calc(100% - 320px);
          padding: 0 0 0 32px; } }
      @media screen and (max-width: 768px) {
        .site-teacher-single .profile__box dl {
          width: auto;
          padding: 20px; } }
      .site-teacher-single .profile__box dl dt {
        font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.04em;
        font-size: 30px;
        font-weight: 700;
        line-height: 1.6;
        margin: 0 0 16px; }
        @media screen and (max-width: 1119px) {
          .site-teacher-single .profile__box dl dt {
            font-size: 24px; } }
        @media screen and (max-width: 768px) {
          .site-teacher-single .profile__box dl dt {
            font-size: 20px;
            margin: 0 0 12px; } }
        .site-teacher-single .profile__box dl dt:first-letter {
          color: #db1501;
          font-size: 48px;
          line-height: 1; }
          @media screen and (max-width: 1119px) {
            .site-teacher-single .profile__box dl dt:first-letter {
              font-size: 32px; } }
          @media screen and (max-width: 768px) {
            .site-teacher-single .profile__box dl dt:first-letter {
              font-size: 28px; } }
      .site-teacher-single .profile__box dl dd {
        font-size: 15px;
        text-align: justify; }
        @media screen and (max-width: 1119px) {
          .site-teacher-single .profile__box dl dd {
            font-size: 14px; } }
        @media screen and (max-width: 768px) {
          .site-teacher-single .profile__box dl dd {
            font-size: 13.5px; } }
    .site-teacher-single .profile__box--rvs:before {
      right: auto;
      left: -40px; }
      @media screen and (max-width: 1119px) {
        .site-teacher-single .profile__box--rvs:before {
          left: -30px; } }
    .site-teacher-single .profile__box--rvs figure {
      order: 2; }
    .site-teacher-single .profile__box--rvs dl {
      order: 1;
      padding: 0 48px 0 24px; }
      @media screen and (max-width: 1119px) {
        .site-teacher-single .profile__box--rvs dl {
          padding: 0 32px 0 0; } }
      @media screen and (max-width: 768px) {
        .site-teacher-single .profile__box--rvs dl {
          padding: 20px; } }

.site-advertise h2.title {
  text-align: center;
  font-size: 28px;
  line-height: 1.6;
  font-weight: 700;
  margin: 0 0 24px; }
  @media screen and (max-width: 1119px) {
    .site-advertise h2.title {
      font-size: 22px; } }
  @media screen and (max-width: 768px) {
    .site-advertise h2.title {
      font-size: 20px;
      text-align: left;
      border-bottom: #db1501 6px solid;
      line-height: 1.5;
      padding: 0 0 6px; } }
  .site-advertise h2.title strong {
    display: inline-block;
    border-bottom: #db1501 6px solid; }
    @media screen and (max-width: 768px) {
      .site-advertise h2.title strong {
        display: block;
        border: none; } }

.site-advertise .localnav {
  background: #f4f4f4;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.12);
  padding: 24px; }
  @media screen and (max-width: 1119px) {
    .site-advertise .localnav {
      padding: 20px; } }
  @media screen and (max-width: 768px) {
    .site-advertise .localnav {
      padding: 15px; } }
  .site-advertise .localnav__list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .site-advertise .localnav__list {
        display: block; } }
    .site-advertise .localnav__list li {
      list-style: none;
      margin: 0 16px 0 0; }
      @media screen and (max-width: 768px) {
        .site-advertise .localnav__list li {
          margin: 0 0 8px; } }
      .site-advertise .localnav__list li:last-child {
        margin: 0; }
      .site-advertise .localnav__list li a {
        background: #db1501;
        border: #db1501 1px solid;
        color: #FFF;
        border-radius: 3px;
        font-size: 18px;
        font-weight: 700;
        padding: 8px 32px;
        display: block;
        min-width: 240px;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .site-advertise .localnav__list li a {
            display: block;
            padding: 8px 15px; } }
        .site-advertise .localnav__list li a:hover {
          background: #FFF;
          color: #db1501; }

.site-advertise .data {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  border: #f4f4f4 24px solid;
  position: relative;
  margin: 0 0 56px; }
  @media screen and (max-width: 768px) {
    .site-advertise .data {
      border: #e8e8e8 8px solid;
      margin: 0 0 32px;
      display: block; } }
  .site-advertise .data__pic {
    width: 640px;
    position: relative;
    cursor: pointer;
    overflow: hidden; }
    @media screen and (max-width: 1119px) {
      .site-advertise .data__pic {
        width: 320px; } }
    @media screen and (max-width: 768px) {
      .site-advertise .data__pic {
        width: auto;
        pointer-events: none; } }
    .site-advertise .data__pic.sizeM {
      background: #f4f4f4;
      text-align: center; }
      .site-advertise .data__pic.sizeM img {
        width: 50%; }
        @media screen and (max-width: 1119px) {
          .site-advertise .data__pic.sizeM img {
            width: 100%; } }
        @media screen and (max-width: 768px) {
          .site-advertise .data__pic.sizeM img {
            pointer-events: none; } }
    .site-advertise .data__pic:hover img {
      opacity: .88; }
    .site-advertise .data__pic:hover figcaption {
      background: #fe1e07; }
    .site-advertise .data__pic img {
      width: 100%;
      -webkit-transition: all 120ms 0ms ease-in-out;
      -moz-transition: all 120ms 0ms ease-in-out;
      -ms-transition: all 120ms 0ms ease-in-out;
      -o-transition: all 120ms 0ms ease-in-out;
      transition: all 120ms 0ms ease-in-out; }
    .site-advertise .data__pic figcaption {
      position: absolute;
      right: 0;
      top: 0;
      background: #db1501;
      padding: 8px 16px;
      color: #FFF;
      font-weight: 700;
      font-size: 15px;
      -webkit-transition: all 120ms 0ms ease-in-out;
      -moz-transition: all 120ms 0ms ease-in-out;
      -ms-transition: all 120ms 0ms ease-in-out;
      -o-transition: all 120ms 0ms ease-in-out;
      transition: all 120ms 0ms ease-in-out; }
      @media screen and (max-width: 1119px) {
        .site-advertise .data__pic figcaption {
          font-size: 13px; } }
      @media screen and (max-width: 768px) {
        .site-advertise .data__pic figcaption {
          display: none; } }
      .site-advertise .data__pic figcaption i {
        display: inline-block;
        margin: 0 8px 0 0; }
  .site-advertise .data__dl {
    width: calc(100% - 640px);
    padding: 40px;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 1119px) {
      .site-advertise .data__dl {
        width: calc(100% - 320px);
        padding: 32px; } }
    @media screen and (max-width: 768px) {
      .site-advertise .data__dl {
        width: auto;
        padding: 15px; } }
    .site-advertise .data__dl dt {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.6;
      padding-left: 36px;
      position: relative; }
      @media screen and (max-width: 1119px) {
        .site-advertise .data__dl dt {
          font-size: 24px; } }
      @media screen and (max-width: 768px) {
        .site-advertise .data__dl dt {
          font-size: 18px;
          padding-left: 24px; } }
      .site-advertise .data__dl dt:before {
        content: '';
        border: #db1501 6px solid;
        position: absolute;
        top: calc(50% + 2px);
        left: 0;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
        border-radius: 50%; }
        @media screen and (max-width: 768px) {
          .site-advertise .data__dl dt:before {
            width: 16px;
            height: 16px;
            border: #db1501 4px solid; } }
    .site-advertise .data__dl dd {
      margin: 14px 0 0;
      padding: 14px 0 0;
      border-top: #e8e8e8 1px solid;
      font-size: 15px;
      text-align: justify; }
      @media screen and (max-width: 1119px) {
        .site-advertise .data__dl dd {
          font-size: 13.5px; } }
      .site-advertise .data__dl dd.btnarea {
        margin: 32px 0 0;
        padding: 0;
        border: none; }

.site-advertise .telarea {
  background: #f4f4f4;
  padding: 24px;
  margin: 0 0 56px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 1119px) {
    .site-advertise .telarea {
      padding: 20px;
      margin: 0 0 32px; } }
  @media screen and (max-width: 768px) {
    .site-advertise .telarea {
      padding: 15px;
      margin: 0 0 24px;
      display: block; } }
  .site-advertise .telarea li {
    width: 23.5%;
    background: #FFF;
    padding: 15px;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.02); }
    @media screen and (min-width: 1120px) {
      .site-advertise .telarea li {
        margin: 15px 0 0; }
        .site-advertise .telarea li:nth-child(-n+4) {
          margin-top: 0; } }
    @media screen and (max-width: 1119px) {
      .site-advertise .telarea li {
        width: 48.5%;
        margin: 16px 0 0; }
        .site-advertise .telarea li:nth-child(-n+2) {
          margin-top: 0; } }
    @media screen and (max-width: 768px) {
      .site-advertise .telarea li {
        width: 100%;
        margin: 0 0 8px; }
        .site-advertise .telarea li:last-child {
          margin: 0; } }
    .site-advertise .telarea li dl {
      font-weight: 700; }
      .site-advertise .telarea li dl dt {
        font-size: 16px;
        margin: 0 0 8px; }
      .site-advertise .telarea li dl dd a {
        display: block;
        background: #db1501;
        color: #FFF;
        font-size: 18px;
        padding: 2px 15px;
        border-radius: 4px;
        box-shadow: 0 4px 0 #b21101; }
        .site-advertise .telarea li dl dd a i {
          display: inline-block;
          transform: rotateY(180deg) translateY(-1px);
          margin: 0 8px 0 0; }

.site-advertise .overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(21, 21, 21, 0.88);
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  overflow: hidden; }
  .site-advertise .overlay.active {
    pointer-events: auto;
    opacity: 1;
    z-index: 9000;
    transition: opacity 800ms; }
    .site-advertise .overlay.active img {
      max-width: 84%;
      max-height: 84%;
      -webkit-transition: all 400ms 0ms ease-in-out;
      -moz-transition: all 400ms 0ms ease-in-out;
      -ms-transition: all 400ms 0ms ease-in-out;
      -o-transition: all 400ms 0ms ease-in-out;
      transition: all 400ms 0ms ease-in-out; }
  .site-advertise .overlay__close {
    width: 48px;
    height: 48px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 18px;
    cursor: pointer;
    -webkit-transition: all 120ms 0ms ease-in-out;
    -moz-transition: all 120ms 0ms ease-in-out;
    -ms-transition: all 120ms 0ms ease-in-out;
    -o-transition: all 120ms 0ms ease-in-out;
    transition: all 120ms 0ms ease-in-out; }
    .site-advertise .overlay__close:hover {
      transform: scale(1.2); }
  .site-advertise .overlay > img {
    max-width: 20%;
    max-height: 20%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1; }

.site-advertise .step__title {
  font-size: 2.88rem;
  font-weight: 700;
  line-height: 1.44;
  text-align: center;
  color: #023894;
  margin: 0 0 32px; }
  @media screen and (max-width: 1119px) {
    .site-advertise .step__title {
      font-size: 2rem;
      margin: 0 0 20px; } }

.site-advertise .step__list {
  position: relative;
  margin: 0 0 80px;
  padding: 0 0 60px;
  border-bottom: #d9d9d9 1px solid; }
  @media screen and (max-width: 768px) {
    .site-advertise .step__list {
      margin: 0 0 40px;
      padding: 0 0 24px; } }
  .site-advertise .step__list.none {
    margin: 0;
    padding: 0;
    border: none; }
    .site-advertise .step__list.none:after {
      display: none; }
  .site-advertise .step__list:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 40px 0 40px;
    border-color: #d9d9d9 transparent transparent transparent;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%); }
    @media screen and (max-width: 768px) {
      .site-advertise .step__list:after {
        border-width: 24px 24px 0 24px; } }
  .site-advertise .step__list__mid {
    font-size: 1.88rem;
    font-weight: 700;
    line-height: 1.56;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .site-advertise .step__list__mid {
        font-size: 1.32rem;
        margin: 0 16px; } }
    .site-advertise .step__list__mid i {
      width: 64px;
      height: 64px;
      margin: 0 auto 24px;
      font-size: 1.99rem;
      display: block;
      background: #FFF;
      color: #023894;
      border-radius: 50%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      box-shadow: 2px 2px 16px rgba(2, 56, 148, 0.08); }
      @media screen and (max-width: 768px) {
        .site-advertise .step__list__mid i {
          width: 56px;
          height: 56px;
          font-size: 1.64rem; } }
  .site-advertise .step__list__three {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    margin: 48px 0 0; }
    @media screen and (max-width: 768px) {
      .site-advertise .step__list__three {
        margin: 24px 8px 0;
        display: block; } }
    .site-advertise .step__list__three li {
      width: calc(33.3% - 12px);
      background: #FFF;
      height: auto;
      padding: 24px;
      padding-top: 40px;
      position: relative;
      border-radius: 0 0 3px 3px;
      border-top: #023894 2px solid; }
      @media screen and (max-width: 768px) {
        .site-advertise .step__list__three li {
          width: auto;
          margin: 0 0 32px;
          padding: 16px;
          padding-top: 24px; }
          .site-advertise .step__list__three li:last-child {
            margin-bottom: 0; } }
      .site-advertise .step__list__three li:nth-child(1):before {
        content: "1"; }
      .site-advertise .step__list__three li:nth-child(2):before {
        content: "2"; }
      .site-advertise .step__list__three li:nth-child(3):before {
        content: "3"; }
      .site-advertise .step__list__three li:nth-child(4):before {
        content: "4"; }
      .site-advertise .step__list__three li:nth-child(5):before {
        content: "5"; }
      .site-advertise .step__list__three li:nth-child(6):before {
        content: "6"; }
      .site-advertise .step__list__three li:nth-child(7):before {
        content: "7"; }
      .site-advertise .step__list__three li:nth-child(8):before {
        content: "8"; }
      .site-advertise .step__list__three li:nth-child(9):before {
        content: "9"; }
      .site-advertise .step__list__three li:nth-child(10):before {
        content: "10"; }
      .site-advertise .step__list__three li:before {
        content: '1';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #FFF;
        border: #023894 2px solid;
        color: #023894;
        font-size: 1.32rem;
        line-height: 1;
        font-weight: 700;
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        border-radius: 50%; }
        @media screen and (max-width: 768px) {
          .site-advertise .step__list__three li:before {
            width: 32px;
            height: 32px;
            font-size: 1.12rem; } }
      .site-advertise .step__list__three li dl dt {
        font-size: 1.44rem;
        font-weight: 700;
        color: #023894;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .site-advertise .step__list__three li dl dt {
            font-size: 1.24rem; } }
      .site-advertise .step__list__three li dl dd {
        margin: 8px 0 0;
        font-size: 1.08rem;
        line-height: 1.66; }
        @media screen and (max-width: 768px) {
          .site-advertise .step__list__three li dl dd {
            font-size: 1rem; } }
  .site-advertise .step__list__context {
    text-align: center;
    font-size: 1.08rem;
    margin: 12px 0 24px; }
    .site-advertise .step__list__context.l {
      font-size: 1.16rem;
      margin-bottom: 0; }
      @media screen and (max-width: 768px) {
        .site-advertise .step__list__context.l {
          padding: 0 16px;
          font-size: 1rem; } }
  .site-advertise .step__list__table {
    background: #FFF;
    border-radius: 3px;
    padding: 40px; }
    @media screen and (max-width: 768px) {
      .site-advertise .step__list__table {
        padding: 16px; } }
    .site-advertise .step__list__table dl {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      font-size: 1.12rem;
      margin: 0 0 32px;
      padding: 0 0 32px;
      border-bottom: #e8e8e8 1px dashed; }
      @media screen and (max-width: 768px) {
        .site-advertise .step__list__table dl {
          display: block;
          font-size: 1rem;
          margin: 0 0 16px;
          padding: 0 0 16px; } }
      .site-advertise .step__list__table dl dt {
        width: 200px;
        font-size: 1.24rem;
        font-weight: 700;
        color: #023894; }
        @media screen and (max-width: 768px) {
          .site-advertise .step__list__table dl dt {
            width: auto; } }
      .site-advertise .step__list__table dl dd {
        width: calc(100% - 200px); }
        @media screen and (max-width: 768px) {
          .site-advertise .step__list__table dl dd {
            width: auto; } }
    .site-advertise .step__list__table__under {
      text-align: center;
      font-size: 1.12rem;
      padding: 24px;
      background: #f4f4f4; }
      @media screen and (max-width: 768px) {
        .site-advertise .step__list__table__under {
          padding: 16px;
          text-align: left;
          font-size: 1rem; } }
      .site-advertise .step__list__table__under h4 {
        font-weight: 700;
        font-size: 1.32rem; }
  .site-advertise .step__list__test {
    margin: 32px 0 0;
    background: #FFF;
    border-radius: 3px;
    padding: 40px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .site-advertise .step__list__test {
        padding: 16px;
        margin: 24px 0 0; } }
    .site-advertise .step__list__test__pic {
      width: 400px;
      height: calc(400px / 16 * 9); }
      @media screen and (max-width: 768px) {
        .site-advertise .step__list__test__pic {
          width: auto;
          height: auto; } }
      .site-advertise .step__list__test__pic img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .site-advertise .step__list__test__data {
      font-size: 1.12rem;
      width: calc(100% - 400px);
      padding: 0 0 0 56px; }
      @media screen and (max-width: 768px) {
        .site-advertise .step__list__test__data {
          width: auto;
          padding: 24px 0 0;
          font-size: 1rem; } }
      .site-advertise .step__list__test__data dt {
        font-weight: 700;
        font-size: 1.64rem;
        line-height: 1.56;
        margin: 0 0 16px;
        padding: 0 0 16px;
        border-bottom: #e8e8e8 1px solid; }
        @media screen and (max-width: 768px) {
          .site-advertise .step__list__test__data dt {
            font-size: 1.24rem; } }

.site-contact h2.huge {
  text-align: center;
  font-size: 24px;
  font-weight: 700; }
  @media screen and (max-width: 1119px) {
    .site-contact h2.huge {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    .site-contact h2.huge {
      font-size: 15px; } }

.site-contact .kugiri {
  margin: 24px 0 36px; }
  .site-contact .kugiri .telarea {
    margin-bottom: 0; }

.site-contact .context {
  margin: 0 0 16px;
  font-size: 17px;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .site-contact .context {
      font-size: 14px; } }

.site-contact .box {
  width: 88%;
  margin: 0 auto; }
  @media screen and (max-width: 1119px) {
    .site-contact .box {
      width: auto; } }

.site-contact .privacy {
  margin: 40px 0 0;
  padding: 32px;
  background: #f4f4f4; }
  @media screen and (max-width: 768px) {
    .site-contact .privacy {
      margin: 24px 0 0;
      padding: 16px; } }
  .site-contact .privacy dt {
    font-size: 17px;
    font-weight: 700;
    margin: 0 0 4px; }
    @media screen and (max-width: 768px) {
      .site-contact .privacy dt {
        font-size: 15px; } }
  .site-contact .privacy dd {
    line-height: 1.66; }

.site-achievement .box {
  width: 88%;
  margin: 0 auto 64px; }
  @media screen and (max-width: 1119px) {
    .site-achievement .box {
      width: auto;
      margin: 0 auto 48px; } }
  @media screen and (max-width: 768px) {
    .site-achievement .box {
      margin: 0 auto 32px; } }
  .site-achievement .box:last-of-type {
    margin-bottom: 0; }

.site-achievement .achievement {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-achievement .achievement {
      display: block; } }
  .site-achievement .achievement--mini {
    justify-content: flex-start;
    margin: 0 0 0 -24px; }
    @media screen and (max-width: 1119px) {
      .site-achievement .achievement--mini {
        margin: 0 0 0 -20px; } }
    @media screen and (max-width: 768px) {
      .site-achievement .achievement--mini {
        margin: 0; } }
    .site-achievement .achievement--mini__minitem {
      width: 25%;
      padding: 0 0 0 24px;
      margin: 24px 0 0; }
      @media screen and (max-width: 1119px) {
        .site-achievement .achievement--mini__minitem {
          padding: 0 0 0 20px;
          margin: 20px 0 0; } }
      @media screen and (max-width: 768px) {
        .site-achievement .achievement--mini__minitem {
          width: auto;
          margin: 0 0 15px;
          padding: 0; }
          .site-achievement .achievement--mini__minitem:last-child {
            margin: 0; } }
      @media screen and (min-width: 769px) {
        .site-achievement .achievement--mini__minitem:nth-child(-n+4) {
          margin: 0; } }
      .site-achievement .achievement--mini__minitem a {
        background: #f1f1f1;
        border-radius: 5px;
        text-align: center;
        display: block;
        padding: 16px 0;
        font-size: 16px;
        font-weight: 700;
        color: #151515; }
        @media screen and (max-width: 1119px) {
          .site-achievement .achievement--mini__minitem a {
            padding: 14px 20px;
            font-size: 15px; } }
        .site-achievement .achievement--mini__minitem a:hover {
          background: #db1501;
          color: #FFF; }
  .site-achievement .achievement__item {
    list-style: none;
    width: calc(50% - 12px);
    margin: 24px 0 0;
    background: #f1f1f1;
    position: relative;
    padding: 40px 0 28px;
    border-radius: 5px; }
    @media screen and (max-width: 1119px) {
      .site-achievement .achievement__item {
        width: calc(50% - 10px);
        padding: 20px;
        padding-top: 28px; } }
    @media screen and (max-width: 768px) {
      .site-achievement .achievement__item {
        width: auto;
        margin: 0 0 15px; }
        .site-achievement .achievement__item:last-child {
          margin: 0; } }
    .site-achievement .achievement__item:nth-child(-n+2) {
      margin-top: 0; }
    .site-achievement .achievement__item__badge {
      position: absolute;
      top: 0;
      left: 0;
      background: #db1501;
      color: #FFF;
      font-weight: 700;
      padding: 2px 20px;
      font-size: 13px;
      border-radius: 5px 0 0 0;
      animation: flash 1200ms infinite alternate; }
      @media screen and (max-width: 1119px) {
        .site-achievement .achievement__item__badge {
          padding: 0 20px; } }
      @media screen and (max-width: 768px) {
        .site-achievement .achievement__item__badge {
          padding: 0 15px; } }

@keyframes flash {
  0% {
    opacity: 0; }
  70% {
    opacity: 1; }
  100% {
    opacity: 1; } }
    .site-achievement .achievement__item dl dt {
      font-weight: 700;
      text-align: center;
      font-size: 26px;
      line-height: 1; }
      @media screen and (max-width: 1119px) {
        .site-achievement .achievement__item dl dt {
          font-size: 24px; } }
      @media screen and (max-width: 768px) {
        .site-achievement .achievement__item dl dt {
          font-size: 22px; } }
    .site-achievement .achievement__item dl dd.btnarea {
      margin: 20px 0 0; }
      @media screen and (max-width: 768px) {
        .site-achievement .achievement__item dl dd.btnarea {
          margin: 15px 0 0; } }
      .site-achievement .achievement__item dl dd.btnarea a {
        padding: 6px 0;
        min-width: 220px;
        border-radius: 4px; }

.site-achievement-single.site-module h2.center {
  font-size: 32px;
  letter-spacing: 0em;
  margin: 0 0 24px; }
  @media screen and (max-width: 768px) {
    .site-achievement-single.site-module h2.center {
      font-size: 22px;
      margin: 0 0 16px; } }

.site-achievement-single .box {
  width: 92%;
  margin: 0 auto; }
  @media screen and (max-width: 1119px) {
    .site-achievement-single .box {
      width: auto; } }

.site-achievement-single .mb {
  margin-top: 64px; }
  @media screen and (max-width: 1119px) {
    .site-achievement-single .mb {
      margin-top: 48px; } }

.site-achievement-single .context {
  text-align: center;
  line-height: 1.56;
  letter-spacing: 0em;
  font-weight: 700;
  margin: 0 0 32px;
  font-size: 32px;
  color: #151515; }
  @media screen and (max-width: 768px) {
    .site-achievement-single .context {
      font-size: 22px;
      border-bottom: #db1501 6px solid;
      margin: 0 0 16px; } }
  .site-achievement-single .context strong {
    color: #db1501;
    display: inline-block;
    border-bottom: #db1501 6px solid; }
    @media screen and (max-width: 768px) {
      .site-achievement-single .context strong {
        border: none; } }

.site-achievement-single .result {
  border: #151515 6px solid;
  border-bottom: 0;
  border-right: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  justify-content: flex-start; }
  .site-achievement-single .result__badge {
    position: absolute;
    top: -24px;
    left: -24px;
    background: #db1501;
    padding: 6px 24px;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    border-radius: 3px; }
    @media screen and (max-width: 1119px) {
      .site-achievement-single .result__badge {
        font-size: 15px;
        padding: 4px 20px;
        top: -16px;
        left: -16px; } }
    @media screen and (max-width: 768px) {
      .site-achievement-single .result__badge {
        font-size: 13.5px;
        padding: 3px 15px;
        top: -14px;
        left: -14px; } }
    .site-achievement-single .result__badge:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: -24px;
      left: 50%;
      transform: translateX(-25%) skewX(34deg);
      border-style: solid;
      border-width: 24px 12px 0 12px;
      border-color: #b21101 transparent transparent transparent; }
      @media screen and (max-width: 1119px) {
        .site-achievement-single .result__badge:after {
          display: none; } }
  .site-achievement-single .result li {
    width: 33.3%;
    padding: 16px;
    border-right: #151515 6px solid;
    border-bottom: #151515 6px solid;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    position: relative; }
    @media screen and (max-width: 768px) {
      .site-achievement-single .result li {
        width: 50%;
        padding: 15px; } }
    .site-achievement-single .result li dl {
      font-weight: 700;
      text-align: center; }
      .site-achievement-single .result li dl dd {
        line-height: 1;
        font-size: 48px;
        color: #db1501;
        text-shadow: none; }
        @media screen and (max-width: 1119px) {
          .site-achievement-single .result li dl dd {
            font-size: 32px; } }
        @media screen and (max-width: 768px) {
          .site-achievement-single .result li dl dd {
            font-size: 24px; } }
        .site-achievement-single .result li dl dd strong {
          font-family: 'Oswald', sans-serif;
          font-size: 120px;
          display: inline-block; }
          @media screen and (max-width: 1119px) {
            .site-achievement-single .result li dl dd strong {
              font-size: 96px; } }
          @media screen and (max-width: 768px) {
            .site-achievement-single .result li dl dd strong {
              font-size: 60px; } }
      .site-achievement-single .result li dl dt {
        margin: 8px 0 0;
        font-size: 32px;
        line-height: 1.56; }
        @media screen and (max-width: 1119px) {
          .site-achievement-single .result li dl dt {
            font-size: 20px; } }
        @media screen and (max-width: 768px) {
          .site-achievement-single .result li dl dt {
            font-size: 17px;
            margin: 6px 0 0; } }

.site-achievement-single .mini {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 0 -20px; }
  @media screen and (max-width: 768px) {
    .site-achievement-single .mini {
      margin: 0 0 0 -15px; } }
  @media screen and (min-width: 1120px) {
    .site-achievement-single .mini li {
      width: 25%;
      padding: 0 0 0 20px;
      margin: 20px 0 0; }
      .site-achievement-single .mini li:nth-child(-n+4) {
        margin: 0; } }
  @media screen and (max-width: 1119px) {
    .site-achievement-single .mini li {
      width: 33.3%;
      padding: 0 0 0 20px;
      margin: 20px 0 0; } }
  @media screen and (max-width: 1119px) and (min-width: 769px) {
    .site-achievement-single .mini li:nth-child(-n+3) {
      margin-top: 0; } }
  @media screen and (max-width: 768px) {
    .site-achievement-single .mini li {
      width: 50%;
      padding: 0 0 0 15px;
      margin: 15px 0 0; }
      .site-achievement-single .mini li:nth-child(-n+2) {
        margin: 0; } }
  .site-achievement-single .mini li dl {
    border: #f1f1f1 6px solid;
    text-align: center;
    padding: 22px 16px 24px;
    font-weight: 700;
    line-height: 1;
    position: relative; }
    @media screen and (max-width: 768px) {
      .site-achievement-single .mini li dl {
        padding: 15px; } }
    .site-achievement-single .mini li dl dt {
      font-size: 22px;
      margin: 16px 0 2px; }
      @media screen and (max-width: 1119px) {
        .site-achievement-single .mini li dl dt {
          font-size: 20px; } }
      @media screen and (max-width: 768px) {
        .site-achievement-single .mini li dl dt {
          font-size: 17px; } }
    .site-achievement-single .mini li dl dd {
      font-size: 28px;
      color: #db1501;
      text-shadow: none; }
      @media screen and (max-width: 768px) {
        .site-achievement-single .mini li dl dd {
          font-size: 24px; } }
      .site-achievement-single .mini li dl dd strong {
        font-family: 'Oswald', sans-serif;
        font-size: 64px; }
        @media screen and (max-width: 768px) {
          .site-achievement-single .mini li dl dd strong {
            font-size: 56px; } }
      .site-achievement-single .mini li dl dd.small {
        color: #151515;
        font-weight: 500;
        font-size: 13px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) translateY(50%);
        background: #f1f1f1;
        padding: 6px 24px;
        font-weight: 700;
        white-space: nowrap; }
        @media screen and (max-width: 768px) {
          .site-achievement-single .mini li dl dd.small {
            padding: 5px 15px;
            font-size: 12px; } }

.site-news .list li {
  list-style: none;
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: #e8e8e8 1px solid; }
  @media screen and (max-width: 768px) {
    .site-news .list li {
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .site-news .list li > a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    color: #151515; }
  .site-news .list li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-news .list li .bg {
    width: 250px;
    height: 140px;
    background-size: cover;
    background-position: center; }
    @media screen and (max-width: 768px) {
      .site-news .list li .bg {
        width: 100px;
        height: 75px; } }
  .site-news .list li dl {
    width: calc(100% - 250px);
    padding: 0 0 0 24px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start; }
    @media screen and (max-width: 768px) {
      .site-news .list li dl {
        width: calc(100% - 100px);
        padding: 0 0 0 15px;
        display: block; } }
    .site-news .list li dl dt {
      font-size: 20px;
      font-weight: 700;
      width: calc(100% - 100px);
      padding: 0 0 0 20px; }
      @media screen and (max-width: 768px) {
        .site-news .list li dl dt {
          font-size: 15px;
          width: auto;
          padding: 4px 0 0; } }
    .site-news .list li dl dd.time {
      background: #db1501;
      color: #FFF;
      font-size: 12px;
      width: 100px;
      padding: 2px 0;
      text-align: center;
      transform: translateY(4px); }
      @media screen and (max-width: 768px) {
        .site-news .list li dl dd.time {
          font-size: 11px;
          transform: translateY(0); } }
    .site-news .list li dl dd.description {
      font-size: 13.5px;
      text-align: justify; }
      @media screen and (max-width: 768px) {
        .site-news .list li dl dd.description {
          display: none; } }

@media screen and (max-width: 1119px) {
  .site-news .minlist {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; } }

@media screen and (max-width: 768px) {
  .site-news .minlist {
    display: block; } }

.site-news .minlist li {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: #e8e8e8 1px solid; }
  @media screen and (max-width: 1119px) {
    .site-news .minlist li {
      width: calc(50% - 10px); } }
  @media screen and (max-width: 768px) {
    .site-news .minlist li {
      width: auto;
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .site-news .minlist li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-news .minlist li a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    color: #151515; }
    .site-news .minlist li a .bg {
      width: 90px;
      height: 60px;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 1119px) {
        .site-news .minlist li a .bg {
          width: 80px;
          height: 50px; } }
    .site-news .minlist li a dl {
      width: calc(100% - 90px);
      padding: 0 0 0 20px; }
      @media screen and (max-width: 1119px) {
        .site-news .minlist li a dl {
          width: calc(100% - 80px); } }
      .site-news .minlist li a dl dt {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.66;
        margin: 8px 0 0; }
        @media screen and (max-width: 1119px) {
          .site-news .minlist li a dl dt {
            font-size: 13.5px; } }
      .site-news .minlist li a dl dd.time {
        background: #db1501;
        color: #FFF;
        font-size: 11px;
        width: 88px;
        padding: 1px 0;
        text-align: center;
        transform: translateY(4px); }
      .site-news .minlist li a dl dd.description {
        font-size: 13.5px;
        text-align: justify; }

.site-news .details .articletitle {
  border-top: #db1501 6px solid;
  background: #f4f4f4;
  padding: 20px 24px;
  margin: 0 0 20px; }
  @media screen and (max-width: 768px) {
    .site-news .details .articletitle {
      padding: 15px;
      margin: 0 0 24px; } }
  @media screen and (max-width: 768px) {
    .site-news .details .articletitle {
      margin: 0 0 16px; } }
  .site-news .details .articletitle h2 {
    line-height: 1.56;
    font-size: 24px;
    font-weight: 700;
    margin: 8px 0 0; }
    @media screen and (max-width: 768px) {
      .site-news .details .articletitle h2 {
        font-size: 17px; } }
  .site-news .details .articletitle .date {
    font-weight: 700;
    color: #FFF;
    background: #db1501;
    padding: 1px 20px;
    font-size: 12px;
    display: inline-block; }

.site-news .details__edit {
  font-size: 15px;
  line-height: 1.8; }
  @media screen and (max-width: 1119px) {
    .site-news .details__edit {
      font-size: 14px; } }
  @media screen and (max-width: 768px) {
    .site-news .details__edit {
      font-size: 13.5px; } }
  .site-news .details__edit h3 {
    font-size: 24px;
    font-weight: 700;
    border-top: #151515 2px solid;
    border-bottom: #151515 2px solid;
    font-weight: 700;
    padding: 8px 2px;
    margin: 0 0 20px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h3 {
        font-size: 22px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h3 {
        font-size: 20px; } }
  .site-news .details__edit h4 {
    font-size: 22px;
    border-bottom: #e8e8e8 2px solid;
    font-weight: 700;
    padding: 0 0 0 16px;
    margin: 0 0 16px;
    position: relative; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h4 {
        font-size: 20px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h4 {
        font-size: 18px; } }
    .site-news .details__edit h4:before {
      content: '';
      width: 4px;
      height: 80%;
      position: absolute;
      top: 10%;
      left: 0;
      background: #db1501;
      border-radius: 2px; }
  .site-news .details__edit h5 {
    font-size: 20px;
    font-weight: 700;
    background: #f4f4f4;
    padding: 4px 15px;
    margin: 0 0 16px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h5 {
        font-size: 18px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h5 {
        font-size: 16px; } }
  .site-news .details__edit h6 {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 0 18px;
    margin: 0 0 8px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h6 {
        font-size: 17px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h6 {
        font-size: 15px; } }
    .site-news .details__edit h6:before {
      content: '';
      width: 12px;
      height: 12px;
      border: #db1501 4px solid;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
  .site-news .details__edit table {
    border: #e8e8e8 1px solid;
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit table {
        margin: 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit table {
        margin: 0 0 24px; } }
    .site-news .details__edit table tbody tr td, .site-news .details__edit table tbody tr th,
    .site-news .details__edit table thead tr td,
    .site-news .details__edit table thead tr th {
      padding: 15px;
      text-align: left;
      vertical-align: middle;
      border: #e8e8e8 1px solid; }
    .site-news .details__edit table tbody tr th,
    .site-news .details__edit table thead tr th {
      background: #f4f4f4; }
      @media screen and (max-width: 768px) {
        .site-news .details__edit table tbody tr th,
        .site-news .details__edit table thead tr th {
          min-width: 100px; } }
  .site-news .details__edit img {
    max-width: 100%; }
  .site-news .details__edit .avatar {
    width: 88px;
    margin: 0 15px; }
    @media screen and (max-width: 768px) {
      .site-news .details__edit .avatar {
        width: 64px;
        margin: 0; } }
    .site-news .details__edit .avatar img {
      width: 100%;
      border-radius: 50%; }
    .site-news .details__edit .avatar figcaption {
      text-align: center;
      margin: 15px 0 0;
      font-size: 14px; }
      @media screen and (max-width: 768px) {
        .site-news .details__edit .avatar figcaption {
          margin: 8px 0 0;
          font-size: 13px; } }
  .site-news .details__edit iframe {
    width: 100%;
    height: 300px;
    vertical-align: bottom;
    margin: 0 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit iframe {
        height: 250px;
        margin: 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit iframe {
        height: 180px;
        margin: 0 0 20px; } }
  .site-news .details__edit p {
    margin: 0 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit p {
        margin: 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit p {
        margin: 0 0 20px; } }

.site-math {
  margin: 76px 0 0; }
  @media screen and (max-width: 1119px) {
    .site-math {
      margin: 68px 0 0; } }
  .site-math .fv {
    background: url("../../../images/math/bg.jpg") no-repeat center;
    background-size: cover; }
    .site-math .fv > figure {
      width: 290px;
      margin: 0 auto; }
      @media screen and (max-width: 1119px) {
        .site-math .fv > figure {
          width: 220px; } }
      @media screen and (max-width: 768px) {
        .site-math .fv > figure {
          width: 200px; } }
      .site-math .fv > figure img {
        width: 100%; }
  .site-math .context {
    background: #003597;
    position: relative; }
    .site-math .context .display {
      z-index: 2; }
    .site-math .context__message {
      text-align: center;
      color: #FFF;
      font-size: 32px;
      font-weight: 700; }
      @media screen and (max-width: 1119px) {
        .site-math .context__message {
          font-size: 24px; } }
      @media screen and (max-width: 768px) {
        .site-math .context__message {
          font-size: 17px; } }
    .site-math .context canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
  .site-math .figs {
    position: relative; }
    .site-math .figs:before {
      content: '';
      width: 100%;
      height: 40%;
      background: #e5f6ff;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1; }
      @media screen and (max-width: 1119px) {
        .site-math .figs:before {
          height: 32%; } }
    .site-math .figs .display {
      z-index: 2; }
    .site-math .figs__flex {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 768px) {
        .site-math .figs__flex {
          display: block; } }
      .site-math .figs__flex__fig {
        width: 440px; }
        @media screen and (max-width: 1119px) {
          .site-math .figs__flex__fig {
            width: 360px; } }
        @media screen and (max-width: 768px) {
          .site-math .figs__flex__fig {
            width: 88%;
            margin: 0 auto 24px; } }
        .site-math .figs__flex__fig img {
          width: 100%; }
      .site-math .figs__flex__dl {
        width: calc(100% - 440px);
        padding: 0 0 0 56px; }
        @media screen and (max-width: 1119px) {
          .site-math .figs__flex__dl {
            width: calc(100% - 360px);
            padding: 0 0 0 32px; } }
        @media screen and (max-width: 768px) {
          .site-math .figs__flex__dl {
            width: auto;
            padding: 0; } }
        .site-math .figs__flex__dl dt {
          font-size: 38px;
          font-weight: 700;
          color: #003597;
          line-height: 1.56;
          margin: 0 0 18px;
          padding: 0 0 16px;
          border-bottom: #e8e8e8 1px solid; }
          @media screen and (max-width: 1119px) {
            .site-math .figs__flex__dl dt {
              font-size: 24px; } }
          @media screen and (max-width: 768px) {
            .site-math .figs__flex__dl dt {
              font-size: 20px; } }
        .site-math .figs__flex__dl dd {
          font-size: 15px;
          text-align: justify; }
          @media screen and (max-width: 1119px) {
            .site-math .figs__flex__dl dd {
              font-size: 14px; } }
          @media screen and (max-width: 768px) {
            .site-math .figs__flex__dl dd {
              font-size: 13.5px; } }
          .site-math .figs__flex__dl dd strong {
            color: #003597;
            border-bottom: #003597 2px solid; }
    .site-math .figs__description {
      margin: 48px 0 0;
      background: #FFF;
      padding: 32px 12%;
      padding-top: 40px;
      border: #e5f6ff 12px solid;
      box-shadow: 0 8px 32px rgba(0, 53, 151, 0.04); }
      @media screen and (max-width: 1119px) {
        .site-math .figs__description {
          padding: 32px 8%; } }
      @media screen and (max-width: 768px) {
        .site-math .figs__description {
          padding: 20px;
          margin: 20px 0 0;
          border-width: 8px; } }
      .site-math .figs__description dt {
        text-align: center;
        font-size: 32px;
        color: #003597;
        font-weight: 700;
        margin: 0 0 8px; }
        @media screen and (max-width: 1119px) {
          .site-math .figs__description dt {
            font-size: 24px; } }
        @media screen and (max-width: 768px) {
          .site-math .figs__description dt {
            font-size: 18px; } }
        .site-math .figs__description dt strong {
          display: inline-block; }
          .site-math .figs__description dt strong span {
            display: inline-block;
            position: relative; }
            .site-math .figs__description dt strong span:before {
              content: '';
              width: 9px;
              height: 9px;
              background: #fffbbc;
              border-radius: 50%;
              position: absolute;
              top: -12px;
              left: 50%;
              transform: translateX(-50%); }
              @media screen and (max-width: 768px) {
                .site-math .figs__description dt strong span:before {
                  width: 7px;
                  height: 7px;
                  top: -8px; } }
      .site-math .figs__description dd {
        font-size: 15px;
        text-align: justify; }
        @media screen and (max-width: 768px) {
          .site-math .figs__description dd {
            font-size: 13.5px; } }
        .site-math .figs__description dd strong {
          color: #003597;
          border-bottom: #003597 2px solid; }
        .site-math .figs__description dd.l {
          text-align: center;
          font-size: 24px;
          color: #db1501;
          font-weight: 700;
          margin: 12px 0 0; }
          @media screen and (max-width: 768px) {
            .site-math .figs__description dd.l {
              font-size: 20px; } }
  .site-math .clums {
    margin: 80px 0;
    position: relative; }
    @media screen and (max-width: 1119px) {
      .site-math .clums {
        margin: 32px 0; } }
    @media screen and (max-width: 768px) {
      .site-math .clums {
        margin: 0; } }
    .site-math .clums .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: url("../../../images/math/bg2.jpg") no-repeat center;
      background-size: cover; }
      @media screen and (max-width: 768px) {
        .site-math .clums .bg {
          position: relative;
          width: auto;
          height: 180px; } }
    .site-math .clums__data {
      margin: 0 0 0 50%;
      padding: 0 0 0 56px; }
      @media screen and (max-width: 1119px) {
        .site-math .clums__data {
          padding: 0 0 0 32px; } }
      @media screen and (max-width: 768px) {
        .site-math .clums__data {
          margin: 24px 0 0;
          padding: 0; } }
      .site-math .clums__data__description dt {
        font-size: 40px;
        font-weight: 700;
        line-height: 1.48;
        color: #003597; }
        @media screen and (max-width: 1119px) {
          .site-math .clums__data__description dt {
            font-size: 28px; } }
        @media screen and (max-width: 768px) {
          .site-math .clums__data__description dt {
            font-size: 20px;
            text-align: center; } }
      .site-math .clums__data__description dd {
        font-size: 15px;
        text-align: justify;
        margin: 16px 0 0; }
        @media screen and (max-width: 1119px) {
          .site-math .clums__data__description dd {
            font-size: 14px; } }
      .site-math .clums__data__teacher {
        margin: 24px 0 0;
        background: #e5f6ff;
        padding: 15px;
        border-radius: 4px; }
        @media screen and (max-width: 768px) {
          .site-math .clums__data__teacher {
            margin: 20px 0 0; } }
        .site-math .clums__data__teacher dt {
          font-size: 18px;
          font-weight: 700; }
          @media screen and (max-width: 768px) {
            .site-math .clums__data__teacher dt {
              font-size: 15px; } }
      .site-math .clums__data__mini {
        background: #fffbbc;
        padding: 15px;
        font-size: 13px;
        margin: 24px 0 0;
        border-radius: 4px; }
        @media screen and (max-width: 768px) {
          .site-math .clums__data__mini {
            margin: 20px 0 0; } }
  .site-math .voice {
    background: #e5f6ff; }
    .site-math .voice h2 {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      margin: 0 0 32px; }
      @media screen and (max-width: 1119px) {
        .site-math .voice h2 {
          font-size: 26px;
          margin: 0 0 24px; } }
      @media screen and (max-width: 768px) {
        .site-math .voice h2 {
          font-size: 20px;
          margin: 0 0 20px; } }
    .site-math .voice__list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      @media screen and (max-width: 768px) {
        .site-math .voice__list {
          display: block; } }
      .site-math .voice__list > li {
        width: calc(50% - 8px);
        background: #FFF;
        padding: 15px;
        font-size: 17px;
        border-radius: 999px;
        margin: 16px 0 0;
        box-shadow: 0 0 16px rgba(0, 53, 151, 0.04); }
        @media screen and (max-width: 1119px) {
          .site-math .voice__list > li {
            font-size: 15px; } }
        @media screen and (max-width: 768px) {
          .site-math .voice__list > li {
            font-size: 14px;
            width: auto;
            margin: 0 0 12px; }
            .site-math .voice__list > li:last-child {
              margin: 0; } }
        .site-math .voice__list > li:nth-child(-n+2) {
          margin-top: 0; }
        .site-math .voice__list > li i {
          color: #003597;
          transform: rotateY(180deg);
          display: inline-block;
          margin: 0 12px 0 0;
          font-size: 20px; }
          @media screen and (max-width: 768px) {
            .site-math .voice__list > li i {
              font-size: 16px;
              margin: 0 8px 0 0; } }
  .site-math .point h2 {
    font-size: 30px;
    line-height: 1.56;
    font-weight: 700;
    text-align: center;
    margin: 0 0 32px; }
    @media screen and (max-width: 1119px) {
      .site-math .point h2 {
        font-size: 24px;
        margin: 0 0 24px; } }
    @media screen and (max-width: 768px) {
      .site-math .point h2 {
        font-size: 18px;
        margin: 0 0 20px; } }
  .site-math .point__list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-math .point__list {
        display: block; } }
    .site-math .point__list > li {
      width: calc(50% - 12px);
      margin: 24px 0 0; }
      @media screen and (max-width: 768px) {
        .site-math .point__list > li {
          width: auto;
          margin: 0 0 12px; }
          .site-math .point__list > li:last-child {
            margin: 0; } }
      .site-math .point__list > li:nth-child(-n+2) {
        margin-top: 0; }
      .site-math .point__list > li dl dt {
        background: #003597;
        text-align: center;
        color: #FFF;
        font-weight: 700;
        font-size: 18px;
        padding: 10px 15px; }
        @media screen and (max-width: 1119px) {
          .site-math .point__list > li dl dt {
            font-size: 15px; } }
      .site-math .point__list > li dl dd {
        background: #e5f6ff;
        padding: 15px;
        text-align: center;
        font-size: 20px; }
        @media screen and (max-width: 1119px) {
          .site-math .point__list > li dl dd {
            font-size: 15px; } }
        .site-math .point__list > li dl dd strong {
          color: #003597;
          border-bottom: #003597 3px solid; }
  .site-math .point__str {
    margin: 56px 0 0;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: #003597;
    line-height: 2; }
    @media screen and (max-width: 1119px) {
      .site-math .point__str {
        margin: 24px 0 0;
        font-size: 24px; } }
    @media screen and (max-width: 768px) {
      .site-math .point__str {
        font-size: 16px;
        text-align: left; } }
    .site-math .point__str strong {
      border-bottom: #003597 6px solid; }
  .site-math .point__badge {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    padding: 0 12%;
    margin: 32px 0 0; }
    @media screen and (max-width: 1119px) {
      .site-math .point__badge {
        padding: 0 8%; } }
    @media screen and (max-width: 768px) {
      .site-math .point__badge {
        display: block;
        padding: 0; } }
    .site-math .point__badge > li {
      width: 50%; }
      @media screen and (max-width: 768px) {
        .site-math .point__badge > li {
          width: 88%;
          margin: 0 auto 8px; }
          .site-math .point__badge > li:last-child {
            margin: 0; } }
      .site-math .point__badge > li img {
        width: 100%; }
  .site-math .bnrs {
    background: #003597; }
    .site-math .bnrs__area {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      @media screen and (max-width: 768px) {
        .site-math .bnrs__area {
          display: block; } }
      .site-math .bnrs__area li {
        width: 48.5%;
        margin: 0 0 40px; }
        @media screen and (max-width: 768px) {
          .site-math .bnrs__area li {
            width: auto;
            margin: 0 0 15px; }
            .site-math .bnrs__area li:last-child {
              margin: 0; } }
        .site-math .bnrs__area li.large {
          width: 100%;
          margin: 0; }
        .site-math .bnrs__area li img {
          width: 100%; }
    .site-math .bnrs .btnarea a {
      background: #FFF;
      border: #FFF 1px solid;
      color: #003597; }
      .site-math .bnrs .btnarea a:hover {
        color: #FFF;
        background: none; }

.site-recruit h2 {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.04em;
  text-align: center;
  font-size: 32px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 24px; }
  @media screen and (max-width: 1119px) {
    .site-recruit h2 {
      font-size: 28px; } }
  @media screen and (max-width: 768px) {
    .site-recruit h2 {
      font-size: 22px;
      margin: 0 0 16px; } }

.site-recruit .context dt {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 20px; }
  @media screen and (max-width: 768px) {
    .site-recruit .context dt {
      font-size: 17px;
      margin: 0 0 16px; } }

.site-recruit .context dd {
  width: 840px;
  margin: 0 auto 12px;
  font-size: 15px; }
  @media screen and (max-width: 1119px) {
    .site-recruit .context dd {
      width: auto;
      padding: 0 20px; } }
  @media screen and (max-width: 768px) {
    .site-recruit .context dd {
      padding: 0;
      font-size: 13.5px;
      text-align: justify; } }
  .site-recruit .context dd:last-of-type {
    margin-bottom: 0; }

.site-recruit .listable {
  background: #f4f4f4; }
  .site-recruit .listable__list__item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: #FFF;
    padding: 20px;
    margin: 0 0 32px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.04); }
    @media screen and (max-width: 1119px) {
      .site-recruit .listable__list__item {
        align-items: flex-start; } }
    @media screen and (max-width: 768px) {
      .site-recruit .listable__list__item {
        display: block;
        padding: 15px;
        margin: 0 0 15px; } }
    .site-recruit .listable__list__item:last-child {
      margin: 0; }
    .site-recruit .listable__list__item--rvs > figure {
      order: 2; }
    .site-recruit .listable__list__item--rvs > dl {
      order: 1;
      padding: 40px;
      padding-right: 60px; }
      @media screen and (max-width: 1119px) {
        .site-recruit .listable__list__item--rvs > dl {
          padding: 0;
          padding-right: 32px; } }
      @media screen and (max-width: 768px) {
        .site-recruit .listable__list__item--rvs > dl {
          padding-right: 0; } }
    .site-recruit .listable__list__item__pic {
      width: 40%;
      order: 1; }
      @media screen and (max-width: 768px) {
        .site-recruit .listable__list__item__pic {
          width: auto; } }
      .site-recruit .listable__list__item__pic img {
        width: 100%; }
    .site-recruit .listable__list__item__data {
      order: 2;
      width: 60%;
      padding: 40px;
      padding-left: 60px; }
      @media screen and (max-width: 1119px) {
        .site-recruit .listable__list__item__data {
          padding: 0;
          padding-left: 32px; } }
      @media screen and (max-width: 768px) {
        .site-recruit .listable__list__item__data {
          width: auto;
          padding: 0;
          margin: 20px 0 0; } }
      .site-recruit .listable__list__item__data dt {
        font-size: 26px;
        font-weight: 700;
        padding: 0 0 0 16px;
        position: relative;
        line-height: 1.2; }
        @media screen and (max-width: 1119px) {
          .site-recruit .listable__list__item__data dt {
            font-size: 20px;
            line-height: 1.5; } }
        @media screen and (max-width: 768px) {
          .site-recruit .listable__list__item__data dt {
            font-size: 17px; } }
        .site-recruit .listable__list__item__data dt:before {
          content: '';
          width: 4px;
          height: 88%;
          border-radius: 4px;
          position: absolute;
          top: 6%;
          left: 0;
          background: #db1501; }
      .site-recruit .listable__list__item__data dd {
        margin: 10px 0 0;
        padding: 18px 0 0;
        border-top: #151515 2px solid;
        font-size: 15px;
        text-align: justify; }
        @media screen and (max-width: 1119px) {
          .site-recruit .listable__list__item__data dd {
            font-size: 14px;
            padding: 15px 0 0; } }
        @media screen and (max-width: 768px) {
          .site-recruit .listable__list__item__data dd {
            font-size: 13.5px; } }
  .site-recruit .listable .btnarea {
    margin: 48px 0 0; }
    @media screen and (max-width: 1119px) {
      .site-recruit .listable .btnarea {
        margin: 40px 0 0; } }
    @media screen and (max-width: 768px) {
      .site-recruit .listable .btnarea {
        margin: 20px 0 0; } }

.site-recruit .message {
  position: relative; }
  .site-recruit .message.wrap {
    padding-left: 0;
    padding-right: 0;
    overflow: visible; }
  .site-recruit .message:before {
    content: '';
    width: 1px;
    height: 96px;
    background: #151515;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-50%); }
    @media screen and (max-width: 1119px) {
      .site-recruit .message:before {
        height: 48px; } }
    @media screen and (max-width: 768px) {
      .site-recruit .message:before {
        display: none; } }
  .site-recruit .message__p {
    width: 840px;
    font-size: 15px;
    margin: 0 auto 24px;
    text-align: justify; }
    @media screen and (max-width: 1119px) {
      .site-recruit .message__p {
        width: auto;
        padding: 0 20px; } }
    @media screen and (max-width: 768px) {
      .site-recruit .message__p {
        font-size: 13.5px;
        padding: 0 15px; } }

.site-recruit #recruit-swipe {
  padding: 32px 0 0; }
  @media screen and (max-width: 1119px) {
    .site-recruit #recruit-swipe {
      padding: 20px 0 0; } }
  @media screen and (max-width: 768px) {
    .site-recruit #recruit-swipe {
      padding: 0 15px; } }
  .site-recruit #recruit-swipe .swiper-slide {
    width: 600px;
    height: 380px;
    position: relative; }
    @media screen and (max-width: 1119px) {
      .site-recruit #recruit-swipe .swiper-slide {
        width: 500px;
        height: 300px; } }
    @media screen and (max-width: 768px) {
      .site-recruit #recruit-swipe .swiper-slide {
        width: 280px;
        height: 240px; } }
    .site-recruit #recruit-swipe .swiper-slide .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center; }

.site-guide .box {
  width: 88%;
  margin: 0 auto; }
  @media screen and (max-width: 1119px) {
    .site-guide .box {
      width: auto; } }

@media screen and (max-width: 768px) {
  .site-guide .responsive-flow {
    width: 100%;
    overflow: auto; }
    .site-guide .responsive-flow:before {
      content: '▼ 横にスクロールしてご覧ください';
      display: block;
      margin: 0 0 8px; } }

.site-guide .responsive-flow table.table-responsive {
  width: 100%;
  border-collapse: collapse; }
  @media screen and (max-width: 768px) {
    .site-guide .responsive-flow table.table-responsive {
      width: 150%; } }
  .site-guide .responsive-flow table.table-responsive thead tr th, .site-guide .responsive-flow table.table-responsive thead tr td,
  .site-guide .responsive-flow table.table-responsive tbody tr th,
  .site-guide .responsive-flow table.table-responsive tbody tr td {
    font-size: 17px;
    border: #e8e8e8 2px solid;
    width: 20%;
    padding: 15px;
    text-align: left;
    vertical-align: middle; }
    @media screen and (max-width: 768px) {
      .site-guide .responsive-flow table.table-responsive thead tr th, .site-guide .responsive-flow table.table-responsive thead tr td,
      .site-guide .responsive-flow table.table-responsive tbody tr th,
      .site-guide .responsive-flow table.table-responsive tbody tr td {
        font-size: 14px;
        width: auto; } }
  .site-guide .responsive-flow table.table-responsive thead tr:nth-child(2n+2),
  .site-guide .responsive-flow table.table-responsive tbody tr:nth-child(2n+2) {
    background: #f9f9f9; }
  .site-guide .responsive-flow table.table-responsive thead tr {
    background: #db1501; }
    .site-guide .responsive-flow table.table-responsive thead tr th, .site-guide .responsive-flow table.table-responsive thead tr td {
      color: #FFF;
      font-weight: 700;
      border: none;
      border-right: #bd1201 2px solid; }
      .site-guide .responsive-flow table.table-responsive thead tr th:last-of-type, .site-guide .responsive-flow table.table-responsive thead tr td:last-of-type {
        border: none; }
  .site-guide .responsive-flow table.table-responsive tbody tr:nth-child(1) th, .site-guide .responsive-flow table.table-responsive tbody tr:nth-child(1) td {
    border-top: none; }
  .site-guide .responsive-flow table.table-responsive tbody tr:hover {
    color: #db1501;
    font-weight: 700; }
    .site-guide .responsive-flow table.table-responsive tbody tr:hover th, .site-guide .responsive-flow table.table-responsive tbody tr:hover td {
      border-bottom-color: #db1501; }

.site-guide p.alert {
  margin: 24px 0 0;
  font-size: 13px; }

.site-kamatori.before .fv {
  margin: 76px 0 0;
  background: url("../../../images/kamatori/fv.jpg") no-repeat center/cover;
  padding: 48px 0;
  border-bottom: #db1501 12px solid; }
  @media screen and (max-width: 1119px) {
    .site-kamatori.before .fv {
      margin: 68px 0 0; } }
  @media screen and (max-width: 768px) {
    .site-kamatori.before .fv {
      padding: 32px 0;
      border-bottom-width: 8px; } }
  .site-kamatori.before .fv__logo {
    width: 640px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .site-kamatori.before .fv__logo {
        width: 80%; } }
    .site-kamatori.before .fv__logo img {
      width: 100%; }

.site-kamatori.before .content.wrap {
  padding-top: 48px;
  padding-bottom: 48px; }
  @media screen and (max-width: 1119px) {
    .site-kamatori.before .content.wrap {
      padding-top: 32px;
      padding-bottom: 32px; } }
  @media screen and (max-width: 768px) {
    .site-kamatori.before .content.wrap {
      padding-top: 24px;
      padding-bottom: 24px; } }

.site-kamatori.before .content .ttl {
  text-align: center;
  padding: 40px 0;
  background: #FFF;
  position: relative;
  border-radius: 6px; }
  @media screen and (max-width: 1119px) {
    .site-kamatori.before .content .ttl {
      padding: 32px; } }
  @media screen and (max-width: 768px) {
    .site-kamatori.before .content .ttl {
      padding: 24px;
      padding-top: 32px; } }
  .site-kamatori.before .content .ttl__date {
    text-align: center;
    font-size: 48px;
    line-height: 1;
    font-weight: 700;
    display: inline-block; }
    @media screen and (max-width: 768px) {
      .site-kamatori.before .content .ttl__date {
        font-size: 20px; } }
    .site-kamatori.before .content .ttl__date strong {
      font-family: 'Oswald', sans-serif;
      font-size: 56px;
      color: #db1501; }
      @media screen and (max-width: 768px) {
        .site-kamatori.before .content .ttl__date strong {
          font-size: 28px; } }
  .site-kamatori.before .content .ttl .alt {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    text-align: center;
    margin: 24px 0 0; }
    @media screen and (max-width: 768px) {
      .site-kamatori.before .content .ttl .alt {
        font-size: 14px;
        margin: 16px 0 0; } }
  .site-kamatori.before .content .ttl .btnarea {
    margin-top: 28px; }
    @media screen and (max-width: 768px) {
      .site-kamatori.before .content .ttl .btnarea {
        margin-top: 12px; } }
    .site-kamatori.before .content .ttl .btnarea a {
      font-size: 20px;
      font-weight: 700;
      border-radius: 999px;
      border: #db1501 2px solid;
      box-shadow: 0 0 24px rgba(219, 21, 1, 0.32); }
      @media screen and (max-width: 768px) {
        .site-kamatori.before .content .ttl .btnarea a {
          font-size: 17px;
          border-radius: 4px; } }
      .site-kamatori.before .content .ttl .btnarea a:hover {
        background: #FFF;
        color: #db1501; }
      .site-kamatori.before .content .ttl .btnarea a i {
        display: inline-block;
        margin: 0 6px 0 0; }

.site-kamatori.before .catch {
  width: 480px;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .site-kamatori.before .catch {
      width: 80%; } }
  .site-kamatori.before .catch img {
    width: 100%; }

.site-kamatori.before .clum {
  margin: 32px 0 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 1119px) {
    .site-kamatori.before .clum {
      display: block; } }
  @media screen and (max-width: 768px) {
    .site-kamatori.before .clum {
      margin: 24px 0 0; } }
  .site-kamatori.before .clum__photo {
    width: calc(100% - 480px); }
    @media screen and (max-width: 1119px) {
      .site-kamatori.before .clum__photo {
        width: auto; } }
    .site-kamatori.before .clum__photo img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right center; }
      @media screen and (max-width: 1119px) {
        .site-kamatori.before .clum__photo img {
          height: auto; } }
  .site-kamatori.before .clum__data {
    width: 480px;
    font-size: 14.5px;
    text-align: justify;
    padding: 0 0 0 40px;
    position: relative; }
    @media screen and (max-width: 1119px) {
      .site-kamatori.before .clum__data {
        width: auto;
        padding: 40px 40px 0; } }
    @media screen and (max-width: 768px) {
      .site-kamatori.before .clum__data {
        padding: 8px 15px 0;
        font-size: 14px; } }
    .site-kamatori.before .clum__data__chara {
      position: absolute;
      right: 0;
      top: 0;
      width: 100px;
      transform: translateY(-50%); }
      @media screen and (max-width: 1119px) {
        .site-kamatori.before .clum__data__chara {
          top: 32px;
          right: 40px; } }
      @media screen and (max-width: 768px) {
        .site-kamatori.before .clum__data__chara {
          width: 56px;
          top: 32px;
          right: 8px; } }
      .site-kamatori.before .clum__data__chara img {
        width: 100%; }
    .site-kamatori.before .clum__data dl {
      padding: 16px 0; }
      .site-kamatori.before .clum__data dl dt {
        font-size: 26px;
        font-weight: 700;
        line-height: 1.5;
        color: #023894; }
        @media screen and (max-width: 768px) {
          .site-kamatori.before .clum__data dl dt {
            font-size: 20px;
            padding: 0 64px 0 0; } }
      .site-kamatori.before .clum__data dl dd {
        margin: 12px 0 0; }

.site-kamatori.after figure.title {
  width: 440px;
  margin: 0 auto 48px; }
  @media screen and (max-width: 1119px) {
    .site-kamatori.after figure.title {
      width: 360px;
      margin: 0 auto 32px; } }
  @media screen and (max-width: 768px) {
    .site-kamatori.after figure.title {
      width: 240px;
      margin: 0 auto 24px; } }
  .site-kamatori.after figure.title img {
    width: 100%; }

.site-kamatori.after .clum {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 0 4vw; }
  @media screen and (max-width: 768px) {
    .site-kamatori.after .clum {
      display: block;
      padding: 0; } }
  .site-kamatori.after .clum:before {
    content: '';
    background: #f4f4f4;
    position: absolute;
    width: 92%;
    height: calc(100% - 4vw);
    top: 4vw;
    right: -40px; }
    @media screen and (max-width: 1119px) {
      .site-kamatori.after .clum:before {
        right: -20px; } }
    @media screen and (max-width: 768px) {
      .site-kamatori.after .clum:before {
        display: none; } }
  .site-kamatori.after .clum:after {
    content: '';
    border: #FFF 4px solid;
    border-right: 0;
    position: absolute;
    width: calc(50% - 4vw);
    height: calc(100% - 16vw);
    bottom: 4vw;
    right: -40px; }
    @media screen and (max-width: 1119px) {
      .site-kamatori.after .clum:after {
        right: -20px; } }
    @media screen and (max-width: 768px) {
      .site-kamatori.after .clum:after {
        display: none; } }
  .site-kamatori.after .clum__pic {
    position: absolute;
    top: 0;
    left: -40px;
    width: 80%;
    height: calc(100% - 8vw);
    overflow: hidden; }
    @media screen and (max-width: 1119px) {
      .site-kamatori.after .clum__pic {
        left: -20px; } }
    @media screen and (max-width: 768px) {
      .site-kamatori.after .clum__pic {
        position: relative;
        left: 0;
        width: auto;
        height: 56vw; } }
    .site-kamatori.after .clum__pic img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
      @media screen and (max-width: 768px) {
        .site-kamatori.after .clum__pic img {
          transform: translate(0) !important; } }
  .site-kamatori.after .clum__data {
    padding: 8vw 0;
    margin: 0 0 0 50%;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .site-kamatori.after .clum__data {
        padding: 0;
        margin: 0; } }
    .site-kamatori.after .clum__data__box {
      background: #FFF;
      padding: 48px;
      padding-top: 56px;
      min-width: 540px;
      max-width: 640px;
      box-shadow: 0 0 64px rgba(21, 21, 21, 0.08);
      border-radius: 1px;
      position: relative; }
      @media screen and (max-width: 1119px) {
        .site-kamatori.after .clum__data__box {
          margin: 0 20px 0 0;
          min-width: 1px;
          max-width: 100%; } }
      @media screen and (max-width: 768px) {
        .site-kamatori.after .clum__data__box {
          margin: 24px 0 0;
          box-shadow: none;
          padding: 0 15px;
          transform: translate(0) !important; } }
      .site-kamatori.after .clum__data__box:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 20px 0 0;
        border-color: #023894 transparent transparent transparent;
        position: absolute;
        top: 8px;
        left: 8px; }
        @media screen and (max-width: 768px) {
          .site-kamatori.after .clum__data__box:before {
            display: none; } }
      .site-kamatori.after .clum__data__box dt {
        font-size: 28px;
        font-weight: 700;
        color: #023894;
        line-height: 1.5; }
        @media screen and (max-width: 1119px) {
          .site-kamatori.after .clum__data__box dt {
            font-size: 24px; } }
        @media screen and (max-width: 768px) {
          .site-kamatori.after .clum__data__box dt {
            font-size: 20px; } }
      .site-kamatori.after .clum__data__box dd {
        text-align: justify;
        font-size: 15px;
        margin: 16px 0 0; }
        @media screen and (max-width: 1119px) {
          .site-kamatori.after .clum__data__box dd {
            font-size: 14px; } }
        .site-kamatori.after .clum__data__box dd.pic {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-50%, -50%);
          width: 80px; }
          @media screen and (max-width: 1119px) {
            .site-kamatori.after .clum__data__box dd.pic {
              width: 56px; } }
          @media screen and (max-width: 768px) {
            .site-kamatori.after .clum__data__box dd.pic {
              right: -15px;
              width: 56px;
              top: -48px; } }
          .site-kamatori.after .clum__data__box dd.pic img {
            width: 100%; }

.site-kamatori.after .bottom {
  margin: 4vw 0 0;
  padding: 4vw 0;
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-kamatori.after .bottom {
      margin: 32px 0 0;
      padding: 0; } }
  .site-kamatori.after .bottom:before {
    content: '';
    background: #f4f4f4;
    position: absolute;
    width: 92%;
    height: 100%;
    top: 0;
    left: -40px; }
    @media screen and (max-width: 1119px) {
      .site-kamatori.after .bottom:before {
        left: -20px; } }
    @media screen and (max-width: 768px) {
      .site-kamatori.after .bottom:before {
        display: none; } }
  .site-kamatori.after .bottom .figbnr {
    padding: 24px;
    background: #FFF;
    border: #023894 4px solid;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start;
    border-radius: 3px;
    box-shadow: 0 0 64px rgba(21, 21, 21, 0.08); }
    @media screen and (max-width: 768px) {
      .site-kamatori.after .bottom .figbnr {
        padding: 0;
        border: none;
        justify-content: space-between; } }
    .site-kamatori.after .bottom .figbnr figure {
      width: 240px;
      margin: 0 20px 0 0; }
      @media screen and (max-width: 1119px) {
        .site-kamatori.after .bottom .figbnr figure {
          width: 180px; } }
      @media screen and (max-width: 768px) {
        .site-kamatori.after .bottom .figbnr figure {
          width: calc(50% - 8px);
          margin: 0; } }
      .site-kamatori.after .bottom .figbnr figure:last-of-type {
        margin: 0; }
      .site-kamatori.after .bottom .figbnr figure img {
        width: 100%; }
    .site-kamatori.after .bottom .figbnr__data {
      width: calc(100% - 480px - 20px);
      padding: 0 24px 0 48px; }
      @media screen and (max-width: 1119px) {
        .site-kamatori.after .bottom .figbnr__data {
          width: calc(100% - 360px - 20px);
          padding-left: 24px; } }
      @media screen and (max-width: 768px) {
        .site-kamatori.after .bottom .figbnr__data {
          padding: 15px;
          border: #023894 2px solid;
          border-radius: 3px;
          width: 100%;
          margin: 15px 0 0; } }
      .site-kamatori.after .bottom .figbnr__data h2 {
        color: #023894;
        font-weight: 700;
        font-size: 38px;
        line-height: 1.3;
        margin: 0 0 16px;
        padding: 0 0 10px;
        position: relative; }
        @media screen and (max-width: 1119px) {
          .site-kamatori.after .bottom .figbnr__data h2 {
            font-size: 24px; } }
        @media screen and (max-width: 768px) {
          .site-kamatori.after .bottom .figbnr__data h2 {
            font-size: 18px; } }
        .site-kamatori.after .bottom .figbnr__data h2:before, .site-kamatori.after .bottom .figbnr__data h2:after {
          content: '';
          width: 100%;
          height: 6px;
          background: #023894;
          position: absolute;
          left: 0;
          bottom: 0; }
          @media screen and (max-width: 768px) {
            .site-kamatori.after .bottom .figbnr__data h2:before, .site-kamatori.after .bottom .figbnr__data h2:after {
              height: 4px; } }
        .site-kamatori.after .bottom .figbnr__data h2:after {
          background: #db1501;
          width: 18%; }
      .site-kamatori.after .bottom .figbnr__data__list {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        justify-content: flex-start;
        margin: 0 0 12px; }
        @media screen and (max-width: 768px) {
          .site-kamatori.after .bottom .figbnr__data__list {
            display: block; } }
        .site-kamatori.after .bottom .figbnr__data__list dl {
          width: 32%;
          border: #023894 2px solid;
          text-align: center; }
          @media screen and (max-width: 768px) {
            .site-kamatori.after .bottom .figbnr__data__list dl {
              width: auto;
              margin: 0 0 8px; }
              .site-kamatori.after .bottom .figbnr__data__list dl:last-of-type {
                margin: 0; } }
          .site-kamatori.after .bottom .figbnr__data__list dl.ws {
            width: 24%;
            margin: 0 2% 0 0; }
            @media screen and (max-width: 768px) {
              .site-kamatori.after .bottom .figbnr__data__list dl.ws {
                width: auto;
                margin-bottom: 8px;
                margin-right: 0; } }
          .site-kamatori.after .bottom .figbnr__data__list dl.wl {
            width: 48%; }
            @media screen and (max-width: 768px) {
              .site-kamatori.after .bottom .figbnr__data__list dl.wl {
                width: auto; } }
          .site-kamatori.after .bottom .figbnr__data__list dl dt {
            background: #023894;
            color: #FFF;
            font-weight: 700;
            padding: 6px 15px; }
          .site-kamatori.after .bottom .figbnr__data__list dl dd {
            color: #023894;
            font-size: 15px;
            padding: 6px 15px;
            font-weight: 700; }
      .site-kamatori.after .bottom .figbnr__data p {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.6;
        color: #023894; }
        @media screen and (max-width: 1119px) {
          .site-kamatori.after .bottom .figbnr__data p {
            font-size: 14px; } }
        @media screen and (max-width: 768px) {
          .site-kamatori.after .bottom .figbnr__data p {
            text-align: center; } }
  .site-kamatori.after .bottom .btnbox {
    margin: 24px 0 0;
    padding: 24px;
    background: #023894;
    border-radius: 3px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 64px rgba(21, 21, 21, 0.08); }
    @media screen and (max-width: 768px) {
      .site-kamatori.after .bottom .btnbox {
        padding: 15px; } }
    .site-kamatori.after .bottom .btnbox a {
      width: 300px;
      display: inline-block;
      padding: 12px 0;
      border-radius: 3px;
      text-align: center;
      color: #023894;
      background: #FFF;
      font-weight: 700;
      font-size: 18px;
      margin: 0 8px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      transition: transform 80ms; }
      @media screen and (max-width: 768px) {
        .site-kamatori.after .bottom .btnbox a {
          display: block;
          width: 100%;
          margin: 0 0 8px; }
          .site-kamatori.after .bottom .btnbox a:last-of-type {
            margin: 0; } }
      .site-kamatori.after .bottom .btnbox a i {
        display: inline-block;
        transform: rotateY(180deg);
        margin: 0 8px 0 0; }
      .site-kamatori.after .bottom .btnbox a:hover {
        transform: scale(1.04); }

.site-kamatori.before .fv.mobara {
  background: url("../../../images/mobara/fv.jpg") no-repeat center/cover;
  padding: 72px 0; }
  @media screen and (max-width: 1119px) {
    .site-kamatori.before .fv.mobara {
      padding: 48px 0; } }
  @media screen and (max-width: 768px) {
    .site-kamatori.before .fv.mobara {
      padding: 32px 0; } }
  .site-kamatori.before .fv.mobara .fv__logo {
    width: 760px; }
    @media screen and (max-width: 1119px) {
      .site-kamatori.before .fv.mobara .fv__logo {
        width: 72%; } }
    @media screen and (max-width: 768px) {
      .site-kamatori.before .fv.mobara .fv__logo {
        width: 80%; } }

.site-kamatori .mobara-title {
  text-align: center;
  font-size: 3.2rem;
  line-height: 1;
  font-weight: 700;
  color: #023894;
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .site-kamatori .mobara-title {
      margin: 0 15px 32px; } }
  .site-kamatori .mobara-title img {
    max-width: 100%;
    width: 520px; }

.site-kamatori .mobara-midtitle {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 700;
  color: #023894;
  border: #023894 2px solid;
  border-radius: 3px;
  padding: 32px 0;
  margin: 0 0 32px;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .site-kamatori .mobara-midtitle {
      padding: 24px 16px;
      font-size: 1.32rem;
      margin: 24px 0 0; } }

.site-kamatori .btnarea.shadow a {
  font-size: 20px;
  font-weight: 700;
  border-radius: 999px;
  border: #db1501 2px solid;
  box-shadow: 0 0 24px rgba(219, 21, 1, 0.32); }
  @media screen and (max-width: 768px) {
    .site-kamatori .btnarea.shadow a {
      font-size: 17px;
      border-radius: 4px; } }
  .site-kamatori .btnarea.shadow a:hover {
    background: #FFF;
    color: #db1501; }
  .site-kamatori .btnarea.shadow a i {
    display: inline-block;
    margin: 0 6px 0 0; }

.site-kamatori .harfbg {
  position: relative;
  overflow: visible !important; }
  .site-kamatori .harfbg:before {
    content: '';
    width: 100%;
    height: 64%;
    background: #f4f4f4;
    position: absolute;
    left: 0;
    bottom: 0; }
    @media screen and (max-width: 768px) {
      .site-kamatori .harfbg:before {
        height: 25%; } }

.site-kamatori .openinfo {
  padding: 0 0 16px; }
  .site-kamatori .openinfo__context {
    text-align: center;
    font-size: 1.12rem;
    margin: 32px 0; }
    @media screen and (max-width: 768px) {
      .site-kamatori .openinfo__context {
        font-size: 1rem;
        text-align: justify;
        padding: 0 15px; } }
  .site-kamatori .openinfo__list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 1119px) {
      .site-kamatori .openinfo__list {
        display: block; } }
    .site-kamatori .openinfo__list li {
      width: calc(50% - 12px);
      padding: 40px;
      border-radius: 6px;
      position: relative;
      overflow: hidden;
      position: relative;
      z-index: 2; }
      @media screen and (max-width: 1119px) {
        .site-kamatori .openinfo__list li {
          width: 100%;
          margin: 0 0 40px; }
          .site-kamatori .openinfo__list li:last-child {
            margin-bottom: 0; } }
      @media screen and (max-width: 768px) {
        .site-kamatori .openinfo__list li {
          padding: 20px;
          margin: 0 0 12px; } }
      .site-kamatori .openinfo__list li:after {
        content: '';
        width: 80px;
        height: 80px;
        background: #f6cc25;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%) rotate(45deg); }
        @media screen and (max-width: 768px) {
          .site-kamatori .openinfo__list li:after {
            width: 56px;
            height: 56px; } }
      .site-kamatori .openinfo__list li:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 6px;
        background: #FFF;
        border: #f4f4f4 4px solid;
        z-index: -1; }
      .site-kamatori .openinfo__list li .clum {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        margin: 0; }
      .site-kamatori .openinfo__list li figure {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: #023894;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        position: relative; }
        @media screen and (max-width: 768px) {
          .site-kamatori .openinfo__list li figure {
            width: 56px;
            height: 56px; } }
        .site-kamatori .openinfo__list li figure img {
          width: 50%; }
      .site-kamatori .openinfo__list li h3 {
        font-size: 2.56rem;
        line-height: 1.5;
        font-weight: 700;
        width: calc(100% - 80px);
        padding-left: 32px;
        color: #023894; }
        @media screen and (max-width: 768px) {
          .site-kamatori .openinfo__list li h3 {
            font-size: 1.4rem;
            width: calc(100% - 56px);
            padding-left: 20px; } }
        .site-kamatori .openinfo__list li h3:before {
          content: attr(data-jp);
          display: block;
          font-size: 1.32rem;
          margin: 0 0 0 2px; }
      .site-kamatori .openinfo__list li .txt {
        font-size: 1.04rem;
        margin: 20px 0 0;
        padding: 20px 0 0;
        border-top: #e8e8e8 1px solid; }
        .site-kamatori .openinfo__list li .txt strong {
          color: #db1501; }

.site-kamatori .descarea,
.site-kamatori .contactarea,
.site-kamatori .guidearea,
.site-kamatori .steparea {
  margin: 32px 0 0;
  background: #FFF;
  padding: 40px;
  border-radius: 6px; }
  @media screen and (max-width: 768px) {
    .site-kamatori .descarea,
    .site-kamatori .contactarea,
    .site-kamatori .guidearea,
    .site-kamatori .steparea {
      padding: 16px;
      margin: 24px 0 0; } }
  .site-kamatori .descarea .mobara-title,
  .site-kamatori .contactarea .mobara-title,
  .site-kamatori .guidearea .mobara-title,
  .site-kamatori .steparea .mobara-title {
    font-size: 1.56rem;
    background: #023894;
    color: #FFF;
    text-align: center;
    padding: 20px 0;
    margin: 0 0 24px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .site-kamatori .descarea .mobara-title,
      .site-kamatori .contactarea .mobara-title,
      .site-kamatori .guidearea .mobara-title,
      .site-kamatori .steparea .mobara-title {
        padding: 12px 0;
        font-size: 1.32rem; } }
    .site-kamatori .descarea .mobara-title:before, .site-kamatori .descarea .mobara-title:after,
    .site-kamatori .contactarea .mobara-title:before,
    .site-kamatori .contactarea .mobara-title:after,
    .site-kamatori .guidearea .mobara-title:before,
    .site-kamatori .guidearea .mobara-title:after,
    .site-kamatori .steparea .mobara-title:before,
    .site-kamatori .steparea .mobara-title:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 12px 0 0;
      border-color: #f6cc25 transparent transparent transparent;
      position: absolute;
      top: 4px;
      left: 4px; }
      @media screen and (max-width: 768px) {
        .site-kamatori .descarea .mobara-title:before, .site-kamatori .descarea .mobara-title:after,
        .site-kamatori .contactarea .mobara-title:before,
        .site-kamatori .contactarea .mobara-title:after,
        .site-kamatori .guidearea .mobara-title:before,
        .site-kamatori .guidearea .mobara-title:after,
        .site-kamatori .steparea .mobara-title:before,
        .site-kamatori .steparea .mobara-title:after {
          border-width: 6px 6px 0 0; } }
    .site-kamatori .descarea .mobara-title:after,
    .site-kamatori .contactarea .mobara-title:after,
    .site-kamatori .guidearea .mobara-title:after,
    .site-kamatori .steparea .mobara-title:after {
      top: auto;
      left: auto;
      bottom: 4px;
      right: 4px;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent #f6cc25 transparent; }
      @media screen and (max-width: 768px) {
        .site-kamatori .descarea .mobara-title:after,
        .site-kamatori .contactarea .mobara-title:after,
        .site-kamatori .guidearea .mobara-title:after,
        .site-kamatori .steparea .mobara-title:after {
          border-width: 0 0 6px 6px; } }
  .site-kamatori .descarea__clum,
  .site-kamatori .contactarea__clum,
  .site-kamatori .guidearea__clum,
  .site-kamatori .steparea__clum {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .site-kamatori .descarea__clum,
      .site-kamatori .contactarea__clum,
      .site-kamatori .guidearea__clum,
      .site-kamatori .steparea__clum {
        display: block; } }
    .site-kamatori .descarea__clum dl,
    .site-kamatori .contactarea__clum dl,
    .site-kamatori .guidearea__clum dl,
    .site-kamatori .steparea__clum dl {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 768px) {
        .site-kamatori .descarea__clum dl,
        .site-kamatori .contactarea__clum dl,
        .site-kamatori .guidearea__clum dl,
        .site-kamatori .steparea__clum dl {
          display: block; } }
      .site-kamatori .descarea__clum dl dt,
      .site-kamatori .contactarea__clum dl dt,
      .site-kamatori .guidearea__clum dl dt,
      .site-kamatori .steparea__clum dl dt {
        background: #eaeaea;
        width: 200px;
        text-align: center;
        font-weight: 700;
        font-size: 1.24rem;
        padding: 8px 0; }
        @media screen and (max-width: 768px) {
          .site-kamatori .descarea__clum dl dt,
          .site-kamatori .contactarea__clum dl dt,
          .site-kamatori .guidearea__clum dl dt,
          .site-kamatori .steparea__clum dl dt {
            width: auto; } }
      .site-kamatori .descarea__clum dl dd,
      .site-kamatori .contactarea__clum dl dd,
      .site-kamatori .guidearea__clum dl dd,
      .site-kamatori .steparea__clum dl dd {
        width: calc(100% - 200px);
        font-size: 1.32rem;
        padding: 0 0 0 20px; }
        @media screen and (max-width: 768px) {
          .site-kamatori .descarea__clum dl dd,
          .site-kamatori .contactarea__clum dl dd,
          .site-kamatori .guidearea__clum dl dd,
          .site-kamatori .steparea__clum dl dd {
            width: auto;
            padding: 16px 0 0;
            font-size: 1.12rem; } }
  .site-kamatori .descarea__txt,
  .site-kamatori .contactarea__txt,
  .site-kamatori .guidearea__txt,
  .site-kamatori .steparea__txt {
    margin: 24px 0 0;
    font-size: 1.04rem;
    padding: 40px;
    border: #f4f4f4 4px solid; }
    @media screen and (max-width: 768px) {
      .site-kamatori .descarea__txt,
      .site-kamatori .contactarea__txt,
      .site-kamatori .guidearea__txt,
      .site-kamatori .steparea__txt {
        padding: 16px; } }

.site-kamatori .contactarea .mobara-title {
  margin-bottom: 0; }

.site-kamatori .contactarea__list li {
  padding: 32px 0;
  border-bottom: #e8e8e8 1px solid;
  padding-left: 48px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-kamatori .contactarea__list li {
      padding-left: 32px; } }
  .site-kamatori .contactarea__list li:nth-child(1):before {
    content: "1"; }
  .site-kamatori .contactarea__list li:nth-child(2):before {
    content: "2"; }
  .site-kamatori .contactarea__list li:nth-child(3):before {
    content: "3"; }
  .site-kamatori .contactarea__list li:before {
    content: '1';
    position: absolute;
    top: 50%;
    left: 0;
    background: #023894;
    color: #FFF;
    width: 32px;
    height: 32px;
    border-radius: 999px;
    transform: translateY(-50%);
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .site-kamatori .contactarea__list li:before {
        transform: translate(0, 0);
        top: 34px;
        width: 24px;
        height: 24px; } }
  .site-kamatori .contactarea__list li dl {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 200px 0 0; }
    @media screen and (max-width: 1119px) {
      .site-kamatori .contactarea__list li dl {
        display: block;
        padding: 0; } }
    .site-kamatori .contactarea__list li dl dt {
      width: 360px;
      font-weight: 700;
      font-size: 1.24rem;
      font-weight: 700; }
      @media screen and (max-width: 1119px) {
        .site-kamatori .contactarea__list li dl dt {
          width: auto; } }
      @media screen and (max-width: 768px) {
        .site-kamatori .contactarea__list li dl dt {
          font-size: 1.16rem; } }
    .site-kamatori .contactarea__list li dl dd {
      width: calc(100% - 360px);
      font-size: 1.08rem; }
      .site-kamatori .contactarea__list li dl dd .sp {
        display: none; }
        @media screen and (max-width: 768px) {
          .site-kamatori .contactarea__list li dl dd .sp {
            display: inline; } }
      @media screen and (max-width: 1119px) {
        .site-kamatori .contactarea__list li dl dd {
          width: auto;
          margin: 16px 0 0;
          font-size: 1rem; } }
      .site-kamatori .contactarea__list li dl dd.btnarea {
        width: auto;
        position: absolute;
        top: 50%;
        right: 0;
        margin: 0;
        transform: translateY(-50%); }
        @media screen and (max-width: 768px) {
          .site-kamatori .contactarea__list li dl dd.btnarea {
            position: relative;
            top: auto;
            right: auto;
            transform: translate(0, 0);
            margin: 16px 0 0; } }
        .site-kamatori .contactarea__list li dl dd.btnarea a {
          font-size: 1.08rem;
          padding: 8px 24px;
          border-radius: 3px;
          min-width: 1px; }

.site-kamatori .guidearea .tablestyle {
  margin: 24px 0; }
  @media screen and (max-width: 768px) {
    .site-kamatori .guidearea .tablestyle {
      border-bottom: none; } }

.site-kamatori .guidearea .context {
  font-size: 1.08rem;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .site-kamatori .guidearea .context {
      font-size: 1rem;
      text-align: left; }
      .site-kamatori .guidearea .context br {
        display: none; } }

.site-kamatori .steparea__list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 1119px) {
    .site-kamatori .steparea__list {
      display: block; } }
  .site-kamatori .steparea__list li {
    width: calc(20% - 12px);
    padding: 24px;
    padding-top: 40px;
    border: #023894 1px solid;
    border-radius: 4px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    position: relative; }
    @media screen and (max-width: 1119px) {
      .site-kamatori .steparea__list li {
        width: auto;
        padding-top: 24px;
        margin: 0 0 24px; } }
    @media screen and (max-width: 768px) {
      .site-kamatori .steparea__list li {
        padding-top: 32px; } }
    .site-kamatori .steparea__list li:nth-child(1):before {
      content: "STEP.1"; }
    .site-kamatori .steparea__list li:nth-child(2):before {
      content: "STEP.2"; }
    .site-kamatori .steparea__list li:nth-child(3):before {
      content: "STEP.3"; }
    .site-kamatori .steparea__list li:nth-child(4):before {
      content: "STEP.4"; }
    .site-kamatori .steparea__list li:nth-child(5):before {
      content: "STEP.5"; }
    .site-kamatori .steparea__list li:before {
      content: 'STEP';
      font-size: .88rem;
      position: absolute;
      top: 0;
      left: 0;
      padding: 2px 16px;
      padding-right: 20px;
      color: #FFF;
      background: #023894;
      border-radius: 0 0 999px 0; }
      @media screen and (max-width: 768px) {
        .site-kamatori .steparea__list li:before {
          padding: 2px 12px;
          font-size: .8rem;
          border-radius: 0 0 8px 0; } }
    .site-kamatori .steparea__list li:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 0 12px 12px;
      border-color: transparent transparent transparent #023894;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(100%, -50%); }
      @media screen and (max-width: 1119px) {
        .site-kamatori .steparea__list li:after {
          border-width: 12px 12px 0 12px;
          border-color: #023894 transparent transparent transparent;
          top: auto;
          right: auto;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 100%); } }
    .site-kamatori .steparea__list li:last-child:after {
      display: none; }
    .site-kamatori .steparea__list li .txt {
      font-size: 1.16rem;
      line-height: 1.56;
      font-weight: 700;
      color: #023894; }

.site-kamatori .steparea .desc {
  border: #f4f4f4 4px solid;
  padding: 24px;
  margin: 32px 0 0;
  border-radius: 4px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  position: relative; }
  @media screen and (max-width: 1119px) {
    .site-kamatori .steparea .desc {
      display: block; } }
  @media screen and (max-width: 768px) {
    .site-kamatori .steparea .desc {
      padding: 0 8px;
      border: none; } }
  .site-kamatori .steparea .desc dt {
    width: 280px;
    font-weight: 700;
    font-size: 1.32rem;
    text-align: center;
    padding-right: 32px; }
    @media screen and (max-width: 1119px) {
      .site-kamatori .steparea .desc dt {
        width: auto;
        padding: 0;
        font-size: 1.64rem; } }
  .site-kamatori .steparea .desc dd {
    font-size: 1.08rem;
    width: calc(100% - 280px);
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 1119px) {
      .site-kamatori .steparea .desc dd {
        width: auto;
        margin: 16px 0 0; } }
    @media screen and (max-width: 768px) {
      .site-kamatori .steparea .desc dd {
        display: block;
        font-size: 1rem; } }
    .site-kamatori .steparea .desc dd .txtbox {
      width: calc(100% - 300px);
      padding: 0 32px 0 0; }
      @media screen and (max-width: 768px) {
        .site-kamatori .steparea .desc dd .txtbox {
          width: auto;
          padding: 0 0 12px; } }
      .site-kamatori .steparea .desc dd .txtbox .info {
        margin: 0 0 24px;
        padding: 0 0 24px;
        border-bottom: #e8e8e8 1px solid; }
    .site-kamatori .steparea .desc dd > figure {
      width: 300px; }
      @media screen and (max-width: 768px) {
        .site-kamatori .steparea .desc dd > figure {
          width: auto;
          margin: 16px 0 0; } }
      .site-kamatori .steparea .desc dd > figure img {
        width: 100%; }

.site-e-tore .e-fv {
  background: #fcfcfc url("../../../images/e-tore/fv_bg.jpg") center no-repeat;
  margin: 76px 0 0;
  border-bottom: rgba(0, 0, 0, 0.04) 1px solid; }
  @media screen and (max-width: 1119px) {
    .site-e-tore .e-fv {
      margin: 68px 0 0; } }
  @media screen and (max-width: 768px) {
    .site-e-tore .e-fv {
      background-size: cover; } }
  .site-e-tore .e-fv .display {
    height: 310px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start; }
    @media screen and (max-width: 1119px) {
      .site-e-tore .e-fv .display {
        height: 280px; } }
    @media screen and (max-width: 768px) {
      .site-e-tore .e-fv .display {
        height: 200px; } }
  .site-e-tore .e-fv__logo {
    margin-left: 4%; }
    @media screen and (max-width: 1119px) {
      .site-e-tore .e-fv__logo {
        margin-left: 6%; } }
    @media screen and (max-width: 768px) {
      .site-e-tore .e-fv__logo {
        margin-left: 15px; }
        .site-e-tore .e-fv__logo img {
          width: 180px; } }
    .site-e-tore .e-fv__logo figcaption {
      font-size: 28px;
      font-weight: 700;
      line-height: 1;
      margin: 16px 0 0; }
      @media screen and (max-width: 1119px) {
        .site-e-tore .e-fv__logo figcaption {
          font-size: 20px; } }
      @media screen and (max-width: 768px) {
        .site-e-tore .e-fv__logo figcaption {
          font-size: 17px; } }

.site-e-tore .first {
  position: relative; }
  .site-e-tore .first.wrap {
    padding-bottom: 56px; }
    @media screen and (max-width: 1119px) {
      .site-e-tore .first.wrap {
        padding-bottom: 32px; } }
  .site-e-tore .first:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    background: #f4f4f4 url("../../../images/e-tore/black_dotto.png") repeat;
    background-size: 16px;
    width: 100%;
    height: 136px; }
    @media screen and (max-width: 768px) {
      .site-e-tore .first:before {
        height: 32%; } }
  .site-e-tore .first dl.context {
    font-weight: 700;
    text-align: center;
    line-height: 1.56; }
    .site-e-tore .first dl.context dt {
      font-size: 15px;
      margin: 0 0 16px; }
      @media screen and (max-width: 768px) {
        .site-e-tore .first dl.context dt {
          font-size: 14px; } }
    .site-e-tore .first dl.context dd {
      font-size: 24px; }
      @media screen and (max-width: 768px) {
        .site-e-tore .first dl.context dd {
          font-size: 18px; }
          .site-e-tore .first dl.context dd br {
            display: none; } }
      .site-e-tore .first dl.context dd strong {
        color: #009225; }
  .site-e-tore .first h1.catch {
    text-align: center;
    color: #FFF;
    font-size: 32px;
    font-weight: 700;
    background: #009225 url("../../../images/e-tore/white_dotto.png") repeat;
    background-size: 16px;
    border-radius: 8px;
    margin: 24px 0 0;
    padding: 24px;
    line-height: 1.56;
    position: relative; }
    @media screen and (max-width: 1119px) {
      .site-e-tore .first h1.catch {
        padding: 20px;
        font-size: 26px; } }
    @media screen and (max-width: 768px) {
      .site-e-tore .first h1.catch {
        padding: 15px;
        font-size: 17px;
        margin: 15px 0 0; } }
    .site-e-tore .first h1.catch:before, .site-e-tore .first h1.catch:after {
      content: '';
      width: 125px;
      height: 126px;
      background: url("../../../images/e-tore/arrow_yel.png") no-repeat;
      background-size: 125px;
      position: absolute;
      top: -80px;
      left: 10%; }
      @media screen and (max-width: 1119px) {
        .site-e-tore .first h1.catch:before, .site-e-tore .first h1.catch:after {
          width: 62px;
          height: 63px;
          background-size: 62px;
          top: -32px;
          left: 5%; } }
      @media screen and (max-width: 768px) {
        .site-e-tore .first h1.catch:before, .site-e-tore .first h1.catch:after {
          width: 32px;
          height: 33px;
          background-size: 32px;
          top: -15px;
          left: 15px; } }
    .site-e-tore .first h1.catch:after {
      left: auto;
      right: 10%;
      transform: rotateY(-180deg); }
      @media screen and (max-width: 1119px) {
        .site-e-tore .first h1.catch:after {
          right: 5%; } }
      @media screen and (max-width: 768px) {
        .site-e-tore .first h1.catch:after {
          right: 15px; } }

.site-e-tore .second {
  background: #f4f4f4 url("../../../images/e-tore/black_dotto.png") repeat;
  background-size: 16px; }
  .site-e-tore .second .whitebox {
    background: #FFF;
    padding: 40px;
    border-radius: 8px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 1119px) {
      .site-e-tore .second .whitebox {
        padding: 20px;
        display: block; } }
    @media screen and (max-width: 768px) {
      .site-e-tore .second .whitebox {
        padding: 15px; } }
    .site-e-tore .second .whitebox figure {
      width: 440px; }
      @media screen and (max-width: 1119px) {
        .site-e-tore .second .whitebox figure {
          width: auto;
          margin: 0 0 24px; } }
      .site-e-tore .second .whitebox figure img {
        width: 100%; }
    .site-e-tore .second .whitebox dl {
      width: calc(100% - 440px);
      padding: 0 0 0 48px; }
      @media screen and (max-width: 1119px) {
        .site-e-tore .second .whitebox dl {
          width: auto;
          padding: 0; } }
      .site-e-tore .second .whitebox dl dt {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 0em;
        margin: 0 0 16px;
        padding: 0 0 12px;
        border-bottom: #e8e8e8 2px solid;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        justify-content: flex-start;
        align-items: center; }
        @media screen and (max-width: 1119px) {
          .site-e-tore .second .whitebox dl dt {
            font-size: 24px; } }
        @media screen and (max-width: 768px) {
          .site-e-tore .second .whitebox dl dt {
            font-size: 17px; } }
        .site-e-tore .second .whitebox dl dt:before {
          content: 'POINT';
          color: #FFF;
          background: #009225;
          font-size: 16px;
          padding: 4px 16px;
          margin: 0 16px 0 0;
          border-radius: 3px;
          display: inline-block; }
          @media screen and (max-width: 1119px) {
            .site-e-tore .second .whitebox dl dt:before {
              padding: 3px 15px; } }
          @media screen and (max-width: 768px) {
            .site-e-tore .second .whitebox dl dt:before {
              font-size: 13px;
              padding: 3px 8px;
              margin: 0 12px 0 0; } }
      .site-e-tore .second .whitebox dl dd {
        font-size: 15px;
        text-align: justify;
        margin: 16px 0 0; }
        @media screen and (max-width: 1119px) {
          .site-e-tore .second .whitebox dl dd {
            font-size: 14px; } }
        @media screen and (max-width: 768px) {
          .site-e-tore .second .whitebox dl dd {
            font-size: 13.5px; } }
  .site-e-tore .second .btnarea {
    margin-top: 48px; }
    @media screen and (max-width: 1119px) {
      .site-e-tore .second .btnarea {
        margin-top: 32px; } }
    .site-e-tore .second .btnarea a {
      background: #009225;
      border: #009225 2px solid;
      border-radius: 4px;
      font-size: 18px; }
      .site-e-tore .second .btnarea a:hover {
        color: #009225;
        background: #FFF; }

.site-myquest .fv {
  margin: 76px 0 0;
  background: url("../../../images/myquest/fv_bg.jpg") no-repeat center bottom;
  background-size: cover;
  height: 460px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  position: relative; }
  @media screen and (max-width: 1119px) {
    .site-myquest .fv {
      margin: 68px 0 0;
      height: 380px; } }
  @media screen and (max-width: 768px) {
    .site-myquest .fv {
      height: 300px; } }
  .site-myquest .fv figure {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 1119px) {
      .site-myquest .fv figure {
        width: 380px; } }
    @media screen and (max-width: 768px) {
      .site-myquest .fv figure {
        width: 280px; } }
    .site-myquest .fv figure img {
      width: 275px;
      transform: translateX(15%); }
      @media screen and (max-width: 1119px) {
        .site-myquest .fv figure img {
          width: 240px; } }
      @media screen and (max-width: 768px) {
        .site-myquest .fv figure img {
          width: 200px; } }
    .site-myquest .fv figure figcaption {
      background: #FFF;
      border-radius: 999px;
      color: #0e3db5;
      margin: 16px 0 0;
      font-weight: 700;
      font-size: 22px; }
      @media screen and (max-width: 1119px) {
        .site-myquest .fv figure figcaption {
          font-size: 20px; } }
      @media screen and (max-width: 768px) {
        .site-myquest .fv figure figcaption {
          font-size: 16px; } }

.site-myquest .first {
  border-top: #ffe400 20px solid;
  background: #0e3db5 url("../../../images/myquest/cross_dotto.png") repeat; }
  @media screen and (max-width: 768px) {
    .site-myquest .first {
      border-width: 8px; } }
  .site-myquest .first.wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
    @media screen and (max-width: 1119px) {
      .site-myquest .first.wrap {
        padding: 32px 0; } }
    @media screen and (max-width: 768px) {
      .site-myquest .first.wrap {
        padding: 24px 15px; } }
  .site-myquest .first dl.context {
    text-align: center;
    color: #FFF; }
    .site-myquest .first dl.context dt {
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 8px; }
      @media screen and (max-width: 1119px) {
        .site-myquest .first dl.context dt {
          font-size: 20px; } }
      @media screen and (max-width: 768px) {
        .site-myquest .first dl.context dt {
          font-size: 17px; } }
      .site-myquest .first dl.context dt strong {
        color: #ffe400;
        font-size: 36px;
        line-height: 1;
        display: inline-block;
        margin: 0 8px 0 0; }
        @media screen and (max-width: 1119px) {
          .site-myquest .first dl.context dt strong {
            font-size: 28px; } }
        @media screen and (max-width: 768px) {
          .site-myquest .first dl.context dt strong {
            font-size: 22px; } }
    .site-myquest .first dl.context dd {
      font-size: 17px; }
      @media screen and (max-width: 1119px) {
        .site-myquest .first dl.context dd {
          font-size: 15px; } }
      @media screen and (max-width: 768px) {
        .site-myquest .first dl.context dd {
          font-size: 14px;
          text-align: justify; }
          .site-myquest .first dl.context dd br {
            display: none; } }

.site-myquest .second {
  position: relative; }
  .site-myquest .second.wrap {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 140px; }
    @media screen and (max-width: 1119px) {
      .site-myquest .second.wrap {
        padding-bottom: 64px; } }
    @media screen and (max-width: 768px) {
      .site-myquest .second.wrap {
        padding-top: 0; } }
  .site-myquest .second:before {
    content: '';
    background-image: url("../../../images/myquest/wave_bg.png");
    width: 100%;
    height: 60px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-repeat: repeat-x;
    background-size: 2000px;
    animation: waves 3000ms infinite; }

@keyframes waves {
  0% {
    background-position: 0; }
  100% {
    background-position: 2000px; } }
    @media screen and (max-width: 1119px) {
      .site-myquest .second:before {
        height: 30px;
        background-size: 1000px; }
      @keyframes waves {
        0% {
          background-position: 0; }
        100% {
          background-position: 1000px; } } }
  .site-myquest .second .twoclum {
    position: relative; }
    .site-myquest .second .twoclum .bg {
      background-size: cover;
      background-position: center;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 16px 16px 0; }
      @media screen and (max-width: 768px) {
        .site-myquest .second .twoclum .bg {
          width: auto;
          position: relative;
          border-radius: 0;
          height: 200px; } }
    .site-myquest .second .twoclum .context {
      padding: 40px;
      padding-left: 56px;
      margin: 0 0 0 50%; }
      @media screen and (max-width: 1119px) {
        .site-myquest .second .twoclum .context {
          padding: 20px;
          padding-left: 32px; } }
      @media screen and (max-width: 768px) {
        .site-myquest .second .twoclum .context {
          padding: 20px 15px 0;
          margin: 0; } }
      .site-myquest .second .twoclum .context dt {
        font-size: 33px;
        font-weight: 700;
        line-height: 1.6;
        color: #0e3db5;
        margin: 0 0 20px;
        padding: 0 0 16px;
        border-bottom: #0e3db5 4px solid;
        letter-spacing: 0em; }
        @media screen and (max-width: 1119px) {
          .site-myquest .second .twoclum .context dt {
            font-size: 24px; } }
        @media screen and (max-width: 768px) {
          .site-myquest .second .twoclum .context dt {
            font-size: 22px; } }
        .site-myquest .second .twoclum .context dt strong span {
          position: relative;
          display: inline-block; }
          .site-myquest .second .twoclum .context dt strong span:before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: -4px;
            background: #ffe400;
            transform: translateX(-50%); }
      .site-myquest .second .twoclum .context dd {
        font-size: 16px;
        text-align: justify; }
        @media screen and (max-width: 1119px) {
          .site-myquest .second .twoclum .context dd {
            font-size: 13.5px; } }
    .site-myquest .second .twoclum .circle {
      position: absolute;
      top: -26px;
      right: 12px;
      width: 140px;
      height: 140px;
      z-index: -1; }
      @media screen and (max-width: 1119px) {
        .site-myquest .second .twoclum .circle {
          display: none; } }
      .site-myquest .second .twoclum .circle img {
        width: 100%; }

.site-myquest .third {
  background: #e4f5ff; }
  .site-myquest .third h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    color: #0e3db5;
    line-height: 1;
    font-weight: 700;
    margin: 0 0 48px;
    text-align: center; }
    @media screen and (max-width: 1119px) {
      .site-myquest .third h2 {
        font-size: 28px;
        margin: 0 0 40px; } }
    @media screen and (max-width: 768px) {
      .site-myquest .third h2 {
        font-size: 24px;
        line-height: 1.4;
        margin: 0 0 32px; } }
    .site-myquest .third h2 strong {
      display: inline-block;
      border-bottom: #ffe400 8px solid; }
  .site-myquest .third .three {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-myquest .third .three {
        display: block; } }
    .site-myquest .third .three li {
      width: 31.5%;
      background: #FFF;
      padding: 24px;
      border-radius: 16px;
      box-shadow: 0 0 16px rgba(14, 61, 181, 0.08);
      position: relative; }
      @media screen and (max-width: 1119px) {
        .site-myquest .third .three li {
          width: 32%;
          padding: 20px; } }
      @media screen and (max-width: 768px) {
        .site-myquest .third .three li {
          width: auto;
          margin: 0 0 30px;
          padding: 15px; }
          .site-myquest .third .three li:last-child {
            margin: 0; } }
      .site-myquest .third .three li:before {
        content: '';
        width: 120px;
        height: 30px;
        background: #023894;
        opacity: .07;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%); }
      .site-myquest .third .three li figure {
        width: 100px;
        margin: 16px auto 0; }
        @media screen and (max-width: 1119px) {
          .site-myquest .third .three li figure {
            width: 80px; } }
        .site-myquest .third .three li figure img {
          width: 100%; }
      .site-myquest .third .three li dl {
        margin: 24px 0 0; }
        .site-myquest .third .three li dl dt {
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          color: #0e3db5;
          margin: 0 0 4px; }
          @media screen and (max-width: 1119px) {
            .site-myquest .third .three li dl dt {
              font-size: 18px; } }
        .site-myquest .third .three li dl dd {
          text-align: justify; }

.site-myquest .four .sidebox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  margin: 48px 0 0;
  position: relative; }
  @media screen and (max-width: 1119px) {
    .site-myquest .four .sidebox {
      display: block;
      margin: 32px 0 0; } }
  .site-myquest .four .sidebox:first-of-type {
    margin: 0; }
  .site-myquest .four .sidebox .scroll-img:before {
    background: #f4f4f4; }
  .site-myquest .four .sidebox._active > dl {
    opacity: 1;
    transform: translateY(0); }
  .site-myquest .four .sidebox:after {
    content: '';
    width: calc(100% - 550px - 48px);
    height: 1px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #e8e8e8; }
    @media screen and (max-width: 1119px) {
      .site-myquest .four .sidebox:after {
        display: none; } }
  .site-myquest .four .sidebox.rvs:after {
    right: auto;
    left: 0; }
  .site-myquest .four .sidebox.rvs figure {
    order: 2; }
  .site-myquest .four .sidebox.rvs dl {
    margin: 0; }
  .site-myquest .four .sidebox__pic {
    width: 550px; }
    @media screen and (max-width: 1119px) {
      .site-myquest .four .sidebox__pic {
        width: 100%; } }
    .site-myquest .four .sidebox__pic img {
      width: 100%; }
  .site-myquest .four .sidebox__txt {
    width: calc(100% - 550px - 48px);
    padding: 32px 0 32px 112px;
    opacity: 0;
    transform: translateY(32px);
    position: relative;
    -webkit-transition: all 400ms 400ms ease-in-out;
    -moz-transition: all 400ms 400ms ease-in-out;
    -ms-transition: all 400ms 400ms ease-in-out;
    -o-transition: all 400ms 400ms ease-in-out;
    transition: all 400ms 400ms ease-in-out; }
    @media screen and (max-width: 1119px) {
      .site-myquest .four .sidebox__txt {
        width: auto; } }
    @media screen and (max-width: 768px) {
      .site-myquest .four .sidebox__txt {
        transform: translateY(0);
        padding: 0; } }
    .site-myquest .four .sidebox__txt dt {
      font-weight: 700;
      font-size: 55px;
      color: #0e3db5;
      position: absolute;
      padding: 16px 8px;
      border: #e8e8e8 2px solid;
      top: 32px;
      left: 0;
      line-height: 1;
      letter-spacing: .12em; }
      @media screen and (min-width: 769px) {
        .site-myquest .four .sidebox__txt dt {
          -webkit-writing-mode: vertical-rl;
          -moz-writing-mode: vertical-rl;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          white-space: nowrap; } }
      @media screen and (max-width: 768px) {
        .site-myquest .four .sidebox__txt dt {
          position: relative;
          top: 0;
          padding: 8px 15px;
          text-align: center;
          font-size: 24px;
          margin: 20px 0 15px; } }
    .site-myquest .four .sidebox__txt dd {
      margin: 16px 0 0;
      position: relative;
      padding: 0 0 0 130px;
      text-align: justify; }
      @media screen and (max-width: 768px) {
        .site-myquest .four .sidebox__txt dd {
          padding: 0; } }
      .site-myquest .four .sidebox__txt dd:first-of-type {
        margin: 0; }
      .site-myquest .four .sidebox__txt dd:before {
        content: attr(data-txt);
        color: #FFF;
        text-align: center;
        font-weight: 700;
        font-size: 13px;
        width: 110px;
        position: absolute;
        top: 2px;
        left: 0;
        border-radius: 2px; }
        @media screen and (max-width: 768px) {
          .site-myquest .four .sidebox__txt dd:before {
            position: relative;
            top: 0;
            display: block;
            margin: 0 0 12px; } }
    .site-myquest .four .sidebox__txt.green dt {
      color: #22ac38;
      border-color: #22ac38; }
    .site-myquest .four .sidebox__txt.green dd:before {
      background: #22ac38; }
    .site-myquest .four .sidebox__txt.orange dt {
      color: #eb6f00;
      border-color: #eb6f00; }
    .site-myquest .four .sidebox__txt.orange dd:before {
      background: #eb6f00; }
    .site-myquest .four .sidebox__txt.red dt {
      color: #e60012;
      border-color: #e60012; }
    .site-myquest .four .sidebox__txt.red dd:before {
      background: #e60012; }

@media screen and (max-width: 768px) {
  .site-myquest .fifth .schedule {
    width: 100%;
    overflow: auto; }
    .site-myquest .fifth .schedule:before {
      content: '▼ スクロールしてご覧ください';
      display: block;
      margin: 0 0 8px; } }

.site-myquest .fifth .schedule__table {
  border: #e8e8e8 1px solid;
  border-collapse: collapse;
  width: 100%; }
  @media screen and (max-width: 768px) {
    .site-myquest .fifth .schedule__table {
      width: 150%; } }
  .site-myquest .fifth .schedule__table thead tr th, .site-myquest .fifth .schedule__table thead tr td,
  .site-myquest .fifth .schedule__table tbody tr th,
  .site-myquest .fifth .schedule__table tbody tr td {
    border: #e8e8e8 1px solid;
    text-align: center;
    vertical-align: middle;
    font-size: 17px;
    padding: 16px; }
    @media screen and (max-width: 768px) {
      .site-myquest .fifth .schedule__table thead tr th, .site-myquest .fifth .schedule__table thead tr td,
      .site-myquest .fifth .schedule__table tbody tr th,
      .site-myquest .fifth .schedule__table tbody tr td {
        font-size: 14px;
        padding: 15px; } }
  .site-myquest .fifth .schedule__table thead {
    background: #0e3db5; }
    .site-myquest .fifth .schedule__table thead tr td {
      border-color: #0d37a2;
      color: #FFF;
      font-weight: 700; }
  .site-myquest .fifth .schedule__table tbody tr th {
    color: #0e3db5;
    font-weight: 700; }

.site-myquest .fifth .alert {
  font-size: 12px;
  text-align: right;
  margin: 16px 0 0; }
  @media screen and (max-width: 768px) {
    .site-myquest .fifth .alert {
      margin: 15px 0 0;
      text-align: left; } }

.site-myquest .fifth .mt {
  margin-top: 32px; }
  @media screen and (max-width: 768px) {
    .site-myquest .fifth .mt {
      margin-top: 24px; } }

.site-myquest .fifth .pricetable tbody tr th {
  font-size: 20px;
  width: 280px;
  background: #FFF;
  font-weight: 700;
  color: #0e3db5; }
  @media screen and (max-width: 768px) {
    .site-myquest .fifth .pricetable tbody tr th {
      width: auto;
      border-bottom: #e8e8e8 1px solid;
      line-height: 1;
      font-size: 17px; } }
  .site-myquest .fifth .pricetable tbody tr th strong {
    font-size: 40px; }
    @media screen and (max-width: 768px) {
      .site-myquest .fifth .pricetable tbody tr th strong {
        font-size: 28px; } }

.site-myquest .fifth .pricetable__data dt {
  font-weight: 700; }

.site-myquest .fifth .pricetable__data dd {
  font-size: 12px; }

.site-leader span.marker, .site-leader strong.marker {
  background: -webkit-linear-gradient(transparent 70%, #ffe013 70%);
  background: -o-linear-gradient(transparent 70%, #ffe013 70%);
  background: linear-gradient(transparent 70%, #ffe013 70%); }

.site-leader .site-module.wrap.none {
  padding: 0;
  overflow: visible; }

.site-leader .leader-h {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0em; }
  @media screen and (max-width: 768px) {
    .site-leader .leader-h {
      font-size: 20px;
      margin: 0 15px 6px; } }

.site-leader .fv {
  height: 450px;
  background: url("../../../images/leader/main.jpg") no-repeat center top;
  background-size: cover;
  margin: 76px 0 0;
  position: relative; }
  @media screen and (max-width: 1119px) {
    .site-leader .fv {
      height: 340px; } }
  @media screen and (max-width: 768px) {
    .site-leader .fv {
      height: 200px; } }
  .site-leader .fv figure {
    width: 40%;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (max-width: 768px) {
      .site-leader .fv figure {
        width: 64%;
        top: 24px; } }
    .site-leader .fv figure img {
      width: 100%; }
  .site-leader .fv .txt {
    position: absolute;
    bottom: 40px;
    left: 50%;
    font-size: 18px;
    font-weight: 700;
    transform: translateX(-50%);
    background: #ffe013;
    padding: 10px 40px;
    white-space: nowrap;
    border-radius: 999px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12); }
    @media screen and (max-width: 1119px) {
      .site-leader .fv .txt {
        font-size: 17px;
        bottom: 32px; } }
    @media screen and (max-width: 768px) {
      .site-leader .fv .txt {
        bottom: 24px;
        white-space: normal;
        font-size: 13px;
        width: calc(100% - 30px);
        text-align: center;
        bottom: 0;
        transform: translateX(-50%) translateY(50%);
        box-shadow: none; } }

.site-leader .context-area {
  margin: 64px 0; }
  @media screen and (max-width: 768px) {
    .site-leader .context-area {
      margin: 56px 0 32px; } }
  .site-leader .context-area .context {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.6;
    margin: 8px 0 0; }
    @media screen and (max-width: 768px) {
      .site-leader .context-area .context {
        font-size: 14px;
        margin: 0 15px; } }

.site-leader .clums {
  position: relative;
  background: #f4f4f4; }
  .site-leader .clums:before {
    content: '';
    width: 200px;
    height: 120px;
    position: absolute;
    right: 6%;
    top: -40px;
    background: url("../../../images/leader/icon.png") no-repeat;
    background-size: cover;
    z-index: 2; }
    @media screen and (max-width: 1119px) {
      .site-leader .clums:before {
        width: 120px;
        height: 72px; } }
    @media screen and (max-width: 768px) {
      .site-leader .clums:before {
        display: none; } }
  .site-leader .clums__bg {
    position: absolute;
    top: 0;
    left: -40px;
    width: calc(50% + 40px);
    height: 100%;
    background: url("../../../images/leader/photo.jpg") no-repeat center;
    background-size: cover; }
    @media screen and (max-width: 768px) {
      .site-leader .clums__bg {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px; } }
  .site-leader .clums__txt {
    padding: 80px 40px;
    padding-left: 56px;
    width: 50%;
    margin: 0 0 0 50%; }
    @media screen and (max-width: 1119px) {
      .site-leader .clums__txt {
        padding: 64px 32px; } }
    @media screen and (max-width: 768px) {
      .site-leader .clums__txt {
        position: relative;
        width: auto;
        padding: 24px 15px;
        margin: 0; } }
    .site-leader .clums__txt dt {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.6;
      margin: 0 0 20px;
      padding: 0 0 18px;
      letter-spacing: 0em;
      position: relative; }
      @media screen and (max-width: 1119px) {
        .site-leader .clums__txt dt {
          font-size: 18px; } }
      .site-leader .clums__txt dt:before, .site-leader .clums__txt dt:after {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #151515; }
      .site-leader .clums__txt dt:after {
        background: #ffe013;
        width: 100px; }
    .site-leader .clums__txt dd {
      font-size: 16px;
      text-align: justify;
      line-height: 1.8; }
      @media screen and (max-width: 1119px) {
        .site-leader .clums__txt dd {
          font-size: 14px; } }
      @media screen and (max-width: 768px) {
        .site-leader .clums__txt dd {
          font-size: 13.5px; } }

.site-leader .listable {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 1119px) {
    .site-leader .listable {
      display: block; } }
  .site-leader .listable li {
    width: calc(50% - 16px);
    border: #f4f4f4 8px solid; }
    @media screen and (max-width: 1119px) {
      .site-leader .listable li {
        width: auto;
        margin: 0 0 48px; }
        .site-leader .listable li:last-child {
          margin: 0; } }
    @media screen and (max-width: 768px) {
      .site-leader .listable li {
        margin: 0 0 32px; } }
    .site-leader .listable li dl dt {
      background: #ffe013;
      font-size: 22px;
      font-weight: 700;
      text-align: center;
      margin: 0 20px;
      padding: 6px 15px;
      position: relative;
      transform: translateY(-50%);
      border-radius: 4px; }
      @media screen and (max-width: 1119px) {
        .site-leader .listable li dl dt {
          font-size: 18px; } }
      @media screen and (max-width: 768px) {
        .site-leader .listable li dl dt {
          font-size: 16px; } }
      .site-leader .listable li dl dt:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16px 20px 0 20px;
        border-color: #ffe013 transparent transparent transparent;
        position: absolute;
        left: 50%;
        bottom: -16px;
        transform: translateX(-50%); }
        @media screen and (max-width: 1119px) {
          .site-leader .listable li dl dt:before {
            bottom: -14px; } }
    .site-leader .listable li dl dd {
      text-align: justify;
      padding: 0 20px 20px;
      font-size: 15px;
      line-height: 1.7;
      border-top: 0; }
      @media screen and (max-width: 1119px) {
        .site-leader .listable li dl dd {
          font-size: 14px; } }
      @media screen and (max-width: 768px) {
        .site-leader .listable li dl dd {
          padding: 0 15px 15px; } }
      .site-leader .listable li dl dd.block {
        text-align: center;
        font-weight: 700;
        display: block;
        background: #f4f4f4;
        padding: 12px 0;
        font-size: 16px;
        margin: 0 20px 16px;
        border-radius: 4px; }
        @media screen and (max-width: 768px) {
          .site-leader .listable li dl dd.block {
            margin: 0 15px 15px;
            padding: 8px 15px;
            font-size: 15px; } }

.site-leader p.large {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  margin: 40px 0 0;
  padding: 24px 20px;
  background: #f4f4f4;
  letter-spacing: 0em; }
  @media screen and (max-width: 1119px) {
    .site-leader p.large {
      border-radius: 4px;
      font-size: 18px;
      margin: 24px 0 0; } }
  @media screen and (max-width: 768px) {
    .site-leader p.large {
      font-size: 16px;
      margin: 16px 0 0;
      padding: 15px;
      text-align: left; } }

.site-leader .archive-box {
  width: 88%;
  margin: 0 auto; }
  @media screen and (max-width: 1119px) {
    .site-leader .archive-box {
      width: auto; } }
  .site-leader .archive-box .borders {
    border-bottom: rgba(21, 21, 21, 0.08) 1px solid; }
    .site-leader .archive-box .borders:after {
      background: #ffe013; }
  .site-leader .archive-box .list > li {
    background: #FFF;
    padding: 24px;
    margin: 0 0 32px;
    box-shadow: 0 0 24px rgba(21, 21, 21, 0.06); }
    @media screen and (max-width: 768px) {
      .site-leader .archive-box .list > li {
        padding: 15px;
        margin: 0 0 15px; } }
    .site-leader .archive-box .list > li:last-child {
      margin: 0; }
  .site-leader .archive-box .list__title {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 20px; }
    @media screen and (max-width: 768px) {
      .site-leader .archive-box .list__title {
        margin: 0 0 15px;
        display: block; } }
    .site-leader .archive-box .list__title time {
      background: #ffe013;
      font-weight: 700;
      font-size: 13px;
      display: block;
      padding: 2px 0;
      width: 120px;
      text-align: center;
      margin: 0 20px 0 0; }
    .site-leader .archive-box .list__title h4 {
      font-size: 18px;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .site-leader .archive-box .list__title h4 {
          font-size: 17px;
          margin: 12px 0 0;
          line-height: 1.56; } }
  .site-leader .archive-box .list__pics {
    margin: 0 0 20px -24px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: flex-start; }
    @media screen and (max-width: 768px) {
      .site-leader .archive-box .list__pics {
        display: block;
        margin: 0 0 20px; } }
    .site-leader .archive-box .list__pics > li {
      width: 33.3%;
      padding: 0 0 0 24px;
      margin: 24px 0 0; }
      .site-leader .archive-box .list__pics > li:nth-child(-n+3) {
        margin-top: 0; }
      @media screen and (max-width: 768px) {
        .site-leader .archive-box .list__pics > li {
          width: auto;
          padding: 0;
          margin: 0 0 15px; }
          .site-leader .archive-box .list__pics > li:last-child {
            margin: 0; } }
      .site-leader .archive-box .list__pics > li figure img {
        width: 100%;
        height: auto; }
  .site-leader .archive-box .list__desc {
    text-align: left;
    background: #f4f4f4;
    font-size: 15.5px;
    padding: 16px 20px; }
    @media screen and (max-width: 768px) {
      .site-leader .archive-box .list__desc {
        padding: 15px;
        font-size: 13.5px; } }

.site-privacy .display.min {
  min-width: 1px;
  max-width: 980px; }

.site-privacy .context {
  padding: 32px;
  background: #f4f4f4; }
  @media screen and (max-width: 768px) {
    .site-privacy .context {
      padding: 16px; } }
  .site-privacy .context strong {
    font-size: 15px;
    font-weight: 700; }

.site-privacy .list {
  border: #f4f4f4 8px solid;
  padding: 24px 32px;
  margin: 16px 0 0; }
  @media screen and (max-width: 768px) {
    .site-privacy .list {
      padding: 12px 16px; } }
  .site-privacy .list li {
    list-style: inside decimal; }

.site-privacy .mb {
  margin-bottom: 72px; }
  @media screen and (max-width: 768px) {
    .site-privacy .mb {
      margin-bottom: 56px; } }

#site-wrapper, #site-footer {
  position: relative; }

.site-clum {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  width: 88%;
  margin: 0 auto; }
  @media screen and (max-width: 1119px) {
    .site-clum {
      width: auto;
      display: block; } }
  .site-clum__sidebar {
    width: 280px; }
    @media screen and (max-width: 1119px) {
      .site-clum__sidebar {
        width: auto; } }
    @media screen and (max-width: 768px) {
      .site-clum__sidebar {
        margin: 32px 0 0; } }
  .site-clum__container {
    width: calc(100% - 280px);
    padding: 0 48px 0 0; }
    @media screen and (max-width: 1119px) {
      .site-clum__container {
        width: auto;
        padding: 0; } }
    .site-clum__container .pager {
      margin: 24px 0 0;
      text-align: center; }
      .site-clum__container .pager a, .site-clum__container .pager span {
        width: 28px;
        height: 28px;
        line-height: 26px;
        text-align: center;
        display: inline-block;
        margin: 0 3px;
        border: #e8e8e8 1px solid;
        background: #e8e8e8;
        color: #151515;
        font-size: 13px; }
      .site-clum__container .pager span {
        background: #db1501;
        border-color: #db1501;
        color: #FFF; }
      .site-clum__container .pager a:hover {
        color: #db1501;
        background: #FFF;
        border-color: #db1501; }

.site-module.wrap {
  padding: 88px 40px;
  overflow: hidden; }
  @media screen and (max-width: 1119px) {
    .site-module.wrap {
      padding: 40px 20px; } }
  @media screen and (max-width: 768px) {
    .site-module.wrap {
      padding: 32px 15px; } }
  .site-module.wrap.no-top {
    padding-top: 0; }
  .site-module.wrap.no-bottom {
    padding-bottom: 0; }
  .site-module.wrap.glay {
    background: #f4f4f4; }
  .site-module.wrap.borders {
    border-bottom: #f4f4f4 15px solid; }
    @media screen and (max-width: 768px) {
      .site-module.wrap.borders {
        border-bottom: #f4f4f4 8px solid; } }

.site-module h2.large {
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  margin: 0 0 40px;
  letter-spacing: .08em; }
  @media screen and (max-width: 1119px) {
    .site-module h2.large {
      font-size: 18px;
      margin: 0 0 32px; } }
  @media screen and (max-width: 768px) {
    .site-module h2.large {
      font-size: 15px;
      margin: 0 0 24px; } }
  .site-module h2.large:before {
    content: attr(data-eng);
    font-family: 'Oswald', sans-serif;
    letter-spacing: .02em;
    font-size: 48px;
    margin: 0 32px 0 0;
    text-indent: -2px;
    text-shadow: none; }
    @media screen and (max-width: 1119px) {
      .site-module h2.large:before {
        font-size: 40px;
        margin: 0 24px 0 0; } }
    @media screen and (max-width: 768px) {
      .site-module h2.large:before {
        font-size: 30px;
        margin: 0 16px 0 0; } }

.site-module h2.center {
  font-weight: 700;
  font-size: 34px;
  line-height: 1;
  margin: 0 0 40px;
  letter-spacing: .08em;
  text-align: center;
  position: relative; }
  @media screen and (max-width: 1119px) {
    .site-module h2.center {
      font-size: 28px;
      margin: 0 0 32px; } }
  @media screen and (max-width: 768px) {
    .site-module h2.center {
      font-size: 24px;
      margin: 0 0 20px; } }
  .site-module h2.center:after {
    content: attr(data-eng);
    letter-spacing: .048em;
    font-size: 14px;
    display: block;
    margin: 20px 0 0;
    color: #db1501; }
    @media screen and (max-width: 1119px) {
      .site-module h2.center:after {
        font-size: 13px;
        margin: 16px 0 0; } }
    @media screen and (max-width: 768px) {
      .site-module h2.center:after {
        margin: 10px 0 0; } }

.site-module h2.borders,
.site-module h3.borders,
.site-module h4.borders {
  border-bottom: #e8e8e8 1px solid;
  position: relative;
  padding: 12px;
  padding-left: 20px;
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 16px; }
  @media screen and (max-width: 1119px) {
    .site-module h2.borders,
    .site-module h3.borders,
    .site-module h4.borders {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    .site-module h2.borders,
    .site-module h3.borders,
    .site-module h4.borders {
      font-size: 18px;
      margin: 0 0 20px;
      padding-left: 18px; } }
  .site-module h2.borders small,
  .site-module h3.borders small,
  .site-module h4.borders small {
    display: inline-block;
    margin: 0 0 0 20px;
    font-size: 13px; }
    @media screen and (max-width: 768px) {
      .site-module h2.borders small,
      .site-module h3.borders small,
      .site-module h4.borders small {
        margin: 0 0 0 15px;
        font-size: 11px; } }
  .site-module h2.borders:after,
  .site-module h3.borders:after,
  .site-module h4.borders:after {
    content: '';
    width: 5px;
    height: 70%;
    background: #db1501;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: 15%;
    left: 0; }

.site-module .btnarea {
  margin: 32px 0 0; }
  @media screen and (max-width: 768px) {
    .site-module .btnarea {
      margin: 24px 0 0; } }
  @media screen and (min-width: 769px) {
    .site-module .btnarea.center {
      text-align: center; }
      .site-module .btnarea.center a {
        margin: 0 8px; } }
  .site-module .btnarea.s {
    margin: 24px 0 0; }
    .site-module .btnarea.s a {
      padding: 10px 0;
      min-width: auto;
      display: block; }
  .site-module .btnarea a {
    background: #db1501;
    color: #FFF;
    padding: 12px 72px;
    min-width: 300px;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .site-module .btnarea a {
        display: block;
        padding: 11px 0;
        margin: 0 0 10px; }
        .site-module .btnarea a:last-of-type {
          margin: 0; } }
    .site-module .btnarea a.arrow:after {
      content: "\f061";
      font-size: 11px;
      font-family: "Font Awesome 5 Free";
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%); }
    .site-module .btnarea a:hover {
      background: #151515;
      color: #FFF; }
    .site-module .btnarea a.glay {
      background: #eaeaea;
      color: #151515; }
      .site-module .btnarea a.glay:hover {
        background: #db1501;
        color: #FFF; }
    .site-module .btnarea a.black {
      background: #151515;
      color: #FFF; }
      .site-module .btnarea a.black:hover {
        background: #292929; }

.site-module .sendarea {
  text-align: center;
  margin: 40px 0 0; }
  @media screen and (max-width: 768px) {
    .site-module .sendarea {
      margin: 24px 0 0; } }
  .site-module .sendarea input {
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    font-size: 17px;
    font-weight: 700;
    background: #db1501;
    width: 280px;
    padding: 12px 0;
    color: #FFF;
    border: none;
    display: inline-block;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      .site-module .sendarea input {
        width: 100%;
        display: block; } }
    .site-module .sendarea input:hover {
      background: #ef1701; }

.site-module .privacystyle__box {
  width: 280px;
  margin: 0 auto;
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-module .privacystyle__box {
      width: 100%; } }
  .site-module .privacystyle__box__clone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    background: #db1501;
    color: #FFF;
    text-align: center;
    pointer-events: none; }
    .site-module .privacystyle__box__clone__box {
      line-height: 1; }
    .site-module .privacystyle__box__clone__min {
      font-size: 11px;
      display: block; }
    .site-module .privacystyle__box__clone__str {
      font-size: 16px;
      font-weight: 700;
      display: block;
      margin: 6px 0 0; }
  .site-module .privacystyle__box input {
    width: 100%;
    height: 64px; }
    .site-module .privacystyle__box input:hover + .privacystyle__box__clone {
      background: #ef1701; }

.site-module .tablestyle {
  width: 100%;
  border: #e8e8e8 1px solid;
  border-collapse: collapse; }
  @media screen and (max-width: 768px) {
    .site-module .tablestyle {
      display: block; } }
  @media screen and (max-width: 768px) {
    .site-module .tablestyle tbody,
    .site-module .tablestyle thead {
      display: block; } }
  .site-module .tablestyle tbody tr,
  .site-module .tablestyle thead tr {
    border-bottom: #e8e8e8 1px solid; }
    @media screen and (max-width: 768px) {
      .site-module .tablestyle tbody tr,
      .site-module .tablestyle thead tr {
        display: block; } }
    .site-module .tablestyle tbody tr th,
    .site-module .tablestyle tbody tr td,
    .site-module .tablestyle thead tr th,
    .site-module .tablestyle thead tr td {
      padding: 24px 20px;
      text-align: left;
      vertical-align: middle;
      font-size: 15px; }
      @media screen and (max-width: 1119px) {
        .site-module .tablestyle tbody tr th,
        .site-module .tablestyle tbody tr td,
        .site-module .tablestyle thead tr th,
        .site-module .tablestyle thead tr td {
          padding: 20px; } }
      @media screen and (max-width: 768px) {
        .site-module .tablestyle tbody tr th,
        .site-module .tablestyle tbody tr td,
        .site-module .tablestyle thead tr th,
        .site-module .tablestyle thead tr td {
          display: block;
          padding: 15px; } }
    .site-module .tablestyle tbody tr th,
    .site-module .tablestyle thead tr th {
      width: 240px;
      border-right: #e8e8e8 1px solid;
      background: #f4f4f4; }
      @media screen and (max-width: 768px) {
        .site-module .tablestyle tbody tr th,
        .site-module .tablestyle thead tr th {
          width: auto;
          border: none; } }
  .site-module .tablestyle.formstyle {
    border: none; }
    .site-module .tablestyle.formstyle tbody tr,
    .site-module .tablestyle.formstyle thead tr {
      border-bottom: #e8e8e8 1px solid; }
      .site-module .tablestyle.formstyle tbody tr th,
      .site-module .tablestyle.formstyle tbody tr td,
      .site-module .tablestyle.formstyle thead tr th,
      .site-module .tablestyle.formstyle thead tr td {
        padding: 48px 0; }
        @media screen and (max-width: 1119px) {
          .site-module .tablestyle.formstyle tbody tr th,
          .site-module .tablestyle.formstyle tbody tr td,
          .site-module .tablestyle.formstyle thead tr th,
          .site-module .tablestyle.formstyle thead tr td {
            padding: 32px 0; } }
        @media screen and (max-width: 768px) {
          .site-module .tablestyle.formstyle tbody tr th,
          .site-module .tablestyle.formstyle tbody tr td,
          .site-module .tablestyle.formstyle thead tr th,
          .site-module .tablestyle.formstyle thead tr td {
            padding: 16px 0; } }
        .site-module .tablestyle.formstyle tbody tr th .mt,
        .site-module .tablestyle.formstyle tbody tr td .mt,
        .site-module .tablestyle.formstyle thead tr th .mt,
        .site-module .tablestyle.formstyle thead tr td .mt {
          margin: 8px 0 0;
          font-size: 13px; }
        .site-module .tablestyle.formstyle tbody tr th input[type=text],
        .site-module .tablestyle.formstyle tbody tr th input[type=email],
        .site-module .tablestyle.formstyle tbody tr th input[type=tel],
        .site-module .tablestyle.formstyle tbody tr th textarea,
        .site-module .tablestyle.formstyle tbody tr th select,
        .site-module .tablestyle.formstyle tbody tr td input[type=text],
        .site-module .tablestyle.formstyle tbody tr td input[type=email],
        .site-module .tablestyle.formstyle tbody tr td input[type=tel],
        .site-module .tablestyle.formstyle tbody tr td textarea,
        .site-module .tablestyle.formstyle tbody tr td select,
        .site-module .tablestyle.formstyle thead tr th input[type=text],
        .site-module .tablestyle.formstyle thead tr th input[type=email],
        .site-module .tablestyle.formstyle thead tr th input[type=tel],
        .site-module .tablestyle.formstyle thead tr th textarea,
        .site-module .tablestyle.formstyle thead tr th select,
        .site-module .tablestyle.formstyle thead tr td input[type=text],
        .site-module .tablestyle.formstyle thead tr td input[type=email],
        .site-module .tablestyle.formstyle thead tr td input[type=tel],
        .site-module .tablestyle.formstyle thead tr td textarea,
        .site-module .tablestyle.formstyle thead tr td select {
          border: #e8e8e8 2px solid;
          background: #FFF;
          display: block;
          padding: 8px;
          font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
          font-weight: 500;
          -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt";
          letter-spacing: 0.024em;
          font-size: 15px;
          width: 100%;
          border-radius: 4px;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out;
          pointer-events: auto !important;
          user-select: auto !important; }
          @media screen and (max-width: 768px) {
            .site-module .tablestyle.formstyle tbody tr th input[type=text],
            .site-module .tablestyle.formstyle tbody tr th input[type=email],
            .site-module .tablestyle.formstyle tbody tr th input[type=tel],
            .site-module .tablestyle.formstyle tbody tr th textarea,
            .site-module .tablestyle.formstyle tbody tr th select,
            .site-module .tablestyle.formstyle tbody tr td input[type=text],
            .site-module .tablestyle.formstyle tbody tr td input[type=email],
            .site-module .tablestyle.formstyle tbody tr td input[type=tel],
            .site-module .tablestyle.formstyle tbody tr td textarea,
            .site-module .tablestyle.formstyle tbody tr td select,
            .site-module .tablestyle.formstyle thead tr th input[type=text],
            .site-module .tablestyle.formstyle thead tr th input[type=email],
            .site-module .tablestyle.formstyle thead tr th input[type=tel],
            .site-module .tablestyle.formstyle thead tr th textarea,
            .site-module .tablestyle.formstyle thead tr th select,
            .site-module .tablestyle.formstyle thead tr td input[type=text],
            .site-module .tablestyle.formstyle thead tr td input[type=email],
            .site-module .tablestyle.formstyle thead tr td input[type=tel],
            .site-module .tablestyle.formstyle thead tr td textarea,
            .site-module .tablestyle.formstyle thead tr td select {
              -webkit-appearance: none; } }
          .site-module .tablestyle.formstyle tbody tr th input[type=text]:focus,
          .site-module .tablestyle.formstyle tbody tr th input[type=email]:focus,
          .site-module .tablestyle.formstyle tbody tr th input[type=tel]:focus,
          .site-module .tablestyle.formstyle tbody tr th textarea:focus,
          .site-module .tablestyle.formstyle tbody tr th select:focus,
          .site-module .tablestyle.formstyle tbody tr td input[type=text]:focus,
          .site-module .tablestyle.formstyle tbody tr td input[type=email]:focus,
          .site-module .tablestyle.formstyle tbody tr td input[type=tel]:focus,
          .site-module .tablestyle.formstyle tbody tr td textarea:focus,
          .site-module .tablestyle.formstyle tbody tr td select:focus,
          .site-module .tablestyle.formstyle thead tr th input[type=text]:focus,
          .site-module .tablestyle.formstyle thead tr th input[type=email]:focus,
          .site-module .tablestyle.formstyle thead tr th input[type=tel]:focus,
          .site-module .tablestyle.formstyle thead tr th textarea:focus,
          .site-module .tablestyle.formstyle thead tr th select:focus,
          .site-module .tablestyle.formstyle thead tr td input[type=text]:focus,
          .site-module .tablestyle.formstyle thead tr td input[type=email]:focus,
          .site-module .tablestyle.formstyle thead tr td input[type=tel]:focus,
          .site-module .tablestyle.formstyle thead tr td textarea:focus,
          .site-module .tablestyle.formstyle thead tr td select:focus {
            transform: scale(1.02);
            border-color: #FFF;
            box-shadow: 0 4px 32px rgba(0, 0, 0, 0.16); }
      .site-module .tablestyle.formstyle tbody tr td,
      .site-module .tablestyle.formstyle thead tr td {
        padding-left: 48px; }
        @media screen and (max-width: 768px) {
          .site-module .tablestyle.formstyle tbody tr td,
          .site-module .tablestyle.formstyle thead tr td {
            padding-left: 0;
            padding-top: 0; } }
      .site-module .tablestyle.formstyle tbody tr th,
      .site-module .tablestyle.formstyle thead tr th {
        width: 300px;
        background: none;
        border: none; }
        @media screen and (max-width: 768px) {
          .site-module .tablestyle.formstyle tbody tr th,
          .site-module .tablestyle.formstyle thead tr th {
            width: auto; } }
        .site-module .tablestyle.formstyle tbody tr th:after,
        .site-module .tablestyle.formstyle thead tr th:after {
          content: '任意';
          float: right;
          background: #e8e8e8;
          color: #151515;
          border-radius: 4px;
          padding: 2px 8px;
          font-size: 13px; }
        .site-module .tablestyle.formstyle tbody tr th.hiss:after,
        .site-module .tablestyle.formstyle thead tr th.hiss:after {
          content: '必須';
          background: #db1501;
          color: #FFF; }

.sendmessage {
  text-align: center;
  font-size: 16px; }
  @media screen and (max-width: 768px) {
    .sendmessage {
      font-size: 14px; } }

.scroll-anime {
  opacity: 0;
  transform: translateY(48px);
  -webkit-transition: all 800ms 0ms ease-in-out;
  -moz-transition: all 800ms 0ms ease-in-out;
  -ms-transition: all 800ms 0ms ease-in-out;
  -o-transition: all 800ms 0ms ease-in-out;
  transition: all 800ms 0ms ease-in-out; }
  .scroll-anime._active {
    opacity: 1;
    transform: translateY(0); }

.scroll-img {
  position: relative; }
  .scroll-img:before, .scroll-img:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .scroll-img:before {
    background: #db1501;
    -webkit-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img:after {
    background: #FFF;
    -webkit-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img.__active:before {
    width: 0; }
  .scroll-img.__active:after {
    width: 0; }

.scale-enter-active, .scale-leave-active {
  transition: 400ms; }

.scale-enter, .scale-leave-to {
  opacity: 0; }

#intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  visibility: hidden; }
  #intro.visible {
    visibility: visible; }
  #intro.active:before, #intro.active:after {
    height: 0; }
  #intro.active #bar {
    opacity: 0; }
  #intro.active #num {
    opacity: 0; }
  #intro.active .svg {
    opacity: 0; }
  #intro:before, #intro:after {
    content: '';
    width: 200%;
    height: 51%;
    position: absolute;
    background: #db1501;
    z-index: 1;
    -webkit-transition: all 400ms 0ms ease-in-out;
    -moz-transition: all 400ms 0ms ease-in-out;
    -ms-transition: all 400ms 0ms ease-in-out;
    -o-transition: all 400ms 0ms ease-in-out;
    transition: all 400ms 0ms ease-in-out; }
  #intro:before {
    top: 0;
    left: -50%; }
  #intro:after {
    bottom: 0;
    right: -50%; }
  #intro #bar {
    height: 2px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translateY(-1px) translateX(-50%);
    background: #FFF;
    opacity: 1;
    transition: opacity 400ms, transform 400ms; }
  #intro #num {
    font-family: Arial, Helvetica, "sans-serif";
    font-size: 20px;
    line-height: 1;
    text-align: center;
    position: absolute;
    top: calc(50% + 30px);
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    position: fixed;
    pointer-events: none;
    color: #FFF;
    opacity: 1;
    z-index: 4;
    transition: opacity 400ms, transform 400ms; }
  #intro .svg {
    width: 60vh;
    position: absolute;
    top: 50%;
    left: calc(50% + 5px);
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
    opacity: .16;
    transition: opacity 400ms, transform 400ms; }
    @media screen and (max-width: 768px) {
      #intro .svg {
        width: 60vw; } }
    #intro .svg svg {
      width: 100%; }
    #intro .svg .st0 {
      fill: #151515; }

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
  border-color: #db1501; }
