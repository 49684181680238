.site-kamatori{
	&.before{
		.fv{
			margin: $pc-head 0 0;
			background: url("../../../images/kamatori/fv.jpg") no-repeat center / cover;
			padding: 48px 0;
			border-bottom: $red 12px solid;
			@media screen and (max-width:$tab){
				margin: $sp-head 0 0;
			}
			@media screen and (max-width:$responsive){
				padding: 32px 0;
				border-bottom-width: 8px;
			}
			&__logo{
				width: 640px;
				margin: 0 auto;
				@media screen and (max-width:$responsive){
					width: 80%;	
				}
				img{
					width: 100%;
				}
			}
		}

		.content{
			&.wrap{
				padding-top: 48px;
				padding-bottom: 48px;
				@media screen and (max-width:$tab){
					padding-top: 32px;
					padding-bottom: 32px;
				}
				@media screen and (max-width:$responsive){
					padding-top: 24px;
					padding-bottom: 24px;
				}
			}
			.ttl{
				text-align: center;
				padding: 40px 0;
				background: #FFF;
				position: relative;
				border-radius: 6px;
				@media screen and (max-width:$tab){
					padding: 32px;	
				}
				@media screen and (max-width:$responsive){
					padding: 24px;	
					padding-top: 32px;
				}
				&__date{
					text-align: center;
					font-size: 48px;
					line-height: 1;
					font-weight: 700;
					display: inline-block;
					@media screen and (max-width:$responsive){
						font-size: 20px;	
					}
					strong{
						@include osw();
						font-size: 56px;
						color: $red;
						@media screen and (max-width:$responsive){
							font-size: 28px;	
						}
					}
				}
				.alt{
					font-weight: 700;
					font-size: 18px;
					line-height: 1.56;
					text-align: center;
					margin: 24px 0 0;
					@media screen and (max-width:$responsive){
						font-size: 14px;
						margin: 16px 0 0;
					}
				}
				.btnarea{
					margin-top: 28px;
					@media screen and (max-width:$responsive){
						margin-top: 12px;	
					}
					a{
						font-size: 20px;
						font-weight: 700;
						border-radius: 999px;
						border: $red 2px solid;
						box-shadow: 0 0 24px rgba($red,.32);
						@media screen and (max-width:$responsive){
							font-size: 17px;
							border-radius: 4px;
						}
						&:hover{
							background: #FFF;
							color: $red;
						}
						i{
							display: inline-block;
							margin: 0 6px 0 0;
						}
					}
				}
			}
		}

		.catch{
			width: 480px;
			margin: 0 auto;
			@media screen and (max-width:$responsive){
				width: 80%;	
			}
			img{
				width: 100%;
			}
		}

		.clum{
			margin: 32px 0 0;
			@include flex();
			@media screen and (max-width:$tab){
				display: block;	
			}
			@media screen and (max-width:$responsive){
				margin: 24px 0 0;	
			}
			&__photo{
				width: calc(100% - 480px);
				@media screen and (max-width:$tab){
					width: auto;	
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: right center;
					@media screen and (max-width:$tab){
						height: auto;
					}
				}
			}
			&__data{
				width: 480px;
				font-size: 14.5px;
				text-align: justify;
				padding: 0 0 0 40px;
				position: relative;
				@media screen and (max-width:$tab){
					width: auto;
					padding: 40px 40px 0;
				}
				@media screen and (max-width:$responsive){
					padding: 8px 15px 0;
					font-size: 14px;
				}
				&__chara{
					position: absolute;
					right: 0;
					top: 0;
					width: 100px;
					transform: translateY(-50%);
					@media screen and (max-width:$tab){
						top: 32px;
						right: 40px;
					}
					@media screen and (max-width:$responsive){
						width: 56px;
						top: 32px;
						right: 8px;
					}
					img{
						width: 100%;
					}
				}
				dl{
					padding: 16px 0;
					dt{
						font-size: 26px;
						font-weight: 700;
						line-height: 1.5;
						color: $blue;
						@media screen and (max-width:$responsive){
							font-size: 20px;
							padding: 0 64px 0 0;
						}
					}
					dd{
						margin: 12px 0 0;
					}
				}
			}
		}
	}


	
	&.after{
		figure.title{
			width: 440px;
			margin: 0 auto 48px;
			@media screen and (max-width:$tab){
				width: 360px;
				margin: 0 auto 32px;
			}
			@media screen and (max-width:$responsive){
				width: 240px;
				margin: 0 auto 24px;
			}
			img{
				width: 100%;
			}
		}

		.clum{
			@include flex();
			align-items: center;
			position: relative;
			padding: 0 0 4vw;
			@media screen and (max-width:$responsive){
				display: block;
				padding: 0;
			}
			&:before{
				content: '';
				background: $glay;
				position: absolute;
				width: 92%;
				height: calc(100% - 4vw);
				top: 4vw;
				right: -40px;
				@media screen and (max-width:$tab){
					right: -20px;	
				}
				@media screen and (max-width:$responsive){
					display: none;
				}
			}
			&:after{
				content: '';
				border: #FFF 4px solid;
				border-right: 0;
				position: absolute;
				width: calc(50% - 4vw);
				height: calc(100% - 16vw);
				bottom: 4vw;
				right: -40px;
				@media screen and (max-width:$tab){
					right: -20px;	
				}
				@media screen and (max-width:$responsive){
					display: none;
				}
			}
			&__pic{
				position: absolute;
				top: 0;
				left: -40px;
				width: 80%;
				height: calc(100% - 8vw);
				overflow: hidden;
				@media screen and (max-width:$tab){
					left: -20px;	
				}
				@media screen and (max-width:$responsive){
					position: relative;
					left: 0;
					width: auto;
					height: 56vw;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					@media screen and (max-width:$responsive){
						transform: translate(0) !important;
					}
				}
			}
			&__data{
				padding: 8vw 0;
				margin: 0 0 0 50%;
				position: relative;
				z-index: 2;
				@media screen and (max-width:$responsive){
					padding: 0;
					margin: 0;
				}
				&__box{
					background: #FFF;
					padding: 48px;
					padding-top: 56px;
					min-width: 540px;
					max-width: 640px;
					box-shadow: 0 0 64px rgba($normal,.08);
					border-radius: 1px;
					position: relative;
					@media screen and (max-width:$tab){
						margin: 0 20px 0 0;
						min-width: 1px;
						max-width: 100%;
					}
					@media screen and (max-width:$responsive){
						margin: 24px 0 0;
						box-shadow: none;
						padding: 0 15px;
						transform: translate(0) !important;
					}
					&:before{
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 20px 20px 0 0;
						border-color: $blue transparent transparent transparent;
						position: absolute;
						top: 8px;
						left: 8px;
						@media screen and (max-width:$responsive){
							display: none;
						}
					}
					dt{
						font-size: 28px;
						font-weight: 700;
						color: $blue;
						line-height: 1.5;
						@media screen and (max-width:$tab){
							font-size: 24px;	
						}
						@media screen and (max-width:$responsive){
							font-size: 20px;	
						}
					}
					dd{
						text-align: justify;
						font-size: 15px;
						margin: 16px 0 0;
						@media screen and (max-width:$tab){
							font-size: 14px;	
						}
						&.pic{
							position: absolute;
							top: 0;
							right: 0;
							transform: translate(-50%, -50%);
							width: 80px;
							@media screen and (max-width:$tab){
								width: 56px;	
							}
							@media screen and (max-width:$responsive){
								right: -15px;
								width: 56px;
								top: -48px;
							}
							img{
								width: 100%;
							}
						}
					}
				}
			}
		}

		.bottom{
			margin: 4vw 0 0;
			padding: 4vw 0;
			position: relative;
			@media screen and (max-width:$responsive){
				margin: 32px 0 0;
				padding: 0;
			}
			&:before{
				content: '';
				background: $glay;
				position: absolute;
				width: 92%;
				height: 100%;
				top: 0;
				left: -40px;
				@media screen and (max-width:$tab){
					left: -20px;	
				}
				@media screen and (max-width:$responsive){
					display: none;
				}
			}
			.figbnr{
				padding: 24px;
				background: #FFF;
				border: $blue 4px solid;
				@include flex();
				align-items: center;
				justify-content: flex-start;
				border-radius: 3px;
				box-shadow: 0 0 64px rgba($normal,.08);
				@media screen and (max-width:$responsive){
					padding: 0;
					border: none;
					justify-content: space-between;
				}
				figure{
					width: 240px;
					margin: 0 20px 0 0;
					@media screen and (max-width:$tab){
						width: 180px;
					}
					@media screen and (max-width:$responsive){
						width: calc(50% - 8px);
						margin: 0;
					}
					&:last-of-type{
						margin: 0;
					}
					img{
						width: 100%;
					}
				}
				&__data{
					width: calc(100% - 480px - 20px);
					padding: 0 24px 0 48px;
					@media screen and (max-width:$tab){
						width: calc(100% - 360px - 20px);
						padding-left: 24px;
					}
					@media screen and (max-width:$responsive){
						padding: 15px;
						border: $blue 2px solid;
						border-radius: 3px;
						width: 100%;
						margin: 15px 0 0;
					}
					h2{
						color: $blue;
						font-weight: 700;
						font-size: 38px;
						line-height: 1.3;
						margin: 0 0 16px;
						padding: 0 0 10px;
						position: relative;
						@media screen and (max-width:$tab){
							font-size: 24px;	
						}
						@media screen and (max-width:$responsive){
							font-size: 18px;	
						}
						&:before,
						&:after{
							content: '';
							width: 100%;
							height: 6px;
							background: $blue;
							position: absolute;
							left: 0;
							bottom: 0;
							@media screen and (max-width:$responsive){
								height: 4px;	
							}
						}
						&:after{
							background: $red;
							width: 18%;
						}
					}
					&__list{
						@include flex();
						justify-content: flex-start;
						margin: 0 0 12px;
						@media screen and (max-width:$responsive){
							display: block;	
						}
						dl{
							width: 32%;
							border: $blue 2px solid;
							text-align: center;
							@media screen and (max-width:$responsive){
								width: auto;
								margin: 0 0 8px;
								&:last-of-type{
									margin: 0;
								}
							}
							&.ws{
								width: 24%;
								margin: 0 2% 0 0;
								@media screen and (max-width:$responsive){
									width: auto;
									margin-bottom: 8px;
									margin-right: 0;
								}
							}
							&.wl{
								width: 48%;
								@media screen and (max-width:$responsive){
									width: auto;	
								}
							}
							dt{
								background: $blue;
								color: #FFF;
								font-weight: 700;
								padding: 6px 15px;
							}
							dd{
								color: $blue;
								font-size: 15px;
								padding: 6px 15px;
								font-weight: 700;
								@media screen and (max-width:$tab){
								}
							}
						}
					}
					p{
						font-size: 16px;
						font-weight: 700;
						line-height: 1.6;
						color: $blue;
						@media screen and (max-width:$tab){
							font-size: 14px;
						}
						@media screen and (max-width:$responsive){
							text-align: center;	
						}
					}
				}
			}
			.btnbox{
				margin: 24px 0 0;
				padding: 24px;
				background: $blue;
				border-radius: 3px;
				@include flex();
				align-items: center;
				justify-content: center;
				box-shadow: 0 0 64px rgba($normal,.08);
				@media screen and (max-width:$responsive){
					padding: 15px;	
				}
				a{
					width: 300px;
					display: inline-block;
					padding: 12px 0;
					border-radius: 3px;
					text-align: center;
					color: $blue;
					background: #FFF;
					font-weight: 700;
					font-size: 18px;
					margin: 0 8px;
					box-shadow: 0 0 12px rgba(#000,.08);
					transition: transform 80ms;
					@media screen and (max-width:$responsive){
						display: block;
						width: 100%;
						margin: 0 0 8px;
						&:last-of-type{
							margin: 0;
						}
					}
					i{
						display: inline-block;
						transform: rotateY(180deg);
						margin: 0 8px 0 0;
					}
					&:hover{
						transform: scale(1.04);
					}
				}
			}
		}
	}
	
	// add mobara
	&.before{
		.fv{
			&.mobara{
				background: url("../../../images/mobara/fv.jpg") no-repeat center / cover;
				padding: 72px 0;
				@media screen and (max-width:$tab){
					padding: 48px 0;
				}
				@media screen and (max-width:$responsive){
					padding: 32px 0;
				}
				.fv__logo{
					width: 760px;
					@media screen and (max-width:$tab){
						width: 72%;
					}
					@media screen and (max-width:$responsive){
						width: 80%;
					}
				}
			}
		}
	}

	.mobara-title{
		text-align: center;
		font-size: 3.2rem;
		line-height: 1;
		font-weight: 700;
		color: $blue;
		margin: 0 0 32px;
		@media screen and (max-width:$responsive){
			margin: 0 15px 32px;
		}
		img{
			max-width: 100%;
			width: 520px;
		}
	}
	.mobara-midtitle{
		font-size: 2rem;
		line-height: 1.5;
		font-weight: 700;
		color: $blue;
		border: $blue 2px solid;
		border-radius: 3px;
		padding: 32px 0;
		margin: 0 0 32px;
		text-align: center;
		@media screen and (max-width:$responsive){
			padding: 24px 16px;
			font-size: 1.32rem;
			margin: 24px 0 0;
		}
	}
	
	.btnarea{
		&.shadow{
			a{
        font-size: 20px;
        font-weight: 700;
        border-radius: 999px;
        border: $red 2px solid;
        box-shadow: 0 0 24px rgba($red,.32);
        @media screen and (max-width:$responsive){
          font-size: 17px;
          border-radius: 4px;
        }
        &:hover{
          background: #FFF;
          color: $red;
        }
        i{
          display: inline-block;
          margin: 0 6px 0 0;
        }
      }
		}
	}
	.harfbg{
		position: relative;
		overflow: visible !important;
		&:before{
			content: '';
			width: 100%;
			height: 64%;
			background: $glay;
			position: absolute;
			left: 0;
			bottom: 0;
			@media screen and (max-width:$responsive){
				height: 25%;
			}
		}
	}
	.openinfo{
		padding: 0 0 16px;
		&__context{
			text-align: center;
			font-size: 1.12rem;
			margin: 32px 0;
			@media screen and (max-width:$responsive){
				font-size: 1rem;
				text-align: justify;
				padding: 0 15px;
			}
		}
		&__list{
			@include flex();
			@media screen and (max-width:$tab){
				display: block;
			}
			li{
				width: calc(50% - 12px);
				padding: 40px;
				border-radius: 6px;
				position: relative;
				overflow: hidden;
				position: relative;
				z-index: 2;
				@media screen and (max-width:$tab){
					width: 100%;
					margin: 0 0 40px;
					&:last-child{
						margin-bottom: 0;
					}
				}
				@media screen and (max-width:$responsive){
					padding: 20px;
					margin: 0 0 12px;
				}
				&:after{
					content: '';
					width: 80px;
					height: 80px;
					background: #f6cc25;
					position: absolute;
					top: 0;
					left: 0;
					transform: translate(-50%, -50%) rotate(45deg);
					@media screen and (max-width:$responsive){
						width: 56px;
						height: 56px;
					}
				}
				&:before{
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 6px;
					background: #FFF;
					border: $glay 4px solid;
					z-index: -1;
				}
				.clum{
					@include flex();
					margin: 0;
				}
				figure{
					width: 80px;
					height: 80px;
					border-radius: 50%;
					background: $blue;
					@include flex();
					align-items: center;
					justify-content: center;
					position: relative;
					@media screen and (max-width:$responsive){
						width: 56px;
						height: 56px;
					}
					img{
						width: 50%;
					}
				}
				h3{
          font-size: 2.56rem;
          line-height: 1.5;
          font-weight: 700;
          width: calc(100% - 80px);
          padding-left: 32px;
          color: $blue;
					@media screen and (max-width:$responsive){
						font-size: 1.4rem;
						width: calc(100% - 56px);
						padding-left: 20px;
					}
					&:before{
						content: attr(data-jp);
						display: block;
						font-size: 1.32rem;
						margin: 0 0 0 2px;
					}
				}
				.txt{
					font-size: 1.04rem;
					margin: 20px 0 0;
					padding: 20px 0 0;
					border-top: $border 1px solid;
					strong{
						color: $red;
					}
				}
			}
		}
	}
	.descarea,
	.contactarea,
	.guidearea,
	.steparea{
		margin: 32px 0 0;
		background: #FFF;
		padding: 40px;
		border-radius: 6px;
		@media screen and (max-width:$responsive){
			padding: 16px;
			margin: 24px 0 0;
		}
		.mobara-title{
			font-size: 1.56rem;
			background: $blue;
			color: #FFF;
			text-align: center;
			padding: 20px 0;
			margin: 0 0 24px;
			position: relative;
			@media screen and (max-width:$responsive){
				padding: 12px 0;
				font-size: 1.32rem;
			}
			&:before,
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 12px 12px 0 0;
				border-color: #f6cc25 transparent transparent transparent;
				position: absolute;
				top: 4px;
				left: 4px;
				@media screen and (max-width:$responsive){
					border-width: 6px 6px 0 0;
				}
			}
			&:after{
				top: auto;
				left: auto;
				bottom: 4px;
				right: 4px;
				border-width: 0 0 12px 12px;
				border-color: transparent transparent #f6cc25 transparent;
				@media screen and (max-width:$responsive){
						border-width: 0 0 6px 6px;
				}
			}
		}
		&__clum{
			@include flex();
			align-items: center;
			@media screen and (max-width:$responsive){
				display: block;
			}
			dl{
				@include flex();
				align-items: center;
				@media screen and (max-width:$responsive){
					display: block;
				}
				dt{
					background: darken($glay, 4%);
					width: 200px;
					text-align: center;
					font-weight: 700;
					font-size: 1.24rem;
					padding: 8px 0;
					@media screen and (max-width:$responsive){
						width: auto;
					}
				}
				dd{
					width: calc(100% - 200px);
					font-size: 1.32rem;
					padding: 0 0 0 20px;
					@media screen and (max-width:$responsive){
						width: auto;
						padding: 16px 0 0;
						font-size: 1.12rem;
					}
				}
			}
		}
		&__txt{
			margin: 24px 0 0;
			font-size: 1.04rem;
			padding: 40px;
			border: $glay 4px solid;
			@media screen and (max-width:$responsive){
				padding: 16px;
			}
		}
	}
	
	.contactarea{
		.mobara-title{
			margin-bottom: 0;
		}
		&__list{
			li{
				padding: 32px 0;
				border-bottom: $border 1px solid;
				padding-left: 48px;
				position: relative;
				@media screen and (max-width:$responsive){
					padding-left: 32px;
				}
				@for $i from 1 through 3{
					&:nth-child(#{$i}){
						&:before{
							content: '#{$i}';
						}
					}
				}
				&:before{
					content: '1';
					position: absolute;
					top: 50%;
					left: 0;
					background: $blue;
					color: #FFF;
					width: 32px;
					height: 32px;
					border-radius: 999px;
					transform: translateY(-50%);
					@include flex();
					align-items: center;
					justify-content: center;
					@media screen and (max-width:$responsive){
						transform: translate(0,0);
						top: 34px;
						width: 24px;
						height: 24px;
					}
				}
				dl{
					@include flex();
					align-items: center;
					position: relative;
					padding: 0 200px 0 0;
					@media screen and (max-width:$tab){
						display: block;
						padding: 0;
					}
					dt{
						width: 360px;
						font-weight: 700;
						font-size: 1.24rem;
						font-weight: 700;
						@media screen and (max-width:$tab){
							width: auto;
						}
						@media screen and (max-width:$responsive){
							font-size: 1.16rem;
						}
					}
					dd{
						width: calc(100% - 360px);
						font-size: 1.08rem;
						.sp{
							display: none;
							@media screen and (max-width:$responsive){
								display: inline;
							}
						}
						@media screen and (max-width:$tab){
							width: auto;
							margin: 16px 0 0;
							font-size: 1rem;
						}
						&.btnarea{
							width: auto;
							position: absolute;
							top: 50%;
							right: 0;
							margin: 0;
							transform: translateY(-50%);
							@media screen and (max-width:$responsive){
								position: relative;
								top: auto;
								right: auto;
								transform: translate(0,0);
								margin: 16px 0 0;
							}
							a{
								font-size: 1.08rem;
								padding: 8px 24px;
								border-radius: 3px;
								min-width: 1px;
							}
						}
					}
				}
			}
		}
	}
	.guidearea{
		.tablestyle{
			margin: 24px 0;
			@media screen and (max-width:$responsive){
				border-bottom: none;
			}
		}
		.context{
			font-size: 1.08rem;
			text-align: center;
			@media screen and (max-width:$responsive){
				font-size: 1rem;
				text-align: left;
				br{
					display: none;
				}
			}
		}
	}
	.steparea{
		&__list{
			@include flex();
			@media screen and (max-width:$tab){
				display: block;
			}
			li{
				width: calc(20% - 12px);
				padding: 24px;
				padding-top: 40px;
				border: $blue 1px solid;
				border-radius: 4px;
				@include flex();
				align-items: center;
				justify-content: center;
				position: relative;
				@media screen and (max-width:$tab){
					width: auto;
					padding-top: 24px;
					margin: 0 0 24px;
				}
				@media screen and (max-width:$responsive){
					padding-top: 32px;
				}
				@for $i from 1 through 5{
					&:nth-child(#{$i}){
						&:before{
							content: 'STEP.#{$i}';
						}
					}
				}
				&:before{
					content: 'STEP';
					font-size: .88rem;
					position: absolute;
					top: 0;
					left: 0;
					padding: 2px 16px;
					padding-right: 20px;
					color: #FFF;
					background: $blue;
					border-radius: 0 0 999px 0;
					@media screen and (max-width:$responsive){
						padding: 2px 12px;
						font-size: .8rem;
						border-radius: 0 0 8px 0;
					}
				}
				&:after{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 12px 0 12px 12px;
					border-color: transparent transparent transparent $blue;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translate(100%, -50%);
					@media screen and (max-width:$tab){
						border-width: 12px 12px 0 12px;
						border-color: $blue transparent transparent transparent;
						top: auto;
						right: auto;
						left: 50%;
						bottom: 0;
						transform: translate(-50%, 100%);
					}
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
 	     .txt{
				 font-size: 1.16rem;
				 line-height: 1.56;
				 font-weight: 700;
				 color: $blue;
        }
			}
		}
		.desc{
			border: $glay 4px solid;
			padding: 24px;
			margin: 32px 0 0;
			border-radius: 4px;
			@include flex();
			align-items: center;
			position: relative;
			@media screen and (max-width:$tab){
				display: block;
			}
			@media screen and (max-width:$responsive){
				padding: 0 8px;
				border: none;
			}
			dt{
				width: 280px;
				font-weight: 700;
				font-size: 1.32rem;
				text-align: center;
				padding-right: 32px;
				@media screen and (max-width:$tab){
					width: auto;
					padding: 0;
					font-size: 1.64rem;
				}
			}
			dd{
				font-size: 1.08rem;
				width: calc(100% - 280px);
				@include flex();
				align-items: center;
				@media screen and (max-width:$tab){
					width: auto;
					margin: 16px 0 0;
				}
				@media screen and (max-width:$responsive){
					display: block;
					font-size: 1rem;
				}
				.txtbox{
					width: calc(100% - 300px);
					padding: 0 32px 0 0;
					@media screen and (max-width:$responsive){
						width: auto;
						padding: 0 0 12px;
					}
					.info{
						margin: 0 0 24px;
						padding: 0 0 24px;
						border-bottom: $border 1px solid;
					}
				}
				> figure{
					width: 300px;
					@media screen and (max-width:$responsive){
						width: auto;
						margin: 16px 0 0;
					}
					img{
						width: 100%;
					}
				}
			}
		}
	}
}