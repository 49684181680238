.site-privacy{
	.display.min{
		min-width: 1px;
		max-width: 980px;
	}
	.context{
		padding: 32px;
		background: $glay;
		@media screen and (max-width:$responsive){
			padding: 16px;	
		}
		strong{
			font-size: 15px;
			font-weight: 700;
		}
	}
	.list{
		border: $glay 8px solid;
		padding: 24px 32px;
		margin: 16px 0 0;
		@media screen and (max-width:$responsive){
			padding: 12px 16px;	
		}
		li{
			list-style: inside decimal;
		}
	}
	.mb{
		margin-bottom: 72px;
		@media screen and (max-width:$responsive){
			margin-bottom: 56px;	
		}
	}
}