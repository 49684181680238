.site-teacher{
    .teacherlist{
        @include flex();
        @media screen and (max-width:$responsive){
            display: block;   
        }
        &__items{
            width: calc(50% - 20px);
            height: 180px;
            margin: 40px 0 0;
            position: relative;
            overflow: hidden;
            display: block;
            @media screen and (max-width:$tab){
                width: calc(50% - 10px);
            }
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 0 0 16px;
                &:last-child{
                    margin: 0;
                }
            }
            &:nth-child(-n+2){
                margin-top: 0;
            }
            &:before,
            &:after{
                content: '';
                width: 60%;
                height: 100%;
                position: absolute;
                top: 0;
                right: -5%;
                transform: skewX(15deg);
                background: darken($glay, 1%);
                z-index: 2;
                @media screen and (max-width:$tab){
                    width: 70%;
                    right: -10%;
                }
            }
            &:after{
                width: 0;
                background: $red;
                @include transition(200ms);
            }
            &:hover{
                &:after{
                    width: 60%;
                    @media screen and (max-width:$tab){
                        width: 70%;   
                    }
                }
                .bg{
                    transform: scale(1.1);
                }
                dl{
                    color: #FFF;
                }
                i{
                    border-color: #FFF;
                    color: #FFF;
                }
            }
            .bg{
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                background-size: cover;
                background-position: center;
                @include transition(200ms);
            }
            dl{
                padding: 56px 40px;
                margin: 0 0 0 50%;
                position: relative;
                color: $normal;
                z-index: 3;
                @media screen and (max-width:$tab){
                    padding: 40px 20px;
                    padding-top: 56px;
                    margin: 0 0 0 44%;
                }
                dt{
                    margin: -12px 0 0;
                    font-weight: 700;
                    font-size: 16px;
                    strong{
                        font-size: 28px;
                        display: inline-block;
                        margin: 0 8px 0 0;
                        @media screen and (max-width:$tab){
                            font-size: 20px;   
                        }
                    }
                }
                dd{
                    margin: 8px 0 0;
                    &:before{
                        content: attr(data-cate);
                        display: inline-block;
                        margin: 0 12px 0 0;
                        padding: 1px 8px;
                        background: $normal;
                        color: #FFF;
                        font-size: 13px;
                    }
                }
            }
            &__badge{
                $size: 52px;
                width: $size;
                height: $size;
                @include flex();
                align-items: center;
                justify-content: center;
                color: #FFF;
                background: darken($red, 4%);
                position: absolute;
                top: 0;
                right: 0;
                z-index: 6;
                @media screen and (max-width:$tab){
                    $size: 40px;
                    font-size: 12px;
                    width: $size;
                    height: $size;
                }
            }
            &__more{
                position: absolute;
                right: 0;
                bottom: 20px;
                border-bottom: $normal 1px solid;
                color: $normal;
                padding: 0 48px 0 0;
                z-index: 4;
            }
        }
    }
}


.site-teacher-single{
    .profile{
        padding: 64px 0;
        position: relative;
        @media screen and (max-width:$tab){
            padding: 32px 0;   
        }
        &:before{
            content: '';
            width: calc(50% + 40px);
            height: 100%;
            background: $glay;
            position: absolute;
            top: 0;
            left: -40px;
        }
        &__box{
            @include flex();
            align-items: center;
            background: #FFF;
            padding: 24px;
            box-shadow: 0 8px 24px rgba(#000,.04);
            overflow: hidden;
            position: relative;
            margin: 0 0 48px;
            @media screen and (max-width:$tab){
                align-items: flex-start;
                padding: 20px;
                margin: 0 0 40px;
            }
            @media screen and (max-width:$responsive){
                padding: 0;
                display: block;
                margin: 0 0 24px;
            }
            &:last-of-type{
                margin: 0;
            }
            &:before{
                content: '';
                width: 80px;
                height: 80px;
                position: absolute;
                right: -40px;
                top: -40px;
                background: $red;
                transform: rotate(45deg);
                @media screen and (max-width:$tab){
                    width: 40px;
                    height: 40px;
                    right: -20px;
                    top: -20px;
                }
                @media screen and (max-width:$responsive){
                    display: none;
                }
            }
            figure{
                width: 480px;
                @media screen and (max-width:$tab){
                    width: 320px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;   
                }
                img{
                    width: 100%;
                }
            }
            dl{
                width: calc(100% - 480px);
                padding: 0 24px 0 48px;
                @media screen and (max-width:$tab){
                    width: calc(100% - 320px);
                    padding: 0 0 0 32px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 20px;
                }
                dt{
                    @include min();
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 1.6;
                    margin: 0 0 16px;
                    @media screen and (max-width:$tab){
                        font-size: 24px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 20px;
                        margin: 0 0 12px;
                    }
                    &:first-letter{
                        color: $red;
                        font-size: 48px;
                        line-height: 1;
                        @media screen and (max-width:$tab){
                            font-size: 32px;   
                        }
                        @media screen and (max-width:$responsive){
                            font-size: 28px;   
                        }
                    }
                }
                dd{
                    font-size: 15px;
                    text-align: justify;
                    @media screen and (max-width:$tab){
                        font-size: 14px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 13.5px;   
                    }
                }
            }
            &--rvs{
                &:before{
                    right: auto;
                    left: -40px;
                    @media screen and (max-width:$tab){
                        left: -30px;
                    }
                }
                figure{
                    order: 2;
                }
                dl{
                    order: 1;
                    padding: 0 48px 0 24px;
                    @media screen and (max-width:$tab){
                        padding: 0 32px 0 0;   
                    }
                    @media screen and (max-width:$responsive){
                        padding: 20px;   
                    }
                }
            }
        }
    }
}