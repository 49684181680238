.site-myquest{
    $b: #0e3db5;
    $s: #e4f5ff;
    $y: #ffe400;
    .fv{
        margin: $pc-head 0 0;
        background: url("../../../images/myquest/fv_bg.jpg") no-repeat center bottom;
        background-size: cover;
        height: 460px;
        @include flex();
        align-items: center;
        justify-content: center;
        position: relative;
        @media screen and (max-width:$tab){
            margin: $sp-head 0 0;
            height: 380px;
        }
        @media screen and (max-width:$responsive){
            height: 300px;   
        }
        figure{
            width: 400px;
            margin: 0 auto;
            text-align: center;
            position: relative;
            z-index: 2;
            @media screen and (max-width:$tab){
                width: 380px;   
            }
            @media screen and (max-width:$responsive){
                width: 280px;   
            }
            img{
                width: 275px;
                transform: translateX(15%);
                @media screen and (max-width:$tab){
                    width: 240px;   
                }
                @media screen and (max-width:$responsive){
                    width: 200px;   
                }
            }
            figcaption{
                background: #FFF;
                border-radius: 999px;
                color: $b;
                margin: 16px 0 0;
                font-weight: 700;
                font-size: 22px;
                @media screen and (max-width:$tab){
                    font-size: 20px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 16px;   
                }
            }
        }
    }
    
    .first{
        border-top: $y 20px solid;
        background: $b url("../../../images/myquest/cross_dotto.png") repeat;
        @media screen and (max-width:$responsive){
            border-width: 8px;   
        }
        &.wrap{
            padding-top: 64px;
            padding-bottom: 64px;
            @media screen and (max-width:$tab){
                padding: 32px 0;   
            }
            @media screen and (max-width:$responsive){
                padding: 24px 15px;   
            }
        }
        dl.context{
            text-align: center;
            color: #FFF;
            dt{
                font-size: 24px;
                font-weight: 700;
                margin: 0 0 8px;
                @media screen and (max-width:$tab){
                    font-size: 20px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 17px;    
                }
                strong{
                    color: $y;
                    font-size: 36px;
                    line-height: 1;
                    display: inline-block;
                    margin: 0 8px 0 0;
                    @media screen and (max-width:$tab){
                        font-size: 28px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 22px;   
                    }
                }
            }
            dd{
                font-size: 17px;
                @media screen and (max-width:$tab){
                    font-size: 15px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 14px;
                    text-align: justify;
                    br{
                        display: none;
                    }
                }
            }
        }
    }
    
    .second{
        position: relative;
        &.wrap{
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 140px;
            @media screen and (max-width:$tab){
                padding-bottom: 64px;   
            }
            @media screen and (max-width:$responsive){
                padding-top: 0;   
            }
        }
        &:before{
            content: '';
            background-image: url("../../../images/myquest/wave_bg.png");
            width: 100%;
            height: 60px;
            position: absolute;
            left: 0;
            bottom: 0;
            background-repeat: repeat-x;
            background-size: 2000px;
            animation: waves 3000ms infinite;
            @keyframes waves{
                0%{
                    background-position: 0;
                }
                100%{
                    background-position: 2000px;
                }
            }
            @media screen and (max-width:$tab){
                height: 30px;
                background-size: 1000px;
                @keyframes waves{
                    0%{
                        background-position: 0;
                    }
                    100%{
                        background-position: 1000px;
                    }
                }
            }
        }
        .twoclum{
            position: relative;
            .bg{
                background-size: cover;
                background-position: center;
                width: 50%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 0 16px 16px 0;
                @media screen and (max-width:$responsive){
                    width: auto;
                    position: relative;
                    border-radius: 0;
                    height: 200px;
                }
            }
            .context{
                padding: 40px;
                padding-left: 56px;
                margin: 0 0 0 50%;
                @media screen and (max-width:$tab){
                    padding: 20px;
                    padding-left: 32px;
                }
                @media screen and (max-width:$responsive){
                    padding: 20px 15px 0;
                    margin: 0;
                }
                dt{
                    font-size: 33px;
                    font-weight: 700;
                    line-height: 1.6;
                    color: $b;
                    margin: 0 0 20px;
                    padding: 0 0 16px;
                    border-bottom: #0e3db5 4px solid;
                    letter-spacing: 0em;
                    @media screen and (max-width:$tab){
                        font-size: 24px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 22px;   
                    }
                    strong{
                        span{
                            position: relative;
                            display: inline-block;
                            &:before{
                                content: '';
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                position: absolute;
                                left: 50%;
                                top: -4px;
                                background: $y;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
                dd{
                    font-size: 16px;
                    text-align: justify;
                    @media screen and (max-width:$tab){
                        font-size: 13.5px;   
                    }
                }
            }
            .circle{
                position: absolute;
                top: -26px;
                right: 12px;
                width: 140px;
                height: 140px;
                z-index: -1;
                @media screen and (max-width:$tab){
                    display: none;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    
    .third{
        background: $s;
        h2{
            @include osw();
            font-size: 40px;
            color: $b;
            line-height: 1;
            font-weight: 700;
            margin: 0 0 48px;
            text-align: center;
            @media screen and (max-width:$tab){
                font-size: 28px;
                margin: 0 0 40px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 24px;
                line-height: 1.4;
                margin: 0 0 32px;
            }
            strong{
                display: inline-block;
                border-bottom: $y 8px solid;
            }
        }
        .three{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            li{
                width: 31.5%;
                background: #FFF;
                padding: 24px;
                border-radius: 16px;
                box-shadow: 0 0 16px rgba($b,.08);
                position: relative;
                @media screen and (max-width:$tab){
                    width: 32%;
                    padding: 20px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 30px;
                    padding: 15px;
                    &:last-child{
                        margin: 0;
                    }
                }
                &:before{
                    content: '';
                    width: 120px;
                    height: 30px;
                    background: $blue;
                    opacity: .07;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
                figure{
                    width: 100px;
                    margin: 16px auto 0;
                    @media screen and (max-width:$tab){
                        width: 80px;   
                    }
                    img{
                        width: 100%;
                    }
                }
                dl{
                    margin: 24px 0 0;
                    dt{
                        text-align: center;
                        font-size: 20px;
                        font-weight: 700;
                        color: $b;
                        margin: 0 0 4px;
                        @media screen and (max-width:$tab){
                            font-size: 18px;   
                        }
                    }
                    dd{
                        text-align: justify;
                    }
                }
            }
        }
    }
    
    .four{
        .sidebox{
            @include flex();
            align-items: center;
            margin: 48px 0 0;
            position: relative;
            @media screen and (max-width:$tab){
                display: block;
                margin: 32px 0 0;   
            }
            &:first-of-type{
                margin: 0;
            }
            .scroll-img{
                &:before{
                    background: $glay;
                }
            }
            &._active{
                > dl{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            &:after{
                content: '';
                width: calc(100% - 550px - 48px);
                height: 1px;
                position: absolute;
                right: 0;
                bottom: 0;
                background: $border;
                @media screen and (max-width:$tab){
                    display: none;
                }
            }
            &.rvs{
                &:after{
                    right: auto;
                    left: 0;
                }
                figure{
                    order: 2;
                }
                dl{
                    margin: 0;
                }
            }
            &__pic{
                width: 550px;
                @media screen and (max-width:$tab){
                    width: 100%; 
                }
                img{
                    width: 100%;
                }
            }
            &__txt{
                width: calc(100% - 550px - 48px);
                padding: 32px 0 32px 112px;
                opacity: 0;
                transform: translateY(32px);
                position: relative;
                @include transition(400ms, $delay:400ms);
                @media screen and (max-width:$tab){
                    width: auto;
                }
                @media screen and (max-width:$responsive){
                    transform: translateY(0);
                    padding: 0;
                }
                dt{
                    //@include min();
                    font-weight: 700;
                    font-size: 55px;
                    color: $b;
                    position: absolute;
                    padding: 16px 8px;
                    border: $border 2px solid;
                    top: 32px;
                    left: 0;
                    line-height: 1;
                    letter-spacing: .12em;
                    @media screen and (min-width:$responsive + 1px){
                        -webkit-writing-mode: vertical-rl;
                        -moz-writing-mode: vertical-rl;
                        -ms-writing-mode: tb-rl;
                        writing-mode: vertical-rl;
                        white-space: nowrap;
                    }
                    @media screen and (max-width:$responsive){
                        position: relative;
                        top: 0;
                        padding: 8px 15px;
                        text-align: center;
                        font-size: 24px;
                        margin: 20px 0 15px;
                    }
                }
                dd{
                    margin: 16px 0 0;
                    position: relative;
                    padding: 0 0 0 130px;
                    text-align: justify;
                    @media screen and (max-width:$responsive){
                        padding: 0;   
                    }
                    &:first-of-type{
                        margin: 0;
                    }
                    &:before{
                        content: attr(data-txt);
                        color: #FFF;
                        text-align: center;
                        font-weight: 700;
                        font-size: 13px;
                        width: 110px;
                        position: absolute;
                        top: 2px;
                        left: 0;
                        border-radius: 2px;
                        @media screen and (max-width:$responsive){
                            position: relative;
                            top: 0;
                            display: block;
                            margin: 0 0 12px;
                        }
                    }
                }
                &.green{
                    $col: #22ac38;
                    dt{
                        color: $col;
                        border-color: $col;
                    }
                    dd{
                        &:before{
                            background: $col;
                        }
                    }
                }
                &.orange{
                    $col: #eb6f00;
                    dt{
                        color: $col;
                        border-color: $col;
                    }
                    dd{
                        &:before{
                            background: $col;
                        }
                    }
                }
                &.red{
                    $col: #e60012;
                    dt{
                        color: $col;
                        border-color: $col;
                    }
                    dd{
                        &:before{
                            background: $col;
                        }
                    }
                }
            }
        }
    }
    
    .fifth{
        .schedule{
            @media screen and (max-width:$responsive){
                width: 100%;
                overflow: auto;
                &:before{
                    content: '▼ スクロールしてご覧ください';
                    display: block;
                    margin: 0 0 8px;
                }
            }
            &__table{
                border: $border 1px solid;
                border-collapse: collapse;
                width: 100%;
                @media screen and (max-width:$responsive){
                    width: 150%;   
                }
                thead,
                tbody{
                    tr{
                        th,td{
                            border: $border 1px solid;
                            text-align: center;
                            vertical-align: middle;
                            font-size: 17px;
                            padding: 16px;
                            @media screen and (max-width:$responsive){
                                font-size: 14px;
                                padding: 15px;
                            }
                        }
                    }
                }
                thead{
                    background: $b;
                    tr{
                        td{
                            border-color: darken($b,4%);
                            color: #FFF;
                            font-weight: 700;
                        } 
                    }
                }
                tbody{
                    tr{
                        th{
                            color: $b;
                            font-weight: 700;
                        }

                    }
                }
            }
        }
        .alert{
            font-size: 12px;
            text-align: right;
            margin: 16px 0 0;
            @media screen and (max-width:$responsive){
                margin: 15px 0 0;
                text-align: left;
            }
        }
        .mt{
            margin-top: 32px;
            @media screen and (max-width:$responsive){
                margin-top: 24px;   
            }
        }
        .pricetable{
            tbody{
                tr{
                    th{
                        font-size: 20px;
                        width: 280px;
                        background: #FFF;
                        font-weight: 700;
                        color: $b;
                        @media screen and (max-width:$responsive){
                            width: auto;
                            border-bottom: $border 1px solid;
                            line-height: 1;
                            font-size: 17px;
                        }
                        strong{
                            font-size: 40px;
                            @media screen and (max-width:$responsive){
                                font-size: 28px;
                            }
                        }
                    }
                }
            }
            &__data{
                dt{
                    font-weight: 700;
                }
                dd{
                    font-size: 12px;
                }
            }
        }
    }
}